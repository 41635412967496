import React, { useState, useContext } from "react";
import {
    Card,
    CardHeader,
    IconButton,
    Divider,
    CardContent,
    OutlinedInput,
    InputAdornment,
    Button,
} from "../../../sharedComponent";
import useTrans from "../../../../hooks/useTranslation";
import { ReactComponent as EditIcon } from "../../../../assets/pen.svg";
import {
    CardActionsWrapper,
    CardWrapper,
    Heading,
    MainWrapper,
    TableWrapper,
    ValueWrapper,
} from "./segmentStyle";
import useCall, { useWaitCall } from "../../../../hooks/useCall";
import {
    pocketSizeConfig,
    updatePocketSize,
} from "../../../../services/LeadsInfoApi";
import { convertObjectToArray } from "./pocketSize";
import { AlertContext } from "../../../../context/alterContext";
import SpinnerWrapper from "../../../sharedComponent/spinWrapper";

const LeadPocket = ({
    refresh,
    pocketFields,
    handleVolumeChange,
    handleCancelRequest,
    isEditPermission,
}) => {
    const alert = useContext(AlertContext);
    const [isEdit, setEdit] = useState(false);
    const fields = pocketFields;
    const pocketConfig = useCall(() => pocketSizeConfig({}), []);
    const leadSegementUpdateApi = useWaitCall(
        (fields) => updatePocketSize(fields),
        [],
        () => {
            alert.setSuccess("Save Successfully");
            refresh();
            setEdit(false);
        },
        () => {
            alert.setError("Something went wrong");
        }
    );
    const disable =
        Object.keys(fields).length === 0
            ? true
            : !Object.keys(fields).some((item) => fields[item].volume);
    const handleSubmit = () => {
        leadSegementUpdateApi.callback(convertObjectToArray(fields));
    };
    const [message] = useTrans();
    const handleCancel = () => {
        setEdit(false);
        handleCancelRequest();
    };

    return (
        <MainWrapper>
            <Card>
                <CardHeader
                    action={
                        !isEdit &&
                        isEditPermission && (
                            <div
                                onClick={() => setEdit(true)}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                }}
                            >
                                <IconButton aria-label="settings">
                                    <EditIcon
                                        width={12}
                                        height={12}
                                        fill={"#919295"}
                                        cursor={"pointer"}
                                    />
                                </IconButton>
                                {message("edit")}
                            </div>
                        )
                    }
                    title={
                        <>
                            <Heading variant="h6">
                                {message("leadInfo.segment.pocketSize")}
                            </Heading>
                        </>
                    }
                />
                <Divider />
                <SpinnerWrapper isLoading={pocketConfig.isLoading}>
                    <CardContent>
                        <CardWrapper>
                            <TableWrapper>
                                <b>Product</b>
                                <b>Volume</b>
                                <b>Value</b>
                            </TableWrapper>
                            {pocketConfig?.data?.data?.data?.map((item) =>
                                !isEdit
                                    ? detailPocket(item, fields)
                                    : editPocket(
                                          item,
                                          fields,
                                          handleVolumeChange
                                      )
                            )}
                        </CardWrapper>
                    </CardContent>
                </SpinnerWrapper>
                {isEdit && (
                    <CardActionsWrapper>
                        <Button
                            onClick={handleCancel}
                            name={message(
                                "sharedComponent.listDivider.leadInfo.button.cancel"
                            )}
                            variant="text"
                        ></Button>
                        <Button
                            onClick={handleSubmit}
                            isLoading={leadSegementUpdateApi.isLoading}
                            name={message(
                                "sharedComponent.listDivider.leadInfo.button.saveChanges"
                            )}
                            disabled={disable}
                        ></Button>
                    </CardActionsWrapper>
                )}
            </Card>
        </MainWrapper>
    );
};

export default LeadPocket;

function detailPocket(
    item: {
        productId: string;
        productName: string;
        unit: string;
        productPrice: string;
    },
    fields: any
): JSX.Element {
    const resultValue =
        parseFloat(fields[item.productId]?.volume) *
        parseFloat(item.productPrice);
    return (
        <TableWrapper key={item.productId}>
            <div>
                <label>{item.productName}</label>
            </div>
            <ValueWrapper>
                <b>
                    {parseFloat(fields[item.productId]?.volume || 0).toFixed(
                        2
                    ) || "0.00"}
                </b>
                <div>{`${item.unit}/month`}</div>
            </ValueWrapper>
            <ValueWrapper>
                <b>{isNaN(resultValue) ? "0.0" : resultValue.toFixed(2)}</b>
                <div>฿/month</div>
            </ValueWrapper>
        </TableWrapper>
    );
}

function editPocket(
    item: {
        productId: string;
        productName: string;
        productPrice: string;
        unit: string;
    },
    fields: any,
    handleVolumeChange: any
): JSX.Element {
    return (
        <TableWrapper key={item.productId}>
            <div>
                <label>{item.productName}</label>
            </div>
            <OutlinedInput
                type="number"
                value={fields[item.productId]?.volume}
                onChange={handleVolumeChange(
                    item.productId,
                    item.productPrice,
                    item.productName,
                    item.unit
                )}
                size="small"
                endAdornment={
                    <InputAdornment position="end">{`${item.unit}/month`}</InputAdornment>
                }
            />
            <OutlinedInput
                size="small"
                disabled={true}
                type="number"
                value={(
                    parseFloat(fields[item.productId]?.volume) *
                    parseFloat(item.productPrice)
                ).toFixed(2)}
                endAdornment={
                    <InputAdornment position="end">฿/month</InputAdornment>
                }
            />
        </TableWrapper>
    );
}
