import React from "react";
import useTrans from "../../../../hooks/useTranslation";

function CreateFullAddress({
    houseNo,
    soi,
    building,
    address,
    province,
    district,
    subdistrict,
    postalCode,
}) {
    const [message] = useTrans();
    const fullAddress = [];

    if (houseNo) {
        fullAddress.push(<>{houseNo}</>);
    }

    if (soi) {
        fullAddress.push(<>{" " + soi}</>);
    }

    if (building) {
        fullAddress.push(<>{" " + building}</>);
    }

    if (address) {
        fullAddress.push(<>{" " + address}</>);
    }

    if (subdistrict) {
        fullAddress.push(
            <>
                {" " + message("account.form.address.view.subDistrict") + " "}{" "}
                {subdistrict}
            </>
        );
    }

    if (district) {
        fullAddress.push(
            <>
                {" " + message("account.form.address.view.district") + " "}{" "}
                {district}
            </>
        );
    }

    if (province) {
        fullAddress.push(<>{" " + province}</>);
    }

    if (postalCode) {
        fullAddress.push(<>{" " + postalCode}</>);
    }

    return <>{fullAddress}</>;
}

export default CreateFullAddress;
