import React from "react";
import { ReactComponent as BetaGro } from "../../../assets/linkConnect.svg";
import { Card, Button, Typography, Stack } from "../../sharedComponent";
import { styled } from "@mui/material/styles";
import useTrans from "../../../hooks/useTranslation";

const Footer = styled("div")(({ theme }) => ({
    margin: "20px 20px",
}));

const Container = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    height: "inherit",
}));

const Body = styled(Container)(({ theme }) => ({
    flexDirection: "column",
    margin: "20px",
}));

const CardWarpper = styled(Card)(() => ({
    width: "30%",
    borderRadius: "20px",
    "@media (max-width:640px)": {
        width: "100%",
    },
}));

const ButtonWrapper = styled("div")(() => ({
    display: "flex",
    justifyContent: "center",
}));

export function CreateLink({ onSubmit, error, onClose, isLoading }: any) {
    const [message] = useTrans();

    return (
        <Container>
            <CardWarpper>
                <Body>
                    <div>
                        <BetaGro />
                    </div>
                    <Typography variant="h5">
                        {message("sharedComponent.modal.accountlink.title")}
                    </Typography>
                    <Typography variant="body1">
                        {message(
                            "sharedComponent.modal.accountlink.sublinktext"
                        )}
                    </Typography>
                </Body>
                <Footer>
                    <Stack flexDirection={"row"}></Stack>
                    <Typography
                        mt={2}
                        style={{ color: "red" }}
                        textAlign={"center"}
                    >
                        {error && "Wrong OTP"}
                    </Typography>
                    <ButtonWrapper>
                        <Button
                            mt={5}
                            style={{ flex: 1, marginRight: "5px" }}
                            variant="text"
                            name={message(
                                "sharedComponent.listDivider.leadInfo.button.cancel"
                            )}
                            onClick={onClose}
                            data-testid="close-btn"
                        />
                        <Button
                            mt={5}
                            style={{ flex: 1 }}
                            name={message(
                                "sharedComponent.modal.assignModal.confirm"
                            )}
                            isLoading={isLoading}
                            onClick={onSubmit}
                            data-testid="gen-link"
                        />
                    </ButtonWrapper>
                </Footer>
            </CardWarpper>
        </Container>
    );
}
