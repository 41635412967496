import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as SVGIcon } from "../../../assets/wave.svg";
import { ReactComponent as BetaGro } from "../../../assets/betagro.svg";
import { Button, Typography, Stack } from "../index";
import { Card } from "@mui/material";
import Link from "@mui/material/Link";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import useTrans from "../../../hooks/useTranslation";
import OTPNumberInput from "../InputField/otpInputNumber";
import { useWaitCall } from "../../../hooks/useCall";
import { AlertContext } from "../../../context/alterContext";
import { useSearchParams } from "react-router-dom";
import { useCommonContext } from "@betagro/ui-common";
import {
    generateOTP,
    generateSignOTP,
    verifyOTP,
} from "../../../services/AccountApi";
import SpinnerWrapper from "../spinWrapper";

const Footer = styled("div")(({ theme }) => ({
    margin: "20px 20px",
}));

const Container = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    height: "inherit",
}));

const Body = styled(Container)(({ theme }) => ({
    flexDirection: "column",
    margin: "20px",
}));

const CardWarpper = styled(Card)(() => ({
    width: "30%",
    borderRadius: "20px",
    "@media (max-width:640px)": {
        width: "100%",
    },
}));

const Main = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
}));

const SubmitButton = styled(Button)(() => ({
    width: "100%",
}));

const CompanyName = styled(Typography)(() => ({
    fontWeight: "700",
    color: "#8A803C",
}));

const OtpLogin = () => {
    const { leadId } = useParams();
    const [searchParams] = useSearchParams();
    const error = searchParams.get("error");
    const handleSubmit = (otp) => {
        verifyOTP(leadId, otp);
    };
    return (
        <Main>
            <OtpComponent
                error={error}
                leadId={parseInt(leadId)}
                onSubmit={handleSubmit}
            />
            <div>
                <SVGIcon />
            </div>
        </Main>
    );
};

interface IOtp {
    leadId: number;
    error?: string;
    isLoading?: boolean;
    type?: number;
    onSubmit?: Function;
}

export function OtpComponent({
    leadId,
    error,
    onSubmit,
    type,
    isLoading,
}: IOtp) {
    const commonContext = useCommonContext();
    const [message] = useTrans();
    const [refresh, setRefresh] = useState(false);
    const [inputOtp, setInputOtp] = useState({
        otp1: null,
        otp2: null,
        otp3: null,
        otp4: null,
        otp5: null,
        otp6: null,
    });

    const generateOTPApi = useWaitCall(
        (leadId) =>
            type ? generateSignOTP({ leadId, type }) : generateOTP(leadId),
        [],
        null,
        () => {
            alert.setError("something went wrong");
        }
    );
    const contactNumber =
        generateOTPApi?.data?.data?.contactNumber ||
        generateOTPApi?.data?.data?.data?.contactNumber;

    const handleSubmitOTP = () => {
        const isAllValidNumbers = Object.values(inputOtp).every(
            (data) => typeof data === "number" && data >= 0 && data <= 9
        );
        if (isAllValidNumbers && contactNumber) {
            const otp = `${Object.values(inputOtp).join("")}`;
            onSubmit(otp);
        }
    };
    const otp2 = useRef();
    const otp1 = useRef();
    const otp3 = useRef();
    const otp4 = useRef();
    const otp5 = useRef();
    const otp6 = useRef();
    const field = { otp1, otp2, otp3, otp4, otp5, otp6 };

    const handleOtpChange = (e) => {
        let fieldnumber = parseInt(e.target.name.split("")[3]);

        if (e.target.value.length === 1) {
            fieldnumber = fieldnumber + 1;
        }
        if (e.target.value.length === 0) {
            fieldnumber = fieldnumber - 1;
        }

        const current = field["otp" + fieldnumber]?.current as any;
        current && current.focus();

        setInputOtp({ ...inputOtp, [e.target.name]: eval(e.target.value) });
    };

    const alert = React.useContext(AlertContext);

    useEffect(() => {
        generateOTPApi.callback(leadId);
    }, [refresh]);

    useEffect(() => {
        commonContext.setHideNav(true);
        return () => commonContext.setHideNav(false);
    }, []);

    return (
        <Container>
            <CardWarpper>
                <Body>
                    <div>
                        <BetaGro />
                    </div>
                    <CompanyName variant="h5">
                        {message("layout.header.betagro")}
                    </CompanyName>
                    <Typography variant="h5">{"OTP Verification"}</Typography>
                    <SpinnerWrapper isLoading={generateOTPApi.isLoading}>
                        <Typography variant="body1">
                            {contactNumber &&
                                `Enter the OTP Send to ${contactNumber}`}
                        </Typography>
                    </SpinnerWrapper>
                </Body>
                <Footer>
                    <Stack flexDirection={"row"}>
                        <OTPNumberInput
                            error={error}
                            handleChange={handleOtpChange}
                            name="otp1"
                            inputRef={otp1}
                        />
                        <OTPNumberInput
                            error={error}
                            handleChange={handleOtpChange}
                            name="otp2"
                            inputRef={otp2}
                        />
                        <OTPNumberInput
                            error={error}
                            handleChange={handleOtpChange}
                            name="otp3"
                            inputRef={otp3}
                        />
                        <OTPNumberInput
                            error={error}
                            handleChange={handleOtpChange}
                            name="otp4"
                            inputRef={otp4}
                        />
                        <OTPNumberInput
                            error={error}
                            handleChange={handleOtpChange}
                            name="otp5"
                            inputRef={otp5}
                        />
                        <OTPNumberInput
                            error={error}
                            handleChange={handleOtpChange}
                            name="otp6"
                            inputRef={otp6}
                        />
                    </Stack>
                    <Typography
                        mt={2}
                        style={{ color: "red" }}
                        textAlign={"center"}
                    >
                        {error && "Wrong OTP"}
                    </Typography>
                    <Typography mt={2} textAlign={"center"}>
                        {error && (
                            <Link onClick={() => setRefresh(!refresh)}>
                                Resend
                            </Link>
                        )}
                    </Typography>
                    <SubmitButton
                        mt={5}
                        name={"Submit"}
                        isLoading={isLoading}
                        onClick={handleSubmitOTP}
                    />
                </Footer>
            </CardWarpper>
        </Container>
    );
}

export default OtpLogin;
