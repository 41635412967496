import { Card, Box, Stack, styled } from "../index";

export const BoxStyle = styled(Box)(() => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    maxWidth: "80%",
    maxHeight: "70%",
}));

export const HeaderStyle = styled("div")(() => ({
    display: "flex",
    justifyContent: "space-between",
}));

export const FooterStyle = styled("div")(() => ({
    display: "flex",
    justifyContent: "space-between",
    padding: "0.8rem 1rem",
}));

export const ContactStack = styled(Stack)(() => ({
    color: "#919295",
    flexDirection: "row",
    alignItems: "center",
}));

export const CardStye = styled(Card)(() => ({
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
}));

export const BodyStyle = styled("div")(() => ({
    overflowY: "scroll",
    height: "37vh",
    "::-webkit-scrollbar": {
        width: "4px",
    },
    "::-webkit-scrollbar-track-piece": {
        background: "#ffffff",
    },
    "::-webkit-scrollbar-thumb": {
        backgroundColor: "lightgray",
        borderRadius: "20px",
    },
}));

export const FooterWraper = styled("div")(() => ({
    borderTop: "1px solid lightgray",
}));

export const ButtonWrapper = styled("div")(() => ({
    display: "flex",
    gap: "1rem",
}));

export const NoDataStyle = styled("div")(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    height: "inherit",
}));
