import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import {
    Box,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    Typography,
    Toolbar,
    Checkbox,
    Stack,
} from "../../../sharedComponent";
import { visuallyHidden } from "@mui/utils";
import { headCells } from "../../../../utils/apiData";
import ButtonComponent from "../../button/Button";
import { ReactComponent as CircleInfo } from "../../../../assets/circle-info.svg";
import { ReactComponent as FileExport } from "../../../../assets/file-export.svg";
import { IEnhancedTableHead, IEnhancedTableToolbar } from "../../../pages/type";
import useTrans from "../../../../hooks/useTranslation";
import "./index.scss";
import { useCommonContext } from "@betagro/ui-common";
import { ROLES } from "../../../../constants/role.status";
export const EnhancedTableHead = (props: IEnhancedTableHead) => {
    const [message] = useTrans();
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler = (
        event: React.MouseEvent<HTMLInputElement>,
        newOrderBy: string
    ) => {
        onRequestSort(event, newOrderBy);
    };

    // const TableWrapper = styled(page)(({ theme }) => ({
    //   '& .MuiInputBase-root':{borderRadius:"8px",height:"44px",border:"none",overflow:"hidden"}
    // }));

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all desserts",
                        }}
                        data-testid= "checkbox"
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        // padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={
                            orderBy === headCell.id ? order.order : false
                        }
                        sx={{ flexDirection: "row" }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={
                                orderBy === headCell.id ? order : ("asc" as any)
                            } // Type mismatch
                            onClick={(
                                event: React.MouseEvent<HTMLInputElement>
                            ) => createSortHandler(event, headCell.id)}
                        >
                            {message(headCell.label)}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order.order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export const EnhancedTableToolbar = (props: IEnhancedTableToolbar) => {
    const [message] = useTrans();
    const { numSelected, name, icon, count, modal, handleExport } = props;
    const { userContextInfo } = useCommonContext();

    return (
        <>
            {modal}
            <Toolbar
                sx={{
                    justifyContent: "space-between",
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    m: { md: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(
                                theme.palette.primary.main,
                                theme.palette.action.activatedOpacity
                            ),
                    }),
                }}
            >
                {numSelected > 0 ? (
                    <>
                        <Typography
                            variant="poster2"
                            id="tableTitle"
                            component="div"
                            className="lead-table-container"
                        >
                            {count} {message(`${name}`)}{" "}
                            {icon && <CircleInfo />}
                        </Typography>
                        <Typography
                            color="inherit"
                            variant="poster2"
                            component="div"
                            className="lead-table-container"
                        >
                            {`(${numSelected} selected)`}
                        </Typography>
                    </>
                ) : (
                    <Typography
                        variant="poster2"
                        id="tableTitle"
                        component="div"
                        className="lead-table-container"
                    >
                        {count} {message(`${name}`)} {icon && <CircleInfo />}
                    </Typography>
                )}

                {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
                <Stack spacing={2} direction="row">
                    <ButtonComponent
                        name={message(
                            "sharedComponent.table.header.button.export"
                        )}
                        variant="outlined"
                        color="success"
                        sx={{
                            borderRadius: "16px",
                            color: "#84BD00",
                            gap: "8px",
                            textTransform: "none",
                            stroke: "lightgray",
                        }}
                        onClick={handleExport}
                        icon={<FileExport />}
                        data-testid="export-btn"
                        disabled={userContextInfo?.role > ROLES.MANAGER}
                    />
                    {/* <ButtonComponent
          name={
            buttonLabel
              ? message(buttonLabel)
              : message("sharedComponent.table.header.button.assignto")
          }
          variant="contained"
          color="success"
          sx={{
            borderRadius: "16px",
            backgroundColor: "#84BD00",
            textTransform: "none",
          }}
           // onClick={openModal}
        /> */}
                </Stack>
            </Toolbar>
        </>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};
