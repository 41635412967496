import React from "react";
import LeadInfoList from "./leadsInfoForm";
import "./index.scss";

const ListDivider = (props: any) => {
    return (
        <LeadInfoList
            refresh={props.refresh}
            leadData={props.leadInfo}
            isEditPermission={props.isEditPermission}
            isMapEditable={true}
        />
    );
};
export default ListDivider;
