import Axios from "./axiosInstance";
import {
    GET_USER_ROLE,
    GET_USER_POST,
    UPLOAD_PRICE_RANGE_DATA,
    UPLOAD_PRICE_DATA,
} from "./uriConstant";

export const setUserRole = (data) => {
    return Axios.post(`${GET_USER_ROLE}`, data);
};
export const setUser = (data) => {
    return Axios.post(`${GET_USER_POST}`, data);
};

export const setPriceRangeData = (data) => {
    return Axios.post(`${UPLOAD_PRICE_RANGE_DATA}`, data);
};
export const setPriceData = (data) => {
    return Axios.post(`${UPLOAD_PRICE_DATA}`, data);
};
