import * as React from "react";
import { Menu, MenuItem } from "../../sharedComponent";
import useTrans from "../../../hooks/useTranslation";

const BasicMenu = (props) => {
    const [message] = useTrans();

    const handleOptions = (val) => {
        if (val) {
            if (val === "sharedComponent.listDivider.assignmentList.assignTo") {
                if (props?.data?.isLeadAssigned === false) {
                    return message(val);
                } else if (props?.data?.isLeadAssigned === true) {
                    return false;
                }
            } else {
                return message(val);
            }
        } else {
            return false;
        }
    };
    return (
        <Menu
            id="basic-menu"
            anchorEl={props?.anchorEl}
            open={props?.open}
            onClose={props?.handleClose}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            sx={{
                display: "block",
                "& .MuiPaper-root": {
                    borderRadius: "16px",
                    padding: "10px",
                    filter: "drop-shadow(0px,0px,1px,rgba(0, 0, 0, 0.15))",
                    width: "8%",
                },
            }}
        >
            {props?.options?.map((option, idx) => {
                return (
                    <div key={idx}>
                        <MenuItem
                            onClick={() =>
                                option.handleClick(
                                    props.data,
                                    option?.name,
                                    props?.tabName,
                                    props?.handleClose
                                )
                            }
                            sx={{ padding: "5px" }}
                            key={idx}
                        >
                            {handleOptions(option?.name) as any}
                        </MenuItem>
                    </div>
                );
            })}
        </Menu>
    );
};
export default BasicMenu;
