import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Importing translation files

import translationEN from "./locales/en.json";
import translationTH from "./locales/th.json";

//Creating object with the variables of imported translation files
const resources = {
    en: {
        translation: translationEN,
    },
    th: {
        translation: translationTH,
    },
};

//i18N Initialization

i18n.use(initReactI18next).init({
    fallbackLng: "en",
    resources,
    lng: "en", //default language
    keySeparator: ".",
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
