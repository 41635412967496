import React from "react";
import { Route, Routes } from "react-router-dom";
import FormPageStepper from "../components/FormPagesStepper";
import OtpLogin from "../components/pages/otpLogoin";
import { styled } from "@mui/material";

const Main = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    padding: "20px 20px",
}));

const PublicRoutes = () => {
    return (
        <Routes>
            <Route path="/create-account-form" element={<FormPageStepper />} />
            <Route path="/validate/otp/:leadId" element={<OtpLogin />} />
        </Routes>
    );
};

export default PublicRoutes;
