import * as React from "react";
import { Modal } from "../index";
import { IModalPopup } from "../../../interfaces/modal";

export default function BasicModal({
    open,
    handleClose,
    children,
}: IModalPopup) {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div>{children}</div>
        </Modal>
    );
}
