import React, { useEffect, useMemo } from "react";
import dayjs from "dayjs";
import {
    Typography,
    Grid,
    TextInput,
    NumberInput,
    Card,
    Box,
    Paper,
    styled,
    SelectInput,
    Button,
} from "../../../sharedComponent";
import useForm from "../../../../hooks/useForm";
import DateTextInput from "../../../sharedComponent/InputField/DateInput";
import { assignLeads } from "../../../../services/LeadsAssignmentApi";
import useCall, { useWaitCall } from "../../../../hooks/useCall";
import { AlertContext } from "../../../../context/alterContext";
import { number } from "../../../../validation/validation";
import { useCommonContext } from "@betagro/ui-common";
import useTrans from "../../../../hooks/useTranslation";
import { getAllUsersInfo } from "../../../../services/userApi";
import { required } from "../../../../validation/validation";
import { ReactComponent as CloseIcon } from "../../../../assets/xmark-solid.svg";
import { ROLES } from "../../../../constants/role.status";

const BoxStyle = styled(Box)(() => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    maxWidth: "70%",
    maxHeight: "70%",
    margin: "20px",
}));

const CardStye = styled(Card)(() => ({
    boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.1)",
    margin: "2rem",
}));

const AssignModal = ({ rowData, handleClose, refresh }) => {
    const commonContext = useCommonContext();
    const [message] = useTrans();
    const defaultValues = {
        leadId: rowData?.id,
        userId:
            commonContext?.userContextInfo?.role === ROLES.SALES
                ? commonContext?.userContextInfo?.first_name
                : null,
    };

    const { register, handleSubmit, resetValue, getValue, isValid } =
        useForm(defaultValues);
    const alert = React.useContext(AlertContext);

    const addMonth = dayjs().add(1, "month");

    const getAllUsers = useCall(() => getAllUsersInfo(), [], []);

    const assignLeadData = useWaitCall(
        (data) => assignLeads(data),
        [],
        (result) => {
            alert.setSuccess(result?.data?.data?.message);
            if (result?.data?.data?.success) {
                rowData.isLeadAssigned = true;
            }
            commonContext.refreshCount();
            refresh();
            handleClose();
        },
        () => {
            alert.setError("something went wrong");
        }
    );

    const getEmployeeId = (userId) => {
        const employee = getAllUsers?.data?.data?.data?.find(
            (x) => x.name === userId
        );
        return employee?.employeeId || null;
    };

    const onSubmit = (data) => {
        event.preventDefault();
        const apidata = {
            leadId: rowData?.id,
            userId: data.userId,
            dueDate:
                data?.dueDate === undefined
                    ? addMonth.toISOString()
                    : data?.dueDate,
            assignDate: new Date().toISOString(),
            target: data?.target,
            assignedBy: commonContext?.userContextInfo?.first_name,
            empCode:
                commonContext?.userContextInfo?.role === ROLES.SALES
                    ? commonContext?.userContextInfo?.employeeId
                    : getEmployeeId(data.userId),
        };
        assignLeadData.callback(apidata);
    };

    const handleOption = useMemo(() => {
        const options = getAllUsers?.data?.data?.data
            .filter(
                (user) =>
                    user.branch === commonContext?.userContextInfo?.branch &&
                    user.role === ROLES.SALES
            )
            .map((user) => ({
                value: user.name,
                label: user.name,
            }));

        return (
            options || [
                {
                    value: "No data",
                    label: "No data",
                },
            ]
        );
    }, [getAllUsers?.data?.data?.data]);

    React.useEffect(() => {
        if (defaultValues) {
            resetValue({ ...(defaultValues as any) });
        }
    }, []);

    const userIdInfo = register(
        "userId",
        required(
            message(
                "sharedComponent.listDivider.assignmentList.requiredAssignee"
            )
        )
    );

    return (
        <BoxStyle component={Paper}>
            <CardStye>
                <Typography variant="poster2">
                    {message("sharedComponent.modal.assignModal.assignToSales")}
                </Typography>
                <Typography
                    variant="subtitle1"
                    sx={{ padding: "1rem 2rem", float: "right" }}
                    onClick={handleClose}
                >
                    <CloseIcon
                        height={25}
                        width={25}
                        fill={"#47474A"}
                        cursor={"pointer"}
                    />
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        {commonContext?.userContextInfo?.role ===
                        ROLES.SALES ? (
                            <TextInput
                                xs={12}
                                md={12}
                                label={message(
                                    "sharedComponent.modal.assignModal.asignee"
                                )}
                                disabled={true}
                                {...register("userId", null)}
                            />
                        ) : (
                            <SelectInput
                                xs={12}
                                md={12}
                                label={"assignee"}
                                options={handleOption}
                                {...userIdInfo}
                                onChange={(e) =>
                                    userIdInfo.onChange({
                                        target: { value: e.target.value },
                                    })
                                }
                                id="assignee"
                            />
                        )}
                        <DateTextInput
                            register={register}
                            xs={6}
                            md={6}
                            defaultValue={addMonth}
                            backDateDisabled={true}
                            label={message(
                                "sharedComponent.modal.assignModal.dueDate"
                            )}
                            name="dueDate"
                        />
                        <NumberInput
                            xs={6}
                            md={6}
                            label={message(
                                "sharedComponent.modal.assignModal.monthlySales"
                            )}
                            iconData={"฿"}
                            placeholder={"Enter target Amount"}
                            {...register("target", number())}
                        />
                    </Grid>
                    <Grid md={12} pt={2}>
                        <Button
                            fullWidth
                            disabled={!isValid && !getValue("userId")}
                            isLoading={assignLeadData.isLoading}
                            onClick={handleSubmit(onSubmit)}
                            name={message(
                                "sharedComponent.modal.assignModal.confirm"
                            )}
                        />
                    </Grid>
                </form>
            </CardStye>
        </BoxStyle>
    );
};

export default AssignModal;
