import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { applyLayout } from "@betagro/ui-common";
import "./index.scss";
import { datadogRum } from "@datadog/browser-rum";

const getEnv = () => {
    switch (location.host) {
        case "dev-bsale.betagro.com":
            return "dev";
        case "test-bsale.betagro.com":
            return "test";
        case "qa-bsale.betagro.com":
            return "uat";
        case "bsale.betagro.com":
            return "prod";
        case "localhost:3000":
            return "local";
        default:
            return "local";
    }
};

const root = ReactDOM.createRoot(document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
export const Root = (props) => {
    return <App />;
};

const AppLayout = applyLayout(Root, "lead");

root.render(<AppLayout />);
datadogRum.init({
    applicationId: "fca98f49-d9ef-4e93-850f-7b783dd617d8",
    clientToken: "pub9f0a5d039164a85a7ef45cc08eb219c3",
    site: "datadoghq.com",
    service: "bsale-lead-fe",
    env: getEnv(),
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
});

datadogRum.startSessionReplayRecording();
