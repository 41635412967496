import Axios from "./axiosInstance";
import { GET_NEAR_BY_PLACE, SET_CREATE_PLACE } from "./uriConstant";

// axios.defaults.proxy.host = "https://dev-bsale.betagro.com"

export const getNearByPlace = (data) => {
    return Axios.post(GET_NEAR_BY_PLACE, data);
};

export const setCreatelace = (id, data) => {
    return Axios.post(SET_CREATE_PLACE + id, data);
};
