import React from "react";
import {
    Paper,
    Typography,
    Grid,
    TextInput,
    UploadInput,
} from "../../../sharedComponent";
import ButtonComponent from "../../../sharedComponent/button/Button";
import { ReactComponent as CloseIcon } from "../../../../assets/xmark-solid.svg";
import { BoxStyle, HeadingStyle } from "../segment/activityStyle";
import useForm from "../../../../hooks/useForm";
import useTrans from "../../../../hooks/useTranslation";
import { INotes } from "../../../../interfaces/activity";
import { AlertContext } from "../../../../context/alterContext";
import { useWaitCall } from "../../../../hooks/useCall";
import { uploadFiles, removeFile } from "../../../../services/LeadsInfoApi";
import TextAreaInput from "../../../sharedComponent/InputField/TextAreaInput/TextAreaInput";

const activityValue = {
    notes: [],
};

const AddNotes = ({ handleClose, submitData }: INotes) => {
    const [message] = useTrans();
    const [files, setFiles] = React.useState([]);
    const alert = React.useContext(AlertContext);
    const [uploadedFiles, setUploadedFiles] = React.useState([]);
    const { register, handleSubmit } = useForm({
        defaultValues: activityValue,
    });
    const notes = register("notes", null);

    const handleChange = (e) => {
        notes.onChange({ target: { value: e.target.value } });
    };

    const onSubmit = (value) => {
        value.attachment = uploadedFiles;
        submitData(value);
    };

    const handleFileChange = (files) => {
        if (files?.length) {
            setFiles(files);
            const data = new FormData();
            for (let i = 0; i < files.length; i++) {
                data.append("File", files[i]);
            }
            uploadFileData.callback(data);
        } else {
            setFiles([]);
        }
    };

    const uploadFileData = useWaitCall(
        (data) => uploadFiles(data),
        [],
        (result) => {
            setFiles([]);
            const newList = result?.data?.data?.data || [];
            const updatedList = [...uploadedFiles, ...newList];
            setUploadedFiles(updatedList);
            alert.setSuccess("Uploaded Successfully");
        },
        () => {
            alert.setError("something went wrong");
        }
    );

    const handleCloseFile = () => {
        setFiles([]);
    };

    const removeImage = useWaitCall(
        async (name) => {
            await removeFile({ name });
            return name;
        },
        [],
        (result) => {
            const updatedList = uploadedFiles.filter(
                (val) => !(val === result.data)
            );
            setUploadedFiles([...updatedList]);
            alert.setSuccess("File deleted Successfully");
        },
        () => {
            alert.setError("something went wrong");
        }
    );
    const handleRemoveImage = (name) => {
        removeImage.callback(name);
    };

    return (
        <BoxStyle sx={{ width: "46%" }} component={Paper} p={4}>
            <div>
                <HeadingStyle>
                    <Typography variant="h4">
                        {message("pages.leadDetails.leadInfo.addNotes")}
                    </Typography>
                    <span data-testid={"closeNote"} onClick={handleClose}>
                        <CloseIcon
                            height={25}
                            width={25}
                            fill={"#47474A"}
                            cursor={"pointer"}
                        />
                    </span>
                </HeadingStyle>
                <Grid item lg={12}>
                    <TextAreaInput
                        label={message("pages.leadDetails.leadInfo.notes")}
                        minRows={4}
                        placeholder={message(
                            "pages.leadDetails.leadInfo.addNotes"
                        )}
                        multiline={true}
                        onChange={handleChange}
                        value={notes.value}
                    />
                </Grid>

                <UploadInput
                    handleChange={handleFileChange}
                    handleClose={handleCloseFile}
                    multiple={true}
                    files={files}
                    removeUploadedFiles={handleRemoveImage}
                    uploadedFiles={uploadedFiles}
                    accept="image/*"
                />
                <ButtonComponent
                    name={message("pages.leadDetails.leadInfo.submit")}
                    variant="contained"
                    color="success"
                    onClick={handleSubmit(onSubmit)}
                    sx={{
                        borderRadius: "16px",
                        backgroundColor: "#84BD00",
                        textTransform: "none",
                        width: "100%",
                        marginTop: "1rem",
                    }}
                />
            </div>
        </BoxStyle>
    );
};
export default AddNotes;
