import React from "react";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import {
    TextInput,
    SelectInput,
    Typography,
    Grid,
    AttestInput,
    Button,
    DateInput,
    NumberInput,
    InputAdornment,
    Checkbox,
    FormControlLabel,
} from "../../sharedComponent";
import { ICreateAccountForm } from "../type";
// import useForm from "../../../hooks/useForm";
import useTrans from "../../../hooks/useTranslation";
import {
    createAccountFormThree,
    createAccouuntButtons,
} from "../../../utils/constants";

import { useCommonContext } from "@betagro/ui-common";

const Main = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0rem",
    gap: "1.688rem",
    width: "55.5rem",
    left: "27rem",
    top: "8rem",
}));

const FormContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0rem 0rem 2rem",
    width: "55.5rem",
    height: "143.875rem",
    background: "#FFFFFF",
    boxShadow: "0rem 0.25rem 5rem rgba(0, 0, 0, 0.04)",
    borderRadius: "1rem",
}));

const FormHead = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    borderBottom: "0.063rem solid #EAEBEB",
    borderRadius: "1rem 16rem 0rem 0rem",
}));

const FormFrame = styled("form")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "4rem",
    padding: "0rem",
    width: "49.5rem",
    height: "67.25rem",
}));

const Ptext = styled("p")((props: any) => ({
    fontFamily: "Arial",
    fontSize: "0.890rem",
    fontStyle: "normal",
    fontWeight: "normal",
    textDecoration: "none",
    textTransform: "none",
    lineHeight: "1.2rem",
    color: "#000000",
    backgroundColor: "#ffffff",
    marginTop: "auto",
    margin: "0px",
    display: !props["aria-haspopup"] ? "flex" : "Block",
    alignItems: "center",
    paddingTop: "16px",
}));

const OrderedList = styled("ol")(() => ({
    paddingInlineStart: "2.25rem",
    margin: 0,
    padding: 0,
    listStylePosition: "inside",
    textIndent: "1.875rem",
    marginBottom: "0.5rem",
}));

const ListItem = styled("li")(() => ({
    fontFamily: "Arial",
    fontSize: "0.890rem",
    fontStyle: "normal",
    fontWeight: "normal",
    textDecoration: "none",
    textTransform: "none",
    color: "#000000",
    lineHeight: "1.2rem",
    backgroundColor: "#ffffff",
    marginTop: ".5rem",
    listStyleType: "none",
}));
const DateContainer = styled("div")(() => ({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    width: "100%",
}));
const NavButton = styled(Button)(() => ({
    borderRadius: "2rem",
    backgroundColor: "#84BD00",
    width: "8.25rem",
    textTransform: "none",
}));

const GridSubContainer = styled(Grid)(() => ({
    justifyContent: "left",
    display: "inline-flex",
    marginTop: "0rem",
    fontSize: "0.890rem",
}));

const CreateAccountStepFour = ({
    register,
    handleNext,
    handleBack,
    isView,
    isSales,
    isCustomer,
    setValues,
    isValidApprover,
    id,
}: ICreateAccountForm) => {
    const [check, setCheck] = React.useState(false);
    const { selectedLanguage } = useCommonContext();
    const handleCheckboxChange = () => {
        if (isView) {
            return;
        }

        setCheck(!check);
    };

    const [message] = useTrans();
    const titleInfo = register("title");

    return (
        <Main>
            <FormContainer id={id}>
                <FormHead>
                    <Typography sx={{ mx: 5 }} variant="poster2">
                        {message(createAccountFormThree.title)}
                    </Typography>
                </FormHead>
                <FormFrame>
                    <Grid container spacing={1}>
                        {!isView ? (
                            <GridSubContainer container spacing={2}>
                                <Ptext aria-haspopup={isView}>
                                    {message(createAccountFormThree.textOne)}
                                </Ptext>
                                <DateInput
                                    xs={5}
                                    md={5}
                                    label=""
                                    name={"productPurchaseDate"}
                                    register={register}
                                    id="productPurchaseDate"
                                    disabled={true}
                                />
                            </GridSubContainer>
                        ) : register("productPurchaseDate").value ? (
                            <DateContainer>
                                <Typography>
                                    {
                                        <span>
                                            <b>
                                                {"\u00A0"}
                                                {dayjs(
                                                    register(
                                                        "productPurchaseDate"
                                                    ).value
                                                )
                                                    .locale(selectedLanguage)
                                                    .format("DD MMMM YYYY")}
                                            </b>
                                        </span>
                                    }
                                </Typography>
                            </DateContainer>
                        ) : (
                            ""
                        )}
                        <br />
                        <br />
                        {!isView && (
                            <>
                                <GridSubContainer container spacing={2}>
                                    <Ptext aria-haspopup={isView}>
                                        {message(
                                            createAccountFormThree.textTwo
                                        )}
                                    </Ptext>
                                    <SelectInput
                                        xs={2}
                                        md={2}
                                        options={[
                                            {
                                                value: 1,
                                                label: message(
                                                    "account.form.aggrement.mr"
                                                ),
                                            },
                                            {
                                                value: 2,
                                                label: message(
                                                    "account.form.aggrement.mrs"
                                                ),
                                            },
                                            {
                                                value: 3,
                                                label: message(
                                                    "account.form.aggrement.miss"
                                                ),
                                            },
                                        ]}
                                        {...titleInfo}
                                        isView={isView}
                                        name="title"
                                        id="Title"
                                        disabled={!isSales}
                                    />
                                    <TextInput
                                        xs={4}
                                        md={4}
                                        {...register("firstName")}
                                        id="firstName"
                                        isView={isView}
                                        disabled={!isSales}
                                    />
                                    <TextInput
                                        xs={3}
                                        md={3}
                                        {...register("lastName")}
                                        id="lastName"
                                        isView={isView}
                                        // value="Sangkaew"
                                        disabled={!isSales}
                                    />
                                </GridSubContainer>
                                <GridSubContainer container spacing={2}>
                                    <Ptext aria-haspopup={isView}>
                                        {message(
                                            createAccountFormThree.textThree
                                        )}
                                    </Ptext>
                                </GridSubContainer>
                                <GridSubContainer container spacing={2}>
                                    <TextInput
                                        xs={12}
                                        md={12}
                                        {...register("item")}
                                        id="item"
                                        isView={isView}
                                        disabled={!isSales}
                                    />
                                </GridSubContainer>
                                <GridSubContainer container spacing={2}>
                                    <Ptext aria-haspopup={isView}>
                                        {message(
                                            createAccountFormThree.textFour
                                        )}
                                    </Ptext>
                                    <TextInput
                                        xs={4}
                                        md={4}
                                        {...register("businessName")}
                                        id="businessName"
                                        isView={isView}
                                        disabled={true}
                                        value={message(
                                            createAccountFormThree.businessName
                                        )}
                                    />

                                    <Ptext aria-haspopup={isView}>
                                        &nbsp;
                                        {message(
                                            createAccountFormThree.textFive
                                        )}
                                    </Ptext>
                                </GridSubContainer>
                            </>
                        )}
                        {isView && (
                            <GridSubContainer container spacing={1}>
                                <Ptext
                                    aria-haspopup={isView}
                                    style={{ textIndent: "1.875rem" }}
                                >
                                    {message(createAccountFormThree.textTwo)}
                                    <SelectInput
                                        options={[
                                            {
                                                value: 1,
                                                label: message(
                                                    "account.form.aggrement.mr"
                                                ),
                                            },
                                            {
                                                value: 2,
                                                label: message(
                                                    "account.form.aggrement.mrs"
                                                ),
                                            },
                                            {
                                                value: 3,
                                                label: message(
                                                    "account.form.aggrement.miss"
                                                ),
                                            },
                                        ]}
                                        {...titleInfo}
                                        isView={isView}
                                        onChange={(e) =>
                                            titleInfo.onChange({
                                                target: {
                                                    value: e.target.value,
                                                },
                                            })
                                        }
                                        name="title"
                                        id="Title"
                                        disabled={!isSales}
                                    />
                                    <TextInput
                                        {...register("firstName")}
                                        id="firstName"
                                        isView={isView}
                                        disabled={!isSales}
                                    />
                                    <TextInput
                                        {...register("lastName")}
                                        id="lastName"
                                        isView={isView}
                                        disabled={!isSales}
                                    />
                                    {"\u00A0"}
                                    {message(createAccountFormThree.textThree)}
                                    {register("item").value ? (
                                        <TextInput
                                            {...register("item")}
                                            id="item"
                                            isView={isView}
                                            disabled={!isSales}
                                        />
                                    ) : (
                                        <b>
                                            {"\u00A0"}
                                            {"-"}
                                        </b>
                                    )}
                                    {"\u00A0"}
                                    {message(createAccountFormThree.textFour)}
                                    <TextInput
                                        {...register("businessName")}
                                        id="businessName"
                                        isView={isView}
                                        disabled={true}
                                        value={message(
                                            createAccountFormThree.businessName
                                        )}
                                    />
                                    <b>
                                        {message(
                                            createAccountFormThree.company
                                        )}
                                    </b>
                                    {"\u00A0"}
                                    {message(createAccountFormThree.textFive)}
                                </Ptext>
                            </GridSubContainer>
                        )}
                        <br />
                        <OrderedList>
                            <ListItem>
                                {!isView ? (
                                    <>
                                        {message(
                                            createAccountFormThree.textPointOne
                                        )}
                                        <GridSubContainer container spacing={1}>
                                            <NumberInput
                                                xs={6}
                                                md={6}
                                                label={""}
                                                labelVal={false}
                                                {...register(
                                                    "adjustedCreditLimit"
                                                )}
                                                inputPropsVal={
                                                    <InputAdornment position="end">
                                                        ฿
                                                    </InputAdornment>
                                                }
                                                id="price"
                                                labelType="row"
                                                iconData={message(
                                                    createAccountFormThree.bahtIcon
                                                )}
                                                disabled={
                                                    register(
                                                        "adjustedCreditLimit"
                                                    ).value || !isSales
                                                }
                                            />
                                            <TextInput
                                                xs={6}
                                                md={6}
                                                {...register("priceWords")}
                                                id="priceWords"
                                                isView={isView}
                                                disabled={!isSales}
                                            />
                                        </GridSubContainer>
                                    </>
                                ) : (
                                    <>
                                        {message(
                                            createAccountFormThree.textPointOne
                                        )}
                                        <NumberInput
                                            label={""}
                                            labelVal={false}
                                            {...register("adjustedCreditLimit")}
                                            inputPropsVal={
                                                <InputAdornment position="end">
                                                    {message(
                                                        createAccountFormThree.baht
                                                    )}
                                                </InputAdornment>
                                            }
                                            isView={isView}
                                            id="price"
                                            labelType="row"
                                            iconData={message(
                                                createAccountFormThree.baht
                                            )}
                                            disabled={
                                                register("adjustedCreditLimit")
                                                    .value || !isSales
                                            }
                                        />
                                        <TextInput
                                            {...register("priceWords")}
                                            value={`(${
                                                register("priceWords").value
                                            })`}
                                            id="priceWords"
                                            isView={isView}
                                            disabled={!isSales}
                                        />
                                    </>
                                )}
                            </ListItem>

                            <ListItem>
                                {!isView ? (
                                    <>
                                        {message(
                                            createAccountFormThree.textPointTwoSecOne
                                        )}

                                        <GridSubContainer container spacing={1}>
                                            <TextInput
                                                xs={6}
                                                md={6}
                                                {...register("workingDays")}
                                                id="workingDays"
                                                isView={isView}
                                                disabled={!isSales}
                                                iconData={message(
                                                    createAccountFormThree.days
                                                )}
                                            />
                                            <TextInput
                                                xs={6}
                                                md={6}
                                                {...register(
                                                    "workingDaysWords"
                                                )}
                                                id="workingDaysWords"
                                                isView={isView}
                                                disabled={!isSales}
                                            />
                                        </GridSubContainer>
                                        {message(
                                            createAccountFormThree.textPointTwoSecTwo
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {message(
                                            createAccountFormThree.textPointTwoSecOne
                                        )}
                                        <TextInput
                                            {...register("workingDays")}
                                            id="workingDays"
                                            isView={isView}
                                            disabled={!isSales}
                                        />
                                        <TextInput
                                            {...register("workingDaysWords")}
                                            value={`(${
                                                register("workingDaysWords")
                                                    .value
                                            })`}
                                            id="workingDaysWords"
                                            isView={isView}
                                            disabled={!isSales}
                                        />
                                        {"\u00A0"}
                                        {message(
                                            createAccountFormThree.textPointTwoSecTwo
                                        )}
                                    </>
                                )}
                            </ListItem>

                            <ListItem>
                                {message(createAccountFormThree.textPointThree)}
                            </ListItem>
                            <ListItem>
                                {message(createAccountFormThree.textPointFour)}
                            </ListItem>
                            <ListItem>
                                {!isView ? (
                                    <>
                                        {message(
                                            createAccountFormThree.textPointFiveSecOne
                                        )}
                                        <GridSubContainer container spacing={1}>
                                            <NumberInput
                                                xs={3}
                                                md={3}
                                                label={""}
                                                labelVal={false}
                                                isView={isView}
                                                {...register("CreditTerm")}
                                                inputPropsVal={
                                                    <InputAdornment position="end">
                                                        Days
                                                    </InputAdornment>
                                                }
                                                id="paymentDueDate"
                                                labelType="row"
                                                disabled={true}
                                                iconData={message(
                                                    createAccountFormThree.days
                                                )}
                                            />
                                            <Ptext aria-haspopup={isView}>
                                                {message(
                                                    createAccountFormThree.textPointFiveSecTwo
                                                )}
                                            </Ptext>
                                        </GridSubContainer>
                                    </>
                                ) : (
                                    <>
                                        {message(
                                            createAccountFormThree.textPointFiveSecOne
                                        )}
                                        <NumberInput
                                            label={""}
                                            labelVal={false}
                                            isView={isView}
                                            {...register("CreditTerm")}
                                            inputPropsVal={
                                                <InputAdornment position="end">
                                                    Days
                                                </InputAdornment>
                                            }
                                            id="paymentDueDate"
                                            labelType="row"
                                            disabled={!isSales}
                                            iconData={message(
                                                createAccountFormThree.days
                                            )}
                                        />
                                        {"\u00A0"}
                                        {message(
                                            createAccountFormThree.textPointFiveSecTwo
                                        )}
                                    </>
                                )}
                            </ListItem>
                            <ListItem>
                                {message(createAccountFormThree.textPointSix)}
                            </ListItem>
                            <ListItem>
                                {message(createAccountFormThree.textPointSeven)}
                            </ListItem>
                            <ListItem>
                                {message(createAccountFormThree.textPointEight)}
                            </ListItem>
                            <ListItem>
                                {message(createAccountFormThree.textPointNine)}
                            </ListItem>
                            <ListItem>
                                {message(createAccountFormThree.textPointTen)}
                            </ListItem>
                            <ListItem>
                                {message(
                                    createAccountFormThree.textPointEleven
                                )}
                            </ListItem>
                        </OrderedList>
                        <Ptext aria-haspopup={isView}>
                            {message(createAccountFormThree.textSix)}
                        </Ptext>
                        <AttestInput
                            xs={6}
                            md={6}
                            // disabled={true}
                            label={message(createAccountFormThree.labelSignOne)}
                            name="buyerSign"
                            register={register}
                            id="buyerSign"
                            disabled={true}
                            isView={isView}
                            dateFieldName="buyerSignDate"
                            handleClick={(e) => {
                                setValues({ buyerSignDate: dayjs() });
                            }}
                        />
                        <AttestInput
                            xs={6}
                            md={6}
                            label={message(createAccountFormThree.labelSignTwo)}
                            name="manangerApprovalSign"
                            register={register}
                            id="manangerApprovalSign"
                            disabled={isCustomer || !isValidApprover}
                            isView={isView}
                            dateFieldName="manangerApprovalSignDate"
                            handleClick={() => {
                                setValues({
                                    manangerApprovalSignDate: dayjs(),
                                });
                            }}
                        />
                        <TextInput
                            xs={3}
                            md={3}
                            label=""
                            value={`${register("firstName").value} ${
                                register("lastName").value
                            }`}
                            isView={isView}
                            id="buyerSignName"
                            disabled={true}
                        />
                        <DateInput
                            xs={3}
                            md={3}
                            label=""
                            name={"buyerSignDate"}
                            register={register}
                            id="buyerSignDate"
                            isView={isView}
                            disabled={true}
                        />
                        <TextInput
                            xs={3}
                            md={3}
                            label=""
                            value=""
                            isView={isView}
                            {...register("manangerApprovalSignName")}
                            id="manangerApprovalSignName"
                            disabled={!isValidApprover}
                        />
                        <DateInput
                            xs={3}
                            md={3}
                            label=""
                            name={"manangerApprovalSignDate"}
                            register={register}
                            id="manangerApprovalSignDate"
                            isView={isView}
                            disabled={true}
                        />
                        <FormControlLabel
                            key={"12"}
                            control={
                                <Checkbox
                                    checked={!isView ? check : true}
                                    name={"checkbox"}
                                    color={isView ? "default" : "primary"}
                                    onChange={handleCheckboxChange}
                                />
                            }
                            sx={{ color: "#000000" }}
                            label={
                                <span style={{ fontSize: "1.45rem" }}>
                                    {message("account.form.agree")}
                                </span>
                            }
                            style={{
                                opacity: isView ? 0.4 : 1,
                            }}
                        />
                        {!isView && (
                            <Grid item xs={12} md={12}>
                                <NavButton
                                    name={message(
                                        createAccouuntButtons.previous
                                    )}
                                    variant="contained"
                                    color="success"
                                    onClick={handleBack}
                                    sx={{
                                        float: "left",
                                    }}
                                />
                                <NavButton
                                    name={message(createAccouuntButtons.next)}
                                    variant="contained"
                                    color="success"
                                    disabled={!check}
                                    onClick={handleNext}
                                    sx={{
                                        float: "right",
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </FormFrame>
            </FormContainer>
        </Main>
    );
};

export default CreateAccountStepFour;
