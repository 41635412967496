import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
// import { headCells,rows ,dummyData } from '../../../../utils/apiData';
import ButtonComponent from "../../button/Button";
import {
    Stack,
    Checkbox,
    Typography,
    Toolbar,
    TableSortLabel,
    TableCell,
    TableRow,
    TableHead,
    Box,
} from "../../../sharedComponent";
import { ReactComponent as CircleInfo } from "../../../../assets/circle-info.svg";
import { ReactComponent as FileExport } from "../../../../assets/file-export.svg";
import { IEnhancedTableHead, IEnhancedTableToolbar } from "../../../pages/type";
import useTrans from "../../../../hooks/useTranslation";
import { useCommonContext } from "@betagro/ui-common";
import { ROLES } from "../../../../constants/role.status";

export const EnhancedTableHead = (props: IEnhancedTableHead) => {
    const {
        order,
        orderBy,
        numSelected,
        rowCount,
        columnData,
        onSelectAllClick,
    } = props;
    // const createSortHandler = (event: React.MouseEvent<HTMLInputElement>, newOrderBy: string) => {
    //   onRequestSort(event, newOrderBy);
    // };
    const [message] = useTrans();

    return (
        <TableHead>
            <TableRow className="table-head-row">
                {columnData.checkBox && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            onChange={onSelectAllClick}
                            indeterminate={
                                numSelected > 0 && numSelected < rowCount
                            }
                            checked={rowCount > 0 && numSelected === rowCount}
                            inputProps={{
                                "aria-label": "select all desserts",
                            }}
                            data-testid= "checkbox"
                        />
                    </TableCell>
                )}
                {columnData?.column ? columnData?.column.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        // align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={
                            orderBy === headCell.id ? order.order : false
                        }
                        sx={{ flexDirection: "row" }}
                    >
                        {headCell.sortable ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={
                                    orderBy === headCell.id
                                        ? order
                                        : ("asc" as any)
                                } // Type mismatch
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order.order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        ) : (
                            headCell.label
                        )}
                    </TableCell>
                )): []}
                {props.pageAction && (
                    <TableCell>
                        {message("createAccount.table.column.link")}
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    );
};

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    // onRequestSort: PropTypes.func.isRequired,
    // onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export const EnhancedTableToolbar = (props: IEnhancedTableToolbar) => {
    const { numSelected, name, icon, buttonLabel, showExport, handleExport } =
        props;
    const [message] = useTrans();
    const { userContextInfo } = useCommonContext();

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(
                            theme.palette.primary.main,
                            theme.palette.action.activatedOpacity
                        ),
                }),
            }}
        >
            {numSelected > 0 ? (
                <>
                    <Typography
                        sx={{ flex: "1 1 100%" }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                        className="lead-table-container"
                    >
                        {message(name)} {icon && <CircleInfo />}
                    </Typography>
                    <Typography
                        sx={{ flex: "1 1 100%" }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                        className="lead-table-container"
                    >
                        {`(${numSelected} selected)`}
                    </Typography>
                </>
            ) : (
                <Typography
                    sx={{ flex: "1 1 100%" }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                    className="lead-table-container"
                >
                    {message(name)} {icon && <CircleInfo />}
                </Typography>
            )}

            {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
            <Stack spacing={2} direction="row">
                {showExport && (
                    <ButtonComponent
                        name={message(
                            "sharedComponent.table.header.button.export"
                        )}
                        variant="outlined"
                        color="success"
                        sx={{
                            borderRadius: "16px",
                            color: "#84BD00",
                            gap: "8px",
                            whiteSpace: "nowrap",
                            textTransform: "none",
                        }}
                        onClick={handleExport}
                        icon={<FileExport />}
                        disabled={userContextInfo.role > ROLES.MANAGER}
                    />
                )}
                {buttonLabel && (
                    <ButtonComponent
                        onClick={props.handleButtonClick}
                        name={message(buttonLabel)}
                        variant="contained"
                        color="success"
                        disabled={userContextInfo.role !== ROLES.SALES}
                        sx={{
                            borderRadius: "16px",
                            backgroundColor: "#84BD00",
                            textTransform: "none",
                            whiteSpace: "nowrap",
                        }}
                    />
                )}
            </Stack>
        </Toolbar>
    );
};
EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};
