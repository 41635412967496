import React from "react";
import {
    Grid,
    Stack,
    Box,
    TextInput,
    NumberInput,
} from "../../../sharedComponent/index";
import useTrans from "../../../../hooks/useTranslation";
import { number, required } from "../../../../validation/validation";
import useForm from "../../../../hooks/useForm";

interface IAddressWrapper {
    defaultValues: any;
    index: number;
    handleChange?: any;
    isView: boolean;
    isSales?: boolean;
    register?: (name: string, validation: (value: string) => void) => void;
}

const AddressWrapper = ({
    defaultValues,
    isView,
    handleChange,
    index,
    isSales,
}: IAddressWrapper) => {
    const [message] = useTrans();
    const { register } = useForm({
        defaultValues: defaultValues,
        onChange: handleChange,
        key: index,
    });
    // const defaultDelivertAddress = register("defaultAddress")
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <Stack flexDirection={"row"} spacing={{ md: 2 }} useFlexGap>
                        <TextInput
                            xs={12}
                            md={12}
                            isView={isView}
                            label={message("sharedComponent.address.houseNo")}
                            {...(register("houseNo", required("")) as any)}
                            id="houseNo"
                            disabled={!isSales}
                        />
                        <TextInput
                            xs={12}
                            md={12}
                            isView={isView}
                            label={message("sharedComponent.address.soi")}
                            {...(register("soi") as any)}
                            id="soi"
                            disabled={!isSales}
                        />
                        <TextInput
                            xs={12}
                            md={12}
                            isView={isView}
                            label={message("sharedComponent.address.floor")}
                            {...(register("building") as any)}
                            id="building"
                            disabled={!isSales}
                        />
                    </Stack>
                </Grid>
                <Grid item md={12}>
                    <Stack flexDirection={"row"} spacing={{ md: 2 }} useFlexGap>
                        <TextInput
                            xs={12}
                            md={12}
                            isView={isView}
                            label={message("sharedComponent.address.address")}
                            {...(register("address", required("")) as any)}
                            id="address"
                            disabled={!isSales}
                        />
                    </Stack>
                </Grid>
                <Grid item md={12}>
                    <Stack flexDirection={"row"} spacing={{ md: 2 }} useFlexGap>
                        <TextInput
                            xs={12}
                            md={12}
                            isView={isView}
                            label={message("sharedComponent.address.province")}
                            {...(register("province", required("")) as any)}
                            id="province"
                            disabled={!isSales}
                        />
                        <TextInput
                            xs={12}
                            md={12}
                            isView={isView}
                            label={message("sharedComponent.address.district")}
                            {...(register("district", required("")) as any)}
                            id="district"
                            disabled={!isSales}
                        />
                    </Stack>
                </Grid>
                <Grid item md={12}>
                    <Stack flexDirection={"row"} spacing={{ md: 2 }} useFlexGap>
                        <TextInput
                            xs={12}
                            md={12}
                            isView={isView}
                            label={message(
                                "sharedComponent.address.subDistrict"
                            )}
                            options={[
                                {
                                    value: "thong Song Hong",
                                    label: "Thong Song Hong",
                                },
                            ]}
                            {...(register("subdistrict", required("")) as any)}
                            id="subdistrict"
                            disabled={!isSales}
                        />
                        <NumberInput
                            xs={12}
                            md={12}
                            isView={isView}
                            label={message(
                                "sharedComponent.address.postalCode"
                            )}
                            {...(register(
                                "postalCode",
                                required(""),
                                number()
                            ) as any)}
                            id="postalCode"
                            disabled={!isSales}
                        />
                    </Stack>
                </Grid>
            </Grid>
            {/* <FormControlLabel
        control={
          <Checkbox
            checked={defaultValues?.defaultAddress}
            onChange={(e) => {
              defaultDelivertAddress.onChange({
                target: { value: e.target.checked },
              });
            }}
            disabled={!isSales}
          />
        }
        label={message(
          "account.form.six.aggrement.text.defaultDelivertAddress"
        )}
      /> */}
        </Box>
    );
};

export default AddressWrapper;
