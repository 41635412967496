import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "../../sharedComponent";

type Props = {
    children: string | React.ReactNode | JSX.Element[];
    isLoading: boolean;
    height?: string;
};

const SpinnerWrapper = ({ children, isLoading, height }: Props) => {
    return (
        <>
            {isLoading && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        height: height || "calc(50vh)",
                        alignItems: "center",
                    }}
                    data-testid="loading-spinner"
                >
                    <CircularProgress />
                </Box>
            )}
            {!isLoading && children}
        </>
    );
};

export default SpinnerWrapper;
