export const apiUrl = "";

// Error Code
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK";
export const NETWORK_CONNECTION_MESSAGE =
    "Cannot connect to server, Please try again.";
export const NETWORK_TIMEOUT_MESSAGE =
    "A network timeout has occurred, Please try again.";

export const ACCOUNT_FORM_HEADING = "account.form.heading";

export const attestInput = {
    apply: "attest.input.button.apply",
    crear: "attest.input.button.clear",
};

export const aggrement = {
    title: "account.form.six.aggrement.text.title",
    date: "account.form.six.aggrement.text.date",
    with: "account.form.six.aggrement.text.with",
    aggrementDate: "account.form.six.aggrement.text.aggrementDate",
    branch: "account.form.six.aggrement.text.branch",
    firstAggrement: "account.form.six.aggrement.text.firstAggrement",
    secondAggrement: "account.form.six.aggrement.text.secondAggrement",
    thirdAggrement: "account.form.six.aggrement.text.thirdAggrement",
    fourthAggrement: "account.form.six.aggrement.text.fourthAggrement",
    fifthAggrement: "account.form.six.aggrement.text.fifthAggrement",
    SixthAggrement: "account.form.six.aggrement.text.sixthAggrement",
    seventhAggrement: "account.form.six.aggrement.text.seventhAggrement",
    eigthAggrement: "account.form.six.aggrement.text.eigthAggrement",
    nineAggrement: "account.form.six.aggrement.text.nineAggrement",
    tenthAggrement: "account.form.six.aggrement.text.tenthAggrement",
    address: "account.form.six.aggrement.text.address",
    addAddress: "account.form.six.aggrement.text.addAddress",
    seller: "account.form.six.aggrement.text.seller",
    buyer: "account.form.six.aggrement.text.buyer",
    witness1: "account.form.six.aggrement.text.firstWitness",
    witness2: "account.form.six.aggrement.text.secondWitness",
    witnessName1: "account.form.six.aggrement.text.firstWitnessName",
    witnessName2: "account.form.six.aggrement.text.secondWitnessName",
    buyerSign: "account.form.six.aggrement.text.buyerSign",
    sellerSign: "account.form.six.aggrement.text.sellerSign",
};

export const createAccouuntButtons = {
    save: "account.form.button.save",
    submit: "account.form.button.submit",
    next: "account.form.button.next",
    previous: "account.form.button.previous",
};
export const createAccountFormOne = {
    title: "account.form.one.aggrement.title",
    body: "account.form.one.aggrement.body",
    consent: "account.form.one.aggrement.consent",
    note: "account.form.one.aggrement.note",
    header: "account.form.one.aggrement.header",
    secOne: "account.form.one.aggrement.secOne",
    labelTitle: "account.form.one.aggrement.label.title",
    name: "account.form.one.aggrement.label.name",
    labelFirstName: "account.form.one.aggrement.label.firstName",
    labelLastName: "account.form.one.aggrement.label.lastName",
    labelNationalId: "account.form.one.aggrement.label.nationalId",
    labelCheckOne: "account.form.one.aggrement.label.checkOne",
    labelCheckTwo: "account.form.one.aggrement.label.checkTwo",
};

export const createAccountFormTwo = {
    title: "account.form.two.aggrement.title",
    secOne: "account.form.two.aggrement.secOne",
    secTwo: "account.form.two.aggrement.secTwo",
    secThree: "account.form.two.aggrement.secThree",
    secFour: "account.form.two.aggrement.secFour",
    labelFirstName: "account.form.two.aggrement.label.firstName",
    labelLastName: "account.form.two.aggrement.label.lastName",
    labelHouseNo: "account.form.two.aggrement.label.houseNo",
    labelSoi: "account.form.two.aggrement.label.soi",
    labelBuilding: "account.form.two.aggrement.label.building",
    labelAddress: "account.form.two.aggrement.label.address",
    labelProvince: "account.form.two.aggrement.label.province",
    labelDistrict: "account.form.two.aggrement.label.district",
    labelSubDistrict: "account.form.two.aggrement.label.subDistrict",
    labelPostalCode: "account.form.two.aggrement.label.postalCode",
    labelCompanyName: "account.form.two.aggrement.label.companyName",
    labelSignOne: "account.form.two.aggrement.label.signOne",
    labelSignTwo: "account.form.two.aggrement.label.signTwo",
    businessName: "account.form.two.aggrement.businessName",
};

export const createAccountFormThree = {
    title: "account.form.three.aggrement.title",
    baht: "account.form.three.aggrement.baht",
    bahtIcon: "account.form.three.aggrement.iconData.baht",
    days: "account.form.three.aggrement.days",
    businessName: "account.form.three.aggrement.body.businessName",
    textOne: "account.form.three.aggrement.body.textOne",
    textTwo: "account.form.three.aggrement.body.textTwo",
    textThree: "account.form.three.aggrement.body.textThree",
    textFour: "account.form.three.aggrement.body.textFour",
    textFive: "account.form.three.aggrement.body.textFive",
    company: "account.form.three.aggrement.body.company",
    textPointOne: "account.form.three.aggrement.body.textPointOne",
    textPointTwoSecOne: "account.form.three.aggrement.body.textPointTwoSecOne",
    textPointTwoSecTwo: "account.form.three.aggrement.body.textPointTwoSecTwo",
    textPointThree: "account.form.three.aggrement.body.textPointThree",
    textPointFour: "account.form.three.aggrement.body.textPointFour",
    textPointFiveSecOne:
        "account.form.three.aggrement.body.textPointFiveSecOne",
    textPointFiveSecTwo:
        "account.form.three.aggrement.body.textPointFiveSecTwo",
    textPointSix: "account.form.three.aggrement.body.textPointSix",
    textPointSeven: "account.form.three.aggrement.body.textPointSeven",
    textPointEight: "account.form.three.aggrement.body.textPointEight",
    textPointNine: "account.form.three.aggrement.body.textPointNine",
    textPointTen: "account.form.three.aggrement.body.textPointTen",
    textPointEleven: "account.form.three.aggrement.body.textPointEleven",
    textSix: "account.form.three.aggrement.label.body.textSix",
    labelSignOne: "account.form.three.aggrement.label.signOne",
    labelSignTwo: "account.form.three.aggrement.label.signTwo",
    labelCheckOne: "account.form.three.aggrement.label.checkOne",
};

export const createAccountFormAnalysis = {
    title: "account.form.tanalysisaggrement.title",
    customerName: "account.form.tanalysisaggrement.customerName",
    secOne: "account.form.analysis.aggrement.secOne",
    secTwo: "account.form.analysis.aggrement.secTwo",
    secThree: "account.form.analysis.aggrement.secThree",
    labelTitle: "account.form.analysis.aggrement.label.title",
    labelFirstName: "account.form.analysis.aggrement.label.firstName",
    labelLastName: "account.form.analysis.aggrement.label.lastName",
    labelNationalID: "account.form.analysis.aggrement.label.nationalID",
    labelColProduct: "account.form.analysis.aggrement.label.col.product",
    labelColTotalPurchaseQntity:
        "account.form.analysis.aggrement.label.col.totalPurchaseQntity",
    labelColPricePerUnit:
        "account.form.analysis.aggrement.label.col.pricePerUnit",
    labelColDaysPerOrder:
        "account.form.analysis.aggrement.label.col.daysPerOrder",
    labelColPurchaseValue:
        "account.form.analysis.aggrement.label.col.purchaseValue",

    labelRowPork: "account.form.analysis.aggrement.label.row.pork",
    labelRowChicken: "account.form.analysis.aggrement.label.row.chicken",
    labelRowEgg: "account.form.analysis.aggrement.label.row.egg",
    labelRowDuck: "account.form.analysis.aggrement.label.row.duck",
    labelRowFish: "account.form.analysis.aggrement.label.row.fish",
    labelRowProcessedMeat:
        "account.form.analysis.aggrement.label.row.processedMeat",
    labelRowProcessedFood:
        "account.form.analysis.aggrement.label.row.processedFood",
    labelRowLivePig: "account.form.analysis.aggrement.label.row.livePig",
    labelRowLiveChicken:
        "account.form.analysis.aggrement.label.row.liveChicken",
    labelRowFreshFish: "account.form.analysis.aggrement.label.row.freshFish",
    labelRowOthers: "account.form.analysis.aggrement.label.row.others",
    labelRowTotalPurchaseValue:
        "account.form.analysis.aggrement.label.row.totalPurchaseValue",

    labelColCreditTerm: "account.form.analysis.aggrement.label.col.creditTerm",
    labelColCreditDay: "account.form.analysis.aggrement.label.col.creditDay",
    labelColTotalCreditLimit:
        "account.form.analysis.aggrement.label.col.totalCreditLimit",
    labelColAdjustedCreditLimit:
        "account.form.analysis.aggrement.label.col.adjustedCreditLimit",
    labelCheckOne: "account.form.analysis.aggrement.label.checkOne",

    labelSignOne: "account.form.analysis.aggrement.label.signOne",
    labelSignTwo: "account.form.analysis.aggrement.label.signTwo",
};

export const createAccountFormUpload = {
    title: "account.form.attachment.title",
    uploadId: "account.form.attachment.uploadId",
    uploadIdMask: "account.form.attachment.uploadId.mask.text",
    uploadImage: "account.form.attachment.uploadImage",
    uploadImageView: "account.form.attachment.uploadImageView",
    chooseFile: "account.form.attachment.chooseFile",
};
