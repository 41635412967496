import React from "react";

import { Grid, FormControl } from "@mui/material";
import { styled } from "@mui/material/styles";
import { IDateInput } from "../../../../interface/InputField";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { ReactComponent as CalendarIcon } from "../../../../assets/calendar-day.svg";
const errorStyle = {
    border: "2px solid red",
    borderRadius: "0.6rem",
};
const FormLabel = styled("label")(() => ({
    fontSize: "1.25rem",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    color: "#464B55",
}));

const InputError = styled("span")(() => ({
    color: "red",
}));

const DatePickerInput = styled(DatePicker)(() => ({
    "& .MuiOutlinedInput-notchedOutline": {
        display: "flex",
        border: "none",
        height: "1.538rem",
        padding: "0.75rem 1rem",
    },
    "& div:nth-of-type(1)": {
        display: "flex",
        background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #6C6D71",
        borderRadius: "0.5rem",
    },
    "& input:nth-of-type(1)": {
        backgroundColor: "initial",
    },
    "& .Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgb(232 228 228 / 71%)",
    },
}));

const DisplayVal = styled("span")(() => ({
    fontSize: "1.25rem",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    color: "#464B55",
    margin: "0.5rem",
}));

const DateTextInput = ({
    xs,
    md,
    sx,
    label = "",
    name,
    value,
    disabled = false,
    error,
    labelType = "column",
    register,
    backDateDisabled,
    defaultValue,
    isView,
    format = "DD/MM/YYYY",
}: IDateInput) => {
    const labelStyle = {
        color: "#919295",
        fontSize: "1.25rem",
        fontFamily: "DB Helvethaica X 65 Regular",
        height: "1.5rem",
    };
    const gridLabelStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
    };

    const textStyle = {
        color: "#000000",
    };

    const today = dayjs();
    const fieldName = `${name}`;
    const dateInfo = register(fieldName, null);
    const fieldValue = value || dayjs(dateInfo?.value);
    const formatDate =
        dayjs(fieldValue).format(format) === "Invalid Date"
            ? ""
            : dayjs(fieldValue).format(format);

    return (
        <>
            {label && labelType == "row" && (
                <Grid item md={3} sx={gridLabelStyle}>
                    <FormLabel
                        style={
                            isView != undefined
                                ? { ...labelStyle, ...textStyle }
                                : labelStyle
                        }
                    >
                        {label}
                    </FormLabel>
                </Grid>
            )}
            {isView && !(xs || md) && (
                <span>
                    {/* <CalendarIcon/> */}
                    <b>
                        {"\u00A0"}
                        {formatDate}
                    </b>
                </span>
            )}

            {isView && (xs || md) && (
                <Grid item xs={xs} md={md}>
                    <DisplayVal style={isView != undefined ? textStyle : {}}>
                        {formatDate}
                    </DisplayVal>
                </Grid>
            )}
            {!isView && (
                <Grid item xs={xs} md={md} sx={sx}>
                    <FormControl>
                        {label && labelType != "row" && (
                            <FormLabel
                                style={isView != undefined ? textStyle : {}}
                            >
                                {label}
                            </FormLabel>
                        )}
                        <>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePickerInput
                                    defaultValue={defaultValue}
                                    data-textid={name}
                                    disabled={disabled}
                                    name={name}
                                    minDate={backDateDisabled ? today : null}
                                    {...dateInfo}
                                    onChange={(value: Date) =>
                                        dateInfo.onChange({
                                            target: {
                                                value: value.toISOString(),
                                            },
                                        })
                                    }
                                    value={fieldValue}
                                    format={"DD/MM/YYYY"}
                                    sx={error && errorStyle}
                                />
                            </LocalizationProvider>
                            {error && <InputError>{error}</InputError>}
                        </>
                    </FormControl>
                </Grid>
            )}
        </>
    );
};

export default DateTextInput;
