import * as React from "react";
import LeadsTabs from "./leadsTabs/LeadsTabs";
import { locale } from "../type";
import { Box, Typography, Button } from "../../sharedComponent";
import { styled } from "@mui/material/styles";
import "./index.scss";
import useTrans from "../../../hooks/useTranslation";
import { useNavigate } from "react-router";
import { useCommonContext } from "@betagro/ui-common";
import { ROLES } from "../../../constants/role.status";

const LeadsDashBoard = ({ t, i18n }: locale) => {
    const [message] = useTrans();
    const navigate = useNavigate();
    const { userContextInfo } = useCommonContext();

    React.useLayoutEffect(() => {
        if (!userContextInfo) {
            return;
        }

        if (userContextInfo.role === ROLES.ADMIN) {
            navigate("/upload");
        } else if (userContextInfo.role === ROLES.STOCKS) {
            window.location.href = `${window.location.origin}/order/bulkCreateOrder`;
        }
    }, [userContextInfo]);

    const BoxStyleWrapper = styled(Box)(() => ({
        // width: '100%',
        //maxWidth: 360,
        // bgcolor: 'background.paper',
        borderRadius: "16px",
        backgroundColor: "#fff",
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow:
            "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
        overflow: "hidden",
        padding: "15px",
    }));

    const handleLeadNavigation = () => {
        navigate("/leadInfo/-1/Branch-Leads");
    };

    if (
        userContextInfo.role === ROLES.ADMIN ||
        userContextInfo.role === ROLES.STOCKS
    ) {
        return null;
    }

    return (
        <>
            <Typography
                variant="poster1"
                sx={{
                    padding: "1rem 0rem",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                {message("layout.sideNav.branchLeads")}
                <Button
                    height={"40px"}
                    name={message("layout.sideNav.addNewLead")}
                    onClick={handleLeadNavigation}
                    disabled={userContextInfo?.role !== ROLES.SALES}
                    data-testid="add-new-lead"
                />
            </Typography>
            <BoxStyleWrapper>
                <LeadsTabs buttonLabel={""} icon={false} />
            </BoxStyleWrapper>
            <p style={{ fontSize: 16, textAlign: "center" }}>
                Commit SHA: {process.env.REACT_APP_GIT_COMMIT_SHA || "Unknown"}
            </p>
        </>
    );
};

export default LeadsDashBoard;
