import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

interface callbackType {
    (myArgument: string): void;
}

export default function useForm(info: any) {
    // change in the future
    const [isValid, setisValid] = useState(true);
    const [fields, setData] = useState([] as any);
    const [validation, setValidation] = useState([] as any);
    const [fieldInfo, setFieldInfo] = useState([] as any);
    const [fieldValue, setFieldValue] = useState({
        ...info.defaultValues,
    });
    const [onChange] = useState(info.onChange);

    useEffect(() => {
        validate();
        setValidation({
            ...validation,
        });
    }, [isValid, validation.length]);

    const validateCollection = (validationCollection) => {
        return (value, fieldName, fieldInfo) => {
            return validationCollection
                .map(
                    (validItem) =>
                        validItem && validItem(value, fieldName, fieldInfo)
                )
                .join("");
        };
    };
    const register = (name: string, ...validations) => {
        fields[name] = {
            name: name,
            value: fieldValue[name] || "",
            error: fields[name]?.error,

            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                fieldValue[name] = e.target.value;
                fields[name].isDirty = true;
                validate(name);
                setFieldValue({
                    ...fieldValue,
                });
                setFieldInfo({
                    ...fieldInfo,
                    [name]: {
                        isDirty: true,
                    },
                });
                info.onChange && info.onChange(fieldValue, info.key);
            },
        };

        if (validations && validations.length) {
            validation[name] = validateCollection(validations);
        }
        return fields[name];
    };

    const validate = (name = "") => {
        callWait(() => {
            setisValid(true);

            if (name && validation[name]) {
                fields[name].error = validation[name](
                    fieldValue[name],
                    name,
                    fieldValue
                );
            }

            const isValid = Object.keys(validation).every((key) => {
                if (!fields[key]) {
                    fields[key] = {};
                }
                fields[key].error = validation[key](
                    fieldValue[key],
                    key,
                    fieldValue
                );
                return !fields[key].error;
            });

            setData({
                ...fields,
            });

            setisValid(isValid);

            return isValid;
        });
    };

    const resetValidation = () => {
        setisValid(!isValid);
        setValidation({});
        validate();
    };

    const setFieldValidation = (fieldCollection: any) => {
        for (const key in fieldCollection) {
            validation[key] = validateCollection(fieldCollection[key]);
        }
        setValidation({
            ...validation,
        });
    };
    const getErrorCollection = () => {
        Object.keys(validation).forEach((key) => {
            if (!fields[key]) {
                fields[key] = {};
            }
            fields[key].error = validation[key](
                fieldValue[key],
                key,
                fieldValue
            );
            return !fields[key].error;
        });

        return fields;
    };

    const getValue = (name: string, defaultValue = "") => {
        return fieldValue[name] || defaultValue;
    };

    const setValue = (name: string, value: any) => {
        setFieldValue({
            ...fieldValue,

            [name]: value,
        });

        setFieldInfo({
            ...fieldInfo,

            [name]: {
                isDirty: true,
            },
        });

        setisValid(!isValid);
    };

    const resetValue = (values: String[]) => {
        setFieldValue(
            values || {
                ...info.defaultValues,
            }
        );
    };

    const setValues = (values: any) => {
        setFieldValue({ ...fieldValue, ...values });
        callWait(() => setisValid(!isValid));
    };

    const handleSubmit = (callback: callbackType) => {
        return () => {
            return callback(fieldValue);
        };
    };

    return {
        register,
        setValue,
        getValue,
        handleSubmit,
        isValid,
        fields,
        fieldValue,
        resetValidation,
        validate,
        setFieldValue,
        resetValue,
        setValues,
        setFieldValidation,
        getErrorCollection,
    };
}
function callWait(callBack: Function) {
    setTimeout(() => {
        callBack();
    }, 100);
}
