import React from "react";

import { Grid, FormControl, InputAdornment, InputBase } from "../../index";
import { styled } from "@mui/material/styles";
import { ITextInput } from "../../../../interface/InputField";
import useTrans from "../../../../hooks/useTranslation";
const errorStyle = {
    border: "2px solid red",
};

const FormLabel = styled("label")(() => ({
    fontSize: "1.25rem",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    color: "#464B55",
}));
const InputError = styled("span")(() => ({
    color: "red",
}));

const Input = styled("input")(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "1.538rem",
    padding: "0.75rem 1.5rem",
    background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #6C6D71",
    borderRadius: "0.5rem",
    borderWidth: "0px",
    borderStyle: "solid",
}));
const StyledInput = styled(InputBase)(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // height: "1.538rem",
    padding: "0.75rem 1.5rem",
    background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #6C6D71",
    borderRadius: "0.5rem",
    borderWidth: "0px",
    borderStyle: "solid",
    height: "3rem",
    input___webkit_outer_spin_button: {
        WebkitAppearance: "none",
        margin: "0",
    },
    input___webkit_inner_spin_button: {
        WebkitAppearance: "none",
        margin: "0",
    },
}));

const NumberInput = ({
    xs,
    md,
    value,
    label,
    name,
    hidden = false,
    disabled = false,
    error,
    onChange,
    labelType = "column",
    labelVal = true,
    iconData,
    type = "number",
    isView = undefined,
    inputComponent,
    labelSize = 3,
    isBold = false,
    inputStyle = {},
    testId
}: ITextInput) => {
    const labelStyle = {
        color: "#919295",
        fontSize: inputStyle.fontSize ? inputStyle.fontSize : "1.25rem",
        fontFamily: "DB Helvethaica X 65 Regular",
    };
    const gridLabelStyle = {
        display: "flex",
        alignItems: "center",
        // justifyContent: "end",
    };

    const textStyle = {
        color: "#000000",
    };

    const DisplayVal = styled("span")(() => ({
        fontSize: inputStyle.fontSize ? inputStyle.fontSize : "1.25rem",
        fontWeight: "400",
        display: "flex",
        alignItems: "center",
        color: "#464B55",
        margin: "0.5rem",
    }));
    const [message] = useTrans();
    return (
        <>
            {label && labelType == "row" && labelVal && (
                <Grid item xs={labelSize} md={labelSize} sx={gridLabelStyle}>
                    {labelType == "row" && (
                        <FormLabel
                            style={
                                isView != undefined
                                    ? { ...labelStyle, ...textStyle }
                                    : labelStyle
                            }
                        >
                            {message(label)}
                        </FormLabel>
                    )}
                </Grid>
            )}
            {isView && !(xs || md) && (
                <span>
                    <b>
                        {"\u00A0"}
                        {value ? value : "0"}
                        {iconData ? ` ${iconData}` : ""}
                    </b>
                </span>
            )}
            {isView && (xs || md) && (
                <Grid item xs={xs} md={md}>
                    {isBold ? (
                        <DisplayVal
                            style={isView != undefined ? textStyle : {}}
                        >
                            <b>
                                {value ? value : "0"}{" "}
                                {iconData ? ` ${iconData}` : ""}
                            </b>
                        </DisplayVal>
                    ) : (
                        <DisplayVal>
                            {value ? value : "0"}{" "}
                            {iconData ? ` ${iconData}` : ""}
                        </DisplayVal>
                    )}
                </Grid>
            )}
            {!isView && (
                <Grid item xs={xs} md={md}>
                    <FormControl fullWidth>
                        {label && labelType != "row" && (
                            <FormLabel
                                style={isView != undefined ? textStyle : {}}
                            >
                                {message(label)}
                            </FormLabel>
                        )}
                        <StyledInput
                            inputComponent={inputComponent}
                            id={name}
                            endAdornment={
                                <InputAdornment position="end">
                                    {iconData}
                                </InputAdornment>
                            }
                            value={value}
                            inputProps={{"data-testid":testId}}
                            name={name}
                            data-testid={name}
                            hidden={hidden}
                            disabled={disabled}
                            onChange={onChange}
                            type={inputComponent ? "text" : type}
                            style={
                                error
                                    ? errorStyle
                                    : { margin: "6px 0px 6px 0px" }
                            }
                        />
                        {error && <InputError>{error}</InputError>}
                    </FormControl>
                </Grid>
            )}
        </>
    );
};

export default NumberInput;
