import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
    interface TypographyVariants {
        poster1: React.CSSProperties;
        poster2: React.CSSProperties;
        poster3: React.CSSProperties;
        poster4: React.CSSProperties;
        poster5: React.CSSProperties;
        title: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        poster1?: React.CSSProperties;
        poster2?: React.CSSProperties;
        poster3?: React.CSSProperties;
        poster4?: React.CSSProperties;
        poster5?: React.CSSProperties;
        title: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        poster1: true;
        poster2: true;
        poster3: true;
        poster4: true;
        poster5: true;
        title: true;
    }
}

const theme = createTheme({
    typography: {
        htmlFontSize: 14,
        fontFamily: "'DB Helvethaica X 55 Regular'",
        fontSize: 14,
        title: {
            fontFamily: "'DB Helvethaica X 65 Med'",
            fontSize: "2.5rem",
            lineHeight: "42px",
            fontWeight: "500",
            color: "#47474A",
        },
        subtitle1: {
            fontFamily: "'DB Helvethaica X 55 Regular'",
            fontSize: "1.3rem",
            lineHeight: "24px",
            fontWeight: "500",
            color: "#47474A",
        },
        subtitle2: {
            fontFamily: "'DB Helvethaica X 55 Regular'",
            fontSize: "1.3rem",
            lineHeight: "24px",
            fontWeight: "500",
            color: "#84BD00",
        },
        poster1: {
            fontFamily: "'DB Helvethaica X 65 Med'",
            fontSize: "3rem",
            lineHeight: "60px",
            fontWeight: "500",
            color: "#47474A",
        },
        poster2: {
            fontFamily: "'DB Helvethaica X 65 Med'",
            fontSize: "1.875rem",
            lineHeight: "60px",
            fontWeight: "550",
        },
        poster3: {
            fontFamily: "'DB Helvethaica X 55 Regular'",
            fontSize: "3rem",
            lineHeight: "60px",
            fontWeight: "550",
        },
        poster4: {
            fontFamily: "'DB Helvethaica X 35 Thin'",
            fontSize: "3rem",
            lineHeight: "60px",
            fontWeight: "550",
        },
        poster5: {
            fontFamily: "'DB Helvethaica X 55 Regular'",
            fontSize: "1rem",
            lineHeight: "24px",
            fontWeight: "500",
            color: "#47474A",
        },
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    // Map the new variant to render a <h1> by default
                    poster1: "poster1",
                    poster2: "poster2",
                    poster3: "poster3",
                    poster4: "poster4",
                },
            },
        },

        MuiChip: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    backgroundColor: "#EFF6E7",
                    color: "#84BD00",
                },
            },
        },
        MuiPaginationItem: {
            defaultProps: {
                // @ts-expect-error Material-UI issue
                components: { previous: () => "Previous", next: () => "Next" },
            },
        },
        // MuiListItemText:{
        //     styleOverrides: {
        //         // Name of the slot
        //         root: {
        //           // Some CSS
        //           fontFamily: "'DB Helvethaica X 55 Regular'",
        //           fontSize: '20px',
        //           lineHeight: '24px',
        //           fontWeight: '400',
        //           color:'#47474A'
        //         },
        //       },
        // }
    },
});

export default theme;
