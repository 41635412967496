import Axios from "./axiosInstance";
import { GET_ALL_USERS, GET_USER } from "./uriConstant";

// axios.defaults.proxy.host = "https://dev-bsale.betagro.com"

export const getUserInfo = () => {
    return Axios.get(GET_USER);
};

export const getAllUsersInfo = () => {
    return Axios.get(GET_ALL_USERS);
};
