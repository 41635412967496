import React from "react";
import dayjs from "dayjs";
import {
    Box,
    Paper,
    Grid,
    Typography,
    Chip,
    Divider,
} from "../../../sharedComponent";
import { ReactComponent as SuitCaseIcon } from "../../../../assets/suitcase.svg";
import {
    IconWrapper,
    HeadingWrapper,
    SubHeadingWrapper,
    DividerStyle,
    GridWrapperStyle,
    GridContainerWrapper,
    GridButtonWrapper,
} from "../segment/activityStyle";
import ButtonComponent from "../../../sharedComponent/button/Button";
import { ActivityBox, ActivityBoxSmall } from "./activityBox";
import useTrans from "../../../../hooks/useTranslation";
import { dateFormat } from "../../../../utils/global";
import { useCommonContext } from "@betagro/ui-common";

const ActivityList = ({ list, handleOpen, handleNote, isEditPermission }) => {
    const [expand, setExpand] = React.useState(false);
    const [message] = useTrans();
    const commonContext = useCommonContext();

    const handleExpand = () => {
        setExpand(!expand);
    };

    const handleNotes = (e, id) => {
        handleOpen();
        handleNote(e, id);
    };

    return (
        <>
            <Box component={Paper} m={2}>
                <GridContainerWrapper item container gap={2} p={1}>
                    <GridWrapperStyle item lg={1} md={1} onClick={handleExpand}>
                        <IconWrapper>
                            <SuitCaseIcon
                                width={20}
                                height={20}
                                fill="rgba(132, 189, 0, 1)"
                            />
                        </IconWrapper>
                        {expand ? (
                            <DividerStyle>
                                <Divider
                                    orientation="vertical"
                                    variant="middle"
                                />
                            </DividerStyle>
                        ) : null}
                    </GridWrapperStyle>
                    <Grid item lg={8} md={8} onClick={handleExpand}>
                        <HeadingWrapper>
                            <Typography>
                                {dayjs(list?.activityDate).format("YYYY-MM-DD")}
                            </Typography>
                        </HeadingWrapper>
                        <SubHeadingWrapper>
                            <SuitCaseIcon />
                            <Typography variant="subtitle1">{`${
                                commonContext?.userContextInfo?.firstNameEn ||
                                commonContext?.userContextInfo?.firstNameTh
                            } ${
                                commonContext?.userContextInfo?.lastNameEn ||
                                commonContext?.userContextInfo?.lastNameTh
                            }`}</Typography>
                            {list?.visit && <Chip label={"Visited"}></Chip>}
                            {list?.call && <Chip label={"Called"}></Chip>}
                            <Typography>
                                {" "}
                                {message(
                                    "pages.leadDetails.leadInfo.thisLeadOn"
                                )}
                            </Typography>
                            <Typography variant="subtitle1">
                                {dateFormat(list?.activityDate)}
                            </Typography>
                        </SubHeadingWrapper>
                    </Grid>
                    <GridButtonWrapper
                        item
                        lg={2}
                        md={1}
                        display={"flex"}
                        justifyContent={"end"}
                    >
                        {isEditPermission && (
                            <ButtonComponent
                                name={message(
                                    "pages.leadDetails.leadInfo.addNote"
                                )}
                                variant="outlined"
                                color="success"
                                onClick={(e) => handleNotes(e, list.id)}
                                sx={{
                                    borderRadius: "8px",
                                    border: "1px solid #84BD00",
                                    textTransform: "none",
                                }}
                            />
                        )}
                    </GridButtonWrapper>
                </GridContainerWrapper>
                <Grid item container gap={4} p={1}>
                    <GridWrapperStyle item lg={1} md={1} p={2}>
                        {expand ? (
                            <>
                                <Typography>
                                    {dateFormat(list.activityDate)}
                                </Typography>
                                <DividerStyle>
                                    <Divider
                                        orientation="vertical"
                                        variant="middle"
                                    />
                                </DividerStyle>
                            </>
                        ) : (
                            ""
                        )}
                    </GridWrapperStyle>
                    <Grid item lg={10} md={10}>
                        {expand ? <ActivityBox list={list} /> : ""}
                    </Grid>
                </Grid>
                {list.notes?.length > 0 && (
                    <Grid item container gap={4} p={1}>
                        <GridWrapperStyle item lg={1} md={2}>
                            {expand ? (
                                <>
                                    <Typography>
                                        {dateFormat(list.activityDate)}
                                    </Typography>
                                    <Typography>
                                        {dayjs(list.activityDate).format(
                                            "hh:mm"
                                        )}
                                    </Typography>
                                    <DividerStyle>
                                        <Divider
                                            orientation="vertical"
                                            variant="middle"
                                        />
                                    </DividerStyle>
                                </>
                            ) : (
                                ""
                            )}
                        </GridWrapperStyle>
                        <Grid item lg={10} md={10}>
                            {expand ? (
                                <ActivityBoxSmall notes={list.notes} />
                            ) : (
                                ""
                            )}
                            <div onClick={handleExpand}>
                                {expand ? "View less" : "view More"}
                            </div>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </>
    );
};

export default ActivityList;
