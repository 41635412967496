export const BASE_ROUTE = "/";
export const GET_LEADS = `${BASE_ROUTE}api/lead/search`;
export const GET_ACCOUNTS = `${BASE_ROUTE}api/account/search`;
export const GET_ACCOUNT_STATS = `${BASE_ROUTE}api/account/stats/all`;
export const SENT_TO_BCOS = `${BASE_ROUTE}api/account/update/eform`;
export const GET_LEADS_INFO = `${BASE_ROUTE}api/lead/`;
export const POST_LEADS = `${BASE_ROUTE}api/lead/v2`;
export const GET_AUTH = `/v1/authentication/aad`;
export const GET_USER = `/v1/authentication/user`;
export const GET_LOGIN_URL = "/login";
export const GET_LOGOUT_URL = "/v1/authentication/aad/logout";
export const GET_NEAR_BY_PLACE = `${BASE_ROUTE}api/nfis/getNearByPlace`;
export const GET_BACKGROUND_URL = "/api/backgroundinfo";
export const POCKET_SIZE = "/api/pocketsizes";
export const LEAD_SEGMENTATION = `/api/channels`;
export const UPDATE_LEAD_SEGMENTATION = `/api/lead/channel`;
export const Update_SEGMENTATION = `/api/lead/channel`;
export const LEAD_IMAGE_UPDATE = `/api/upload/saveLeadLogoOrBackdropImg`;
export const ASSIGN_LEADS = `${BASE_ROUTE}api/lead/assignment`;
export const SET_CREATE_PLACE = `${BASE_ROUTE}api/lead/create-place/`;
export const GET_ACTIVITY_LIST = `${BASE_ROUTE}api/activity`;
export const UPLOAD_FILES = `${BASE_ROUTE}api/upload`;
export const REMOVE_FILES = `${BASE_ROUTE}api/upload`;
export const GET_ACTIVITY_LIST_NOTE = `${BASE_ROUTE}api/activity/note`;
export const GET_CUSTOMER_SEARCH = `${BASE_ROUTE}api/nfis/searchCustomer`;
export const GET_ALL_USERS = `${BASE_ROUTE}api/user/all/list`;
export const GET_LEAD_MARK_AS_SUCCESSFUL = `${BASE_ROUTE}api/lead/mark/success`;
export const GET_LEAD_MARK_AS_FAILED = `${BASE_ROUTE}api/lead/mark/failed`;
export const GET_USER_ROLE = `${BASE_ROUTE}api/user/roles`;
export const GET_USER_POST = `${BASE_ROUTE}api/user`;
export const PUT_ACCOUNT = `${BASE_ROUTE}api/account`;
export const GET_ACCOUNT = `${BASE_ROUTE}api/account`;
export const SUBMIT_ACCOUNT = `${BASE_ROUTE}api/account/approval`;
export const REJECT_ACCOUNT_FORM = `${BASE_ROUTE}api/account/mark/reject`;
export const SUBMIT_CUSTOMER_FORM = `${BASE_ROUTE}api/account/customer/submit`;
export const GENERTE_ACCOUNT_LINK = `${BASE_ROUTE}api/sms/link/generate`;
export const VERIFY_OTP_AUTH = `/v1/authentication/otp/verify`;
export const GET_OTP = `/v1/authentication/otp/generate`;
export const SET_ATTACHMENTS = "/api/account/add/attachment";
export const GENERATE_SIGN_OTP = "/api/sms/otp/generate";
export const VERIFY_SIGN_OTP = "/api/sms/otp/verify";

export const POCKET_SIZE_CONFIG = "/api/upload/pocketPrice";
export const POCKET_SIZE_RANGE_CONFIG = "/api/upload/pocketPriceRange";

export const UPLOAD_PRICE_RANGE_DATA = "api/upload/importPriceRangeExcelToDB";
export const UPLOAD_PRICE_DATA = "api/upload/importPriceExcelToDB";

//Online form pdf generation
export const CREATE_ONLINE_FORM = `${BASE_ROUTE}api/account/e-form`;
