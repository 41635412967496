import React from "react";

import {
    Grid,
    FormControl,
    Checkbox,
    FormControlLabel,
    FormGroup,
} from "../../../sharedComponent";
import { styled } from "@mui/material/styles";
import { ICheckBoxInput } from "../../../../interface/InputField";

const errorStyle = {
    border: "2px solid red",
};
const FormLabel = styled("label")(() => ({
    fontSize: "1.25rem",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    color: "#464B55",
}));

const InputError = styled("span")(() => ({
    color: "red",
}));

const Input = styled("input")(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "1.538rem",
    padding: "0.75rem 1rem",
    background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #6C6D71",
    borderRadius: "0.5rem",
    borderWidth: "0px",
    borderStyle: "solid",
}));

const CheckboxInput = ({
    xs,
    md,
    label,
    name,
    options = [],
    disabled = false,
    error,
    handleChange,
}: ICheckBoxInput) => {
    return (
        <Grid item xs={xs} md={md}>
            <FormControl>
                <FormGroup>
                    <FormLabel>{label}</FormLabel>
                    {options.map((op, index) => {
                        // eslint-disable-next-line react/jsx-key
                        return (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                        value={op.value}
                                        name={name}
                                        data-textid={name}
                                        disabled={disabled}
                                        // checked={values.includes(op.value)}
                                        onChange={handleChange}
                                        style={error ? errorStyle : null}
                                    />
                                }
                                label={op.label}
                            />
                        );
                    })}
                </FormGroup>
                {error && <InputError>{error}</InputError>}
            </FormControl>
        </Grid>
    );
};

export default CheckboxInput;
