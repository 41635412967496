import { IAccountFilters } from "../interfaces/account";
import Axios from "./axiosInstance";
import {
    GET_ACCOUNTS,
    GET_ACCOUNT_STATS,
    PUT_ACCOUNT,
    GET_ACCOUNT,
    SUBMIT_ACCOUNT,
    GENERTE_ACCOUNT_LINK,
    GET_OTP,
    VERIFY_OTP_AUTH,
    SENT_TO_BCOS,
    SET_ATTACHMENTS,
    SUBMIT_CUSTOMER_FORM,
    REJECT_ACCOUNT_FORM,
    GENERATE_SIGN_OTP,
    VERIFY_SIGN_OTP,
    CREATE_ONLINE_FORM,
} from "./uriConstant";

export const getAccounts = (filters: IAccountFilters) => {
    return Axios.post(`${GET_ACCOUNTS}`, {
        filters,
    });
};

export const generateSignOTP = (data: { leadId: number; type: number }) => {
    return Axios.post(`${GENERATE_SIGN_OTP}`, data);
};

export const verifySignOTP = (data: {
    otp: string;
    type: number;
    leadId: number;
}) => {
    return Axios.post(`${VERIFY_SIGN_OTP}`, data);
};

export const getAccountStats = () => {
    return Axios.get(`${GET_ACCOUNT_STATS}`);
};

export const sentToBCOS = (accountId: string[]) => {
    return Axios.put(`${SENT_TO_BCOS}`, {
        accountId,
    });
};

export const putAccountForm = (data) => {
    return Axios.put(`${PUT_ACCOUNT}`, data);
};

export const generateLink = (data) => {
    return Axios.post(`${GENERTE_ACCOUNT_LINK}`, data);
};

export const submitAccountForm = (data) => {
    return Axios.post(`${SUBMIT_ACCOUNT}`, data);
};

export const createOnlineForm = (data) => {
    return Axios.post(`${CREATE_ONLINE_FORM}`, data);
};

export const rejectAccountForm = (data) => {
    return Axios.post(`${REJECT_ACCOUNT_FORM}/${data.accountId}`, {
        note: data.note,
    });
};

export const setAttachments = (data) => {
    return Axios.post(`${SET_ATTACHMENTS}`, data);
};

export const submitCustomerForm = () => {
    return Axios.put(`${SUBMIT_CUSTOMER_FORM}`);
};

export const getAccountForm = (leadId) => {
    return Axios.get(GET_ACCOUNT + "/" + leadId);
};

export const generateOTP = (leadId) => {
    return Axios.get(GET_OTP, {
        params: {
            leadId: leadId,
        },
    });
};

export const verifyOTP = (leadId, otp) => {
    const url = new URL(
        `${location.origin}${VERIFY_OTP_AUTH}?leadId=${leadId}&otp=${otp}`
    );
    // url.searchParams.append("redirect", location.origin);
    location.href = url.href;
};
