import React from "react";

import { useTranslation } from "react-i18next";
import { useCommonContext } from "@betagro/ui-common";

export function MyComponent() {
    const { t, i18n } = useTranslation();

    return <p>{t("my translated text")}</p>;
}

export enum Language {
    ENGLISH = "en",

    THAI = "th",
}

function useTrans() {
    const { t, i18n } = useTranslation();

    const { selectedLanguage } = useCommonContext();

    React.useEffect(() => {
        changeLanguage(selectedLanguage);
    }, [selectedLanguage]);

    const changeLanguage = (language: string) => {
        if (i18n.language !== language) {
            i18n.changeLanguage(language);
        }
    };

    const message = (
        messageKey: string,
        format?: any,
        defaultMessage = "[MessageNotAvailable]"
    ): any => {
        return t(messageKey || defaultMessage, format);
    };

    return [message, changeLanguage];
}

export default useTrans;
