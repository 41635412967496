export const ROLES = {
    SALES: 1,
    MANAGER: 2,
    GM: 3,
    AVP: 4,
    VP: 5,
    COO: 6,
    CEO: 7,
    STOCKS: 8,
    ADMIN: 9,
};
