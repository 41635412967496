import React, { useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import {
    Typography,
    Grid,
    Button,
    UploadInput,
    FormControlLabel,
    Checkbox,
} from "../../sharedComponent";
import useTrans from "../../../hooks/useTranslation";
import { useWaitCall } from "../../../hooks/useCall";
import { uploadFiles, removeFile } from "../../../services/LeadsInfoApi";
import { AlertContext } from "../../../context/alterContext";

import {
    createAccountFormUpload,
    createAccouuntButtons,
} from "../../../utils/constants";
import { ICreateAccountForm } from "../type";
import { createFileforUpload } from "../../../utils/commonFunc";

const Main = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0rem",
    gap: "1.688rem",
    width: "55.5rem",
    height: "81rem",
    left: "27rem",
    top: "8rem",
}));

const FormContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0rem 0rem 2rem",
    gap: "1.5rem",
    width: "55.5rem",
    height: "75.875rem",
    background: "#FFFFFF",
    boxShadow: "0rem 0.25rem 5rem rgba(0, 0, 0, 0.04)",
    borderRadius: "1rem",
}));

const FormHead = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: "5.125rem",
    borderBottom: "0.063rem solid #EAEBEB",
    borderRadius: "1rem 16rem 0rem 0rem",
}));

const FormFrame = styled("form")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "4rem",
    padding: "0rem",
    width: "49.5rem",
    height: "67.25rem",
}));

const NavButton = styled(Button)(() => ({
    borderRadius: "2rem",
    backgroundColor: "#84BD00",
    width: "8.25rem",
    textTransform: "none",
    marginTop: "1rem",
}));

const CreateAccountStepUpload = ({
    register,
    handleBack,
    handleNext,
    fieldValue,
    setValues,
    isView,
    isCustomer,
}: ICreateAccountForm) => {
    const [message] = useTrans();
    const [check, setCheck] = React.useState(false);
    const handleCheckboxChange = () => {
        if (isView) {
            return;
        }

        setCheck(!check);
    };

    const alert = React.useContext(AlertContext);

    const uploadIdCard = useWaitCall(
        (data) => uploadFiles(data),
        [],
        (result) => {
            const newList = result?.data?.data?.data || [];
            const uploadedIdCard = fieldValue["idCard"]?.length
                ? fieldValue["idCard"]
                : [];
            const updatedList = [...uploadedIdCard, ...newList];
            setValues({ idCard: updatedList });
            alert.setSuccess("Uploaded Successfully");
        },
        (e) => {
            alert.setError("something went wrong");
        }
    );

    const removeIdCard = useWaitCall(
        async (name) => {
            await removeFile({ name });
            return name;
        },
        [],
        (result) => {
            const updatedList = fieldValue["idCard"].filter(
                (val) => !(val === result.data)
            );
            setValues({ idCard: updatedList });
            alert.setSuccess("File deleted Successfully");
        },
        () => {
            alert.setError("something went wrong");
        }
    );

    const uploadCustomerImages = useWaitCall(
        (data) => uploadFiles(data),
        [],
        (result) => {
            const newList = result?.data?.data?.data || [];
            const uploadedCustomerImages = fieldValue["customerImages"]?.length
                ? fieldValue["customerImages"]
                : [];
            const updatedList = [...uploadedCustomerImages, ...newList];
            setValues({ customerImages: updatedList });
            alert.setSuccess("Uploaded Successfully");
        },
        () => {
            alert.setError("something went wrong");
        }
    );

    const removeCustomerImages = useWaitCall(
        async (name) => {
            await removeFile({ name });
            return name;
        },
        [],
        (result) => {
            const updatedList = fieldValue["customerImages"].filter(
                (val) => !(val === result.data)
            );
            setValues({ customerImages: updatedList });
            alert.setSuccess("File deleted Successfully");
        },
        () => {
            alert.setError("something went wrong");
        }
    );

    const handleIdCard = async (val) => {
        if (val.length) {
            const data = new FormData() as any;
            for (let i = 0; i < val.length; i++) {
                const file = await createFileforUpload(val[i], i);
                data.append("File", file);
            }
            uploadIdCard.callback(data);
        }
    };

    const handleRemoveIdCard = (name) => {
        removeIdCard.callback(name);
    };

    const handleCustomerImages = (val) => {
        if (val.length) {
            const data = new FormData() as any;
            for (let i = 0; i < val.length; i++) {
                const extension = val[i].name.substr(val[i].name.lastIndexOf('.') + 1);
                const filename = `CUSTOMER-IMAGE-${i + 1}.${extension}`;
                data.append("File", val[i], filename);
            }
            uploadCustomerImages.callback(data);
        }
    };

    const handleRemoveCustomerImages = (name) => {
        removeCustomerImages.callback(name);
    };

    return (
        <Main>
            {/* <TitleMain>
        <Typography variant="poster1">{message(ACCOUNT_FORM_HEADING)}</Typography>
        <NavButton
          name={message(createAccouuntButtons.save)}
          variant="contained"
          color="success"
        />
      </TitleMain> */}
            <FormContainer>
                <FormHead>
                    <Typography sx={{ mx: 5 }} variant="poster2">
                        {message(createAccountFormUpload.title)}
                    </Typography>
                </FormHead>
                <FormFrame>
                    <Grid container spacing={1}>
                        <UploadInput
                            xs={12}
                            md={12}
                            handleChange={handleIdCard}
                            uploadedFiles={fieldValue["idCard"]}
                            removeUploadedFiles={handleRemoveIdCard}
                            multiple={true}
                            label={message(createAccountFormUpload.uploadId)}
                            maskText={message(
                                createAccountFormUpload.uploadIdMask
                            )}
                            {...register("idCard")}
                            id="idCard"
                            name="idCard"
                            isCustomer={isCustomer}
                            disabled={
                                !isCustomer || fieldValue["idCard"] != null
                            }
                            isView={isView}
                            viewDocType="file"
                        />
                        <UploadInput
                            xs={12}
                            md={12}
                            handleChange={handleCustomerImages}
                            uploadedFiles={fieldValue["customerImages"]}
                            removeUploadedFiles={handleRemoveCustomerImages}
                            multiple={true}
                            label={
                                !isView
                                    ? message(
                                          createAccountFormUpload.uploadImage
                                      )
                                    : message(
                                          createAccountFormUpload.uploadImageView
                                      )
                            }
                            {...register("customerImages")}
                            id="customerImages"
                            name="customerImages"
                            isCustomer={isCustomer}
                            disabled={!isCustomer}
                            isView={isView}
                            viewDocType="image"
                        />
                        <FormControlLabel
                            key={"12"}
                            control={
                                <Checkbox
                                    checked={!isView ? check : true}
                                    name={"checkbox"}
                                    color={isView ? "default" : "primary"}
                                    onChange={handleCheckboxChange}
                                />
                            }
                            sx={{ color: "#000000" }}
                            label={message("account.form.agree")}
                            style={{
                                opacity: isView ? 0.4 : 1,
                            }}
                        />

                        {!isView && (
                            <Grid item xs={12} md={12}>
                                <NavButton
                                    name={message(
                                        createAccouuntButtons.previous
                                    )}
                                    variant="contained"
                                    color="success"
                                    onClick={handleBack}
                                    sx={{
                                        float: "left",
                                    }}
                                />
                                <NavButton
                                    name={message(createAccouuntButtons.next)}
                                    variant="contained"
                                    color="success"
                                    onClick={handleNext}
                                    disabled={!check}
                                    sx={{
                                        float: "right",
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </FormFrame>
            </FormContainer>
        </Main>
    );
};

export default CreateAccountStepUpload;
