import React, { useMemo } from "react";
import ListDivider from "./listDivider/ListDivider";
import "./index.scss";
import AssignmentList from "./listDivider/assignmentList";
import LeadSegmentComponent from "./segment";
import { useParams } from "react-router-dom";
import { ILeads } from "../../../interfaces/edit-lead";
import BackgroundInfo from "./backgroundInfo";
import Activity from "./activity";
import "./index.scss";
import { useCommonContext } from "@betagro/ui-common";
import { ROLES } from "../../../constants/role.status";

interface IProps {
    tabName?: String;
    data?: any;
    refresh?: any;
}

const LeadsDetails = ({
    data,
    refresh,
    tabName,
}: IProps): React.ReactElement => {
    const { id } = useParams();
    const isNew = id === "-1";
    const [background, setbackground] = React.useState({});
    const handleBackgroundData = (data) => {
        setbackground(data);
    };
    const commonContext = useCommonContext();

    const isEditPermission = useMemo(() => {
        const roleCheck = commonContext?.userContextInfo?.role === ROLES.SALES;
        const empCode = commonContext?.userContextInfo?.employeeId;
        const permission =
            !data?.leadSalesMappings?.empCode ||
            data?.leadSalesMappings?.empCode === empCode;

        return permission && roleCheck;
    }, []);

    const SelectedTab = (tabName) => {
        switch (tabName) {
            case "sharedComponent.tabs.header.segmentation":
                return (
                    <LeadSegmentComponent
                        refresh={refresh}
                        leadInfo={data}
                        isEditPermission={isEditPermission}
                    />
                );
            case "sharedComponent.tabs.header.leadInfo":
                return (
                    <ListDivider
                        refresh={refresh}
                        leadInfo={data as ILeads}
                        isEditPermission={isEditPermission}
                        isMapEditable={true}
                    />
                );
            case "sharedComponent.tabs.header.backgroundInfo":
                return (
                    <BackgroundInfo
                        refresh={refresh}
                        leadInfo={data as ILeads}
                        isEditPermission={isEditPermission}
                    />
                );
            case "sharedComponent.tabs.header.activity":
                return (
                    <Activity
                        refresh={refresh}
                        leadInfo={data as ILeads}
                        isEditPermission={isEditPermission}
                    />
                );
            default:
                break;
        }
    };

    return (
        <div className="list-divider">
            {SelectedTab(tabName)}
            {!isNew ? (
                <AssignmentList
                    refresh={refresh}
                    assignData={data}
                    backroungData={background}
                    leadId={id}
                    isEditPermission={isEditPermission}
                />
            ) : (
                <div style={{ flex: "0 0 30%" }}> </div>
            )}
        </div>
    );
};

export default LeadsDetails;
