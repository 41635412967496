import React from "react";
import { Typography } from "@mui/material";

const TypographyWrapper = (props: any): React.ReactElement => {
    return (
        <Typography {...props} sx={{ color: "#000000", ...props.sx }}>
            {props.children}
        </Typography>
    );
};

export default TypographyWrapper;
