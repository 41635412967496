import { styled } from "@mui/material/styles";
import { CardContent, CardHeader, Typography } from "../../../sharedComponent";

export const MainContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    "& > div": {
        marginBottom: "16px",
    },
}));
export const MainWrapper = styled("div")(() => ({
    flex: 2,
}));
export const CardWrapper = styled("div")(() => ({
    width: "100%",
}));
export const CardListWrapper = styled("div")(() => ({
    display: "flex",
    justifyContent: "space-between",
    "& > div": {
        flex: 1,
        marginRight: "16px",
    },
    "& > div:last-child": {
        flex: 1,
        marginRight: "0px",
    },
}));

export const ValueWrapper = styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end !important",
    "& > b": {
        flex: "0 !important",
        marginRight: "16px",
    },
    "& > b:last-child": {
        flex: "0 !important",
        marginRight: "0px",
    },
}));

export const CardActionsWrapper = styled("div")(() => ({
    display: "flex",
    justifyContent: "flex-end",
    padding: "16px",
    "& > *:first-of-type": {
        marginRight: "5px",
    },
}));

export const CardContainerWrapper = styled("div")(() => ({
    margin: "0px",
    padding: "0px 16px",
}));

export const CardHeaderWrapper = styled(CardHeader)(() => ({
    padding: "16px 16px 0px 16px",
    color: "#464B55",
}));

export const CardVAlueWrapper = styled("div")(() => ({
    margin: "0px",
    fontSize: "1.8rem",
    fontWeight: 800,
    color: "#464B55",
}));

export const FieldsWrapper = styled("div")(() => ({
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px",

    alignItems: "flex-start",
    "& > *": {
        flex: 1,
    },
    "& > *:first-of-type": {
        marginRight: "5px",
    },
}));
interface iTable {
    gridSize?: number;
    IsleftAlign?: boolean;
    margin?: string;
}
export const TableWrapper = styled("div")<iTable>(
    ({ gridSize = 2, IsleftAlign, margin }) => ({
        display: "flex",
        width: "100%",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        alignItems: "center",
        flex: 1,
        justifyContent: "flex-start",
        "& > div,b": {
            flex: gridSize ? gridSize : 1,
            display: "flex",
            justifyContent: IsleftAlign ? "flex-start" : "flex-end",
            "& > b": {
                marginRight: "5px",
            },
            margin: margin || "5px",
        },
        "& > div:first-of-type,b:first-of-type": {
            flex: 1,
            justifyContent: "flex-start",
        },
    })
);

export const CardContentWrapper = styled(CardContent)(() => ({
    padding: "0px 16px",
}));

export const Heading = styled(Typography)(() => ({
    fontSize: "1.5rem",
    fontFamily: "'DB Helvethaica X 65 Med'",
}));
