import React, { useState, useMemo, useContext, useEffect } from "react";
import {
    Card,
    CardHeader,
    IconButton,
    Typography,
    Divider,
    CardContent,
    Button,
    SelectInput,
    NumberInput,
    RadioInput,
    IMaskInput,
    Grid,
} from "../../../sharedComponent";
import useTrans from "../../../../hooks/useTranslation";
import { ReactComponent as EditIcon } from "../../../../assets/pen.svg";
import { ReactComponent as RefreshIcon } from "../../../../assets/refresh.svg";
import {
    CardActionsWrapper,
    CardWrapper,
    Heading,
    MainWrapper,
    TableWrapper,
} from "../segment/segmentStyle";
import useForm from "../../../../hooks/useForm";
import useCall, { useWaitCall } from "../../../../hooks/useCall";
import { AlertContext } from "../../../../context/alterContext";
import {
    getBackgroundInfo,
    setBackgroundInfo,
    getSearchCustomerInfo,
} from "../../../../services/BackgroundgetApi";
import SpinnerWrapper from "../../../sharedComponent/spinWrapper";
import { phoneValidation, required } from "../../../../validation/validation";
import { useCommonContext } from "@betagro/ui-common";
interface IBackgroundSegment {
    leadInfo: any;
    refresh: any;
    isEditPermission: boolean;
}
interface IBackgroundInfo {
    type: number;
    nationalId: string;
    phoneNumber: string;
    branchCode: string;
    customerId: string;
    membershipId: string;
}

interface leadId {
    leadId: number;
}

const TextMaskCustom = React.forwardRef<HTMLElement, any>(
    function TextMaskCustom(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="0-0000-00000-00-0"
                definitions={{
                    "#": /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value: any) =>
                    onChange({ target: { name: props.name, value } })
                }
                overwrite
            />
        );
    }
);
const Row = ({ children, isEdit }) => {
    return (
        <TableWrapper
            margin={!isEdit && "15px 0px"}
            IsleftAlign={true}
            gridSize={2}
        >
            {children}
        </TableWrapper>
    );
};
const BackgroundInfo = ({ leadInfo, isEditPermission }: IBackgroundSegment) => {
    const commonContext = useCommonContext();
    const [message] = useTrans();
    const [refresh, setRefresh] = useState(false);
    const backgroundApiCall = useCall(
        () => getBackgroundInfo(leadInfo.id as any),
        [refresh],
        []
    );
    const [data, setData] = useState({} as IBackgroundInfo);

    useEffect(() => {
        if (!backgroundApiCall.isLoading) {
            setValues({
                ...backgroundApiCall?.data?.data?.data,
                branchCode: "00000",
            });
            setData(backgroundApiCall?.data?.data?.data);
        }
        if (backgroundApiCall.error) {
            alert.setError("something went wrong");
        }
    }, [backgroundApiCall.isLoading]);

    const initialValue = {
        type: data?.type || 1,
        nationalId: data?.nationalId || "",
        phoneNumber:
            data?.phoneNumber ||
            (leadInfo.contactNumber && leadInfo.contactNumber[0]),
        branchCode: "00000",
        customerId: data?.customerId || "NA",
        membershipId: data?.membershipId || "NA",
        leadId: leadInfo.id,
    };
    const { register, getValue, handleSubmit, validate, setValues, isValid } =
        useForm({
            defaultValues: initialValue,
        });

    const alert = useContext(AlertContext);
    const backgroundUpdateApiCall = useWaitCall(
        (segmentInfo) => setBackgroundInfo(segmentInfo),
        {},
        () => {
            alert.setSuccess("Save Successfully");
            commonContext.refreshCount();
            // refresh();
            setRefresh(!refresh);
            setEdit(false);
        },
        () => {
            alert.setError("something went wrong");
        }
    );

    const handleCustomerId = (customerId) => {
        setValues({ ...data, customerId: customerId });
        setData({ ...data, customerId: customerId });
    };

    const getCustomerId = useWaitCall(
        (customerInfo) => getSearchCustomerInfo(customerInfo),
        {},
        (result) => {
            handleCustomerId(result?.data?.data?.data?.customerID);
            alert.setSuccess("Save Successfully");
            setEdit(false);
        },
        (err) => {
            alert.setError(`${err.data.message}`);
        }
    );

    const handleRefresh = () => {
        const customerInfo = {
            taxNo: data?.nationalId?.split("-").join(""),
            branchCode: "00000",
        };

        if (!customerInfo.taxNo) {
            alert.setError("taxNo is required");
        } else if (!leadInfo?.placeId) {
            alert.setError("Place Id is required");
        } else {
            getCustomerId.callback(customerInfo);
        }
    };

    const onSubmit = (data) => {
        if (data.nationalId.replaceAll("-", "").length !== 13) {
            alert.setError("Tax id must be 13 digits");
            return;
        }

        backgroundUpdateApiCall.callback(data);
    };

    const [isEdit, setEdit] = useState(false);

    const val = [" ", "NA"];
    return (
        <MainWrapper>
            <SpinnerWrapper isLoading={backgroundApiCall.isLoading}>
                <Card>
                    <CardHeader
                        action={
                            !isEdit &&
                            isEditPermission && (
                                <div
                                    onClick={() => {
                                        setEdit(true);
                                        validate();
                                    }}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <IconButton aria-label="settings">
                                        <EditIcon
                                            width={12}
                                            height={12}
                                            fill={"#919295"}
                                            cursor={"pointer"}
                                        />
                                    </IconButton>
                                    {message("edit")}
                                </div>
                            )
                        }
                        title={
                            <>
                                <Heading variant="h6">
                                    {message(
                                        "pages.leadDetails.backgroundInfo.heading"
                                    )}
                                </Heading>
                            </>
                        }
                    />
                    <Divider />
                    <CardContent>
                        <CardWrapper>
                            <Row isEdit={isEdit}>
                                <RadioInput
                                    labelType="row"
                                    label={message(
                                        "pages.leadDetails.backgroundInfo.type"
                                    )}
                                    isView={!isEdit}
                                    xs={6}
                                    md={6}
                                    options={[
                                        {
                                            name: "personal",
                                            label: "Personal",
                                            value: 1,
                                        },
                                        {
                                            name: "juristic",
                                            label: "Juristic person",
                                            value: 2,
                                        },
                                    ]}
                                    {...register("type", null)}
                                    id="type"
                                />
                            </Row>
                            <Row isEdit={isEdit}>
                                <NumberInput
                                    {...register("nationalId")}
                                    isView={!isEdit}
                                    id={"dasdsad"}
                                    type="number"
                                    labelType="row"
                                    label={message(
                                        "pages.leadDetails.backgroundInfo.national_tax_id"
                                    )}
                                    xs={6}
                                    md={6}
                                    inputComponent={TextMaskCustom}
                                />
                            </Row>
                            <Row isEdit={isEdit}>
                                <NumberInput
                                    isView={!isEdit}
                                    type="number"
                                    labelType="row"
                                    labelVal={"Segment"}
                                    label={message(
                                        "pages.leadDetails.backgroundInfo.phone_no"
                                    )}
                                    xs={6}
                                    md={6}
                                    {...register(
                                        "phoneNumber",
                                        required(""),
                                        phoneValidation("")
                                    )}
                                />
                            </Row>
                            <Row isEdit={isEdit}>
                                <NumberInput
                                    isView={!isEdit}
                                    type="number"
                                    labelType="row"
                                    labelVal={"Segment"}
                                    label={message(
                                        "pages.leadDetails.backgroundInfo.branchcode"
                                    )}
                                    xs={6}
                                    md={6}
                                    disabled={true}
                                    {...register("branchCode", null)}
                                />
                            </Row>
                            <Row isEdit={isEdit}>
                                <NumberInput
                                    isView={!isEdit}
                                    type="number"
                                    labelType="row"
                                    labelVal={"Segment"}
                                    label={message(
                                        "pages.leadDetails.backgroundInfo.customerId"
                                    )}
                                    xs={6}
                                    md={6}
                                    disabled={true}
                                    {...register("customerId", null)}
                                />
                                {!isEdit &&
                                    (val.includes(getValue("customerId")) ||
                                        getValue("customerId") === "") && (
                                        <Grid item xs={6} md={6}>
                                            <IconButton
                                                aria-label="settings"
                                                onClick={handleRefresh}
                                            >
                                                <RefreshIcon />
                                            </IconButton>
                                        </Grid>
                                    )}
                            </Row>
                            <Row isEdit={isEdit}>
                                <NumberInput
                                    isView={!isEdit}
                                    type="number"
                                    labelType="row"
                                    labelVal={"Segment"}
                                    label={message(
                                        "pages.leadDetails.backgroundInfo.membershipId"
                                    )}
                                    xs={6}
                                    md={6}
                                    disabled={true}
                                    {...register("membershipId", null)}
                                />
                            </Row>
                        </CardWrapper>
                    </CardContent>
                    {isEdit && (
                        <CardActionsWrapper>
                            <Button
                                onClick={() => {
                                    setEdit(false);
                                    setValues(initialValue);
                                }}
                                name={message(
                                    "sharedComponent.listDivider.leadInfo.button.cancel"
                                )}
                                variant="text"
                            ></Button>
                            <Button
                                disabled={!isValid}
                                isLoading={backgroundUpdateApiCall.isLoading}
                                onClick={handleSubmit(onSubmit)}
                                name={message(
                                    "sharedComponent.listDivider.leadInfo.button.saveChanges"
                                )}
                            ></Button>
                        </CardActionsWrapper>
                    )}
                </Card>
            </SpinnerWrapper>
        </MainWrapper>
    );
};

export default BackgroundInfo;
