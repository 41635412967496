export enum StatusEnum {
    NEW = 1,
    IN_PROGRESS = 2,
    INCOMPLETE = 3,
    SUCCESSFUL = 4,
    FAILED = 5,
    ASSIGNED = 6,
    READY = 7,
    ARCHIVED = 8,
    FORM_IN_PROGRESS = 9,
    REVIEWED = 10,
    COMPLETE = 11,
    REJECTED = 12,
}
