import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import { Grid } from "../../sharedComponent";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import "./index.scss";
import { styled } from "@mui/material/styles";

import { timeConvert } from "../../../utils/timeconvert";
interface ITimePicker {
    disable?: boolean;
    name?: string;
    value?: string;
    onChange?: any;
    error?: string;
}
const InputError = styled("span")(() => ({
    color: "red",
}));

const TimeRangePicker = ({ value, onChange, error }: ITimePicker) => {
    const time = value ? value.split("||") : [null, null];
    const [valueStart, setValueStart] = React.useState<Dayjs | null>(
        dayjs(time[0])
    );

    const [valueEnd, setValueEnd] = React.useState<Dayjs | null>(
        dayjs(time[1])
    );

    const handleTime = (start, end) => {
        end = `${end}` == "Invalid Date" ? null : end;
        start = `${start}` == "Invalid Date" ? null : start;

        setValueEnd(end);
        setValueStart(start);
        onChange({ target: { value: `${start}||${end}` } });
    };

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container>
                    <Grid item lg={4}>
                        <TimeField
                            value={valueStart}
                            onChange={(newValue) =>
                                handleTime(newValue, valueEnd)
                            }
                            ampm={false}
                        />
                    </Grid>
                    <Grid item lg={4} pl={2}>
                        <TimeField
                            value={valueEnd}
                            onChange={(newValue) =>
                                handleTime(valueStart, newValue)
                            }
                            ampm={false}
                        />
                    </Grid>
                    <Grid item lg={8} pl={0} style={{ textAlign: "left" }}>
                        {error && <InputError>{error}</InputError>}
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </>
    );
};

export default TimeRangePicker;
