import React from "react";
import {
    Typography,
    Divider,
    Box,
    Paper,
    Grid,
    Checkbox,
    FormControlLabel,
    Button,
} from "../../sharedComponent/index";
import { styled } from "@mui/material/styles";
import { TextInput, NumberInput } from "../../sharedComponent";
import { AddressMap } from "../../AddressMap";
import { ICreateAccountForm } from "../type";
import useTrans from "../../../hooks/useTranslation";
const AddressWrapperStyle = styled("div")(() => ({
    padding: "1rem",
}));

const NavButton = styled(Button)(() => ({
    borderRadius: "2rem",
    backgroundColor: "#84BD00",
    width: "8.25rem",
    textTransform: "none",
}));

const CustomerMap = ({
    register,
    handleNext,
    handleBack,
    isView,
    isSales,
    id,
}: ICreateAccountForm) => {
  
    const [check, setCheck] = React.useState(false);
    const [message] = useTrans();
    const latitude = register("latitude");
    const longitude = register("longitude");

    const handleCheckboxChange = () => {
        if (isView) {
            return;
        }

        setCheck(!check);
    };
    const handleCoordsUpdate = (lat: number, long: number) => {
        latitude.onChange({ target: { value: lat } });
        longitude.onChange({ target: { value: long } });
    };
    const style = {
        fontSize: "1.45rem",
    };
    return (
        <Box id={id} component={Paper} sx={{ borderRadius: "1rem" }}>
            <Typography variant="poster2" sx={{ padding: "1rem 2rem" }}>
                {message("pages.stepper.label.five")}
            </Typography>
            <Divider />
            {!isView ? (
                <Grid container item gap={2} p={4}>
                    <TextInput
                        inputStyle={style}
                        xs={4}
                        md={5}
                        subText={false}
                        isView={isView}
                        disabled={!isSales}
                        label={message(
                            "sharedComponent.listDivider.leadInfo.shopName"
                        )}
                        {...register("companyName")}
                        id="companyName"
                    />
                    <TextInput
                        inputStyle={style}
                        xs={4}
                        md={5}
                        subText={false}
                        isView={isView}
                        disabled={true}
                        label={message(
                            "pages.createAccountSteps.customerMap.contactPersonName"
                        )}
                        value={`${register("firstName")?.value} ${
                            register("lastName")?.value
                        }`}
                        id="contactPersonName"
                    />
                    <NumberInput
                        inputStyle={style}
                        xs={4}
                        md={5}
                        subText={false}
                        isView={isView}
                        disabled={!isSales}
                        label={message(
                            "sharedComponent.listDivider.leadInfo.contactNumber"
                        )}
                        {...register("contactNumber")}
                        id="contactNmuber"
                    />
                    <TextInput
                        inputStyle={style}
                        xs={4}
                        md={5}
                        subText={false}
                        isView={isView}
                        disabled={!isSales}
                        label={message(
                            "pages.createAccountSteps.customerMap.salesRepresentative"
                        )}
                        {...register("salesRepresntative")}
                        id="salesRepresntative"
                    />
                    <NumberInput
                        inputStyle={style}
                        xs={4}
                        md={5}
                        subText={false}
                        isView={isView}
                        disabled={!isSales}
                        label={message(
                            "pages.createAccountSteps.customerMap.salesRepContactno"
                        )}
                        {...register("salesRepContactNumber")}
                        id="salesRepContactNumber"
                    />
                    <NumberInput
                        inputStyle={style}
                        xs={3}
                        md={3}
                        subText={false}
                        isView={isView}
                        disabled={!isSales}
                        label={message(
                            "pages.createAccountSteps.customerMap.latitude"
                        )}
                        {...register("latitude")}
                        id="Latitude"
                        testId="lati-tude"
                    />
                    <NumberInput
                        inputStyle={style}
                        xs={3}
                        md={3}
                        subText={false}
                        isView={isView}
                        disabled={!isSales}
                        {...register("longitude")}
                        id="longitude"
                        label={message(
                            "pages.createAccountSteps.customerMap.longitude"
                        )}
                        testId="longi-tude"
                    />
                    <TextInput
                        inputStyle={style}
                        xs={12}
                        md={12}
                        subText={false}
                        isView={isView}
                        disabled={!isSales}
                        label={`${message(
                            "pages.createAccountSteps.customerMap.SummaryOfDetails"
                        )} ${message("sharedComponent.label.optional")}`}
                        {...register("summaryOfDetails")}
                        id="summaryOfDetails"
                    />
                </Grid>
            ) : (
                <Grid container item rowSpacing={2} p={4}>
                    <TextInput
                        inputStyle={style}
                        xs={4}
                        md={4}
                        subText={false}
                        isView={isView}
                        labelSize={2}
                        disabled={!isSales}
                        label={message(
                            "sharedComponent.listDivider.leadInfo.shopName"
                        )}
                        labelType="row"
                        {...register("companyName")}
                        id="companyName"
                    />
                    <TextInput
                        inputStyle={style}
                        xs={4}
                        md={4}
                        subText={false}
                        isView={isView}
                        labelSize={2}
                        labelType="row"
                        disabled={!isSales}
                        label={message(
                            "pages.createAccountSteps.customerMap.salesRepresentative"
                        )}
                        {...register("salesRepresntative")}
                        id="salesRepresntative"
                    />
                    <TextInput
                        inputStyle={style}
                        xs={4}
                        md={4}
                        subText={false}
                        isView={isView}
                        labelSize={2}
                        disabled={true}
                        labelType="row"
                        label={message(
                            "pages.createAccountSteps.customerMap.contactPersonName"
                        )}
                        value={`${register("firstName")?.value} ${
                            register("lastName")?.value
                        }`}
                        id="contactPersonName"
                    />
                    <NumberInput
                        inputStyle={style}
                        xs={4}
                        md={4}
                        subText={false}
                        isView={isView}
                        labelSize={2}
                        labelType="row"
                        disabled={!isSales}
                        label={message(
                            "pages.createAccountSteps.customerMap.salesRepContactno"
                        )}
                        {...register("salesRepContactNumber")}
                        id="salesRepContactNumber"
                    />
                    <NumberInput
                        inputStyle={style}
                        xs={4}
                        md={4}
                        subText={false}
                        isView={isView}
                        labelSize={2}
                        disabled={!isSales}
                        labelType="row"
                        label={message(
                            "sharedComponent.listDivider.leadInfo.contactNumber"
                        )}
                        {...register("contactNumber")}
                        id="contactNmuber"
                    />

                    <NumberInput
                        inputStyle={style}
                        xs={4}
                        md={4}
                        subText={false}
                        isView={isView}
                        labelSize={2}
                        labelType="row"
                        disabled={!isSales}
                        label={`${message(
                            "pages.createAccountSteps.customerMap.latitude"
                        )}, ${message(
                            "pages.createAccountSteps.customerMap.longitude"
                        )}`}
                        {...register("latitude")}
                        value={`${Number(latitude?.value)},${Number(
                            longitude?.value
                        )}`}
                        id="Latitude"
                    />

                    <TextInput
                        inputStyle={style}
                        xs={4}
                        md={4}
                        subText={false}
                        isView={isView}
                        labelSize={2}
                        labelType="row"
                        disabled={!isSales}
                        label={message(
                            "pages.createAccountSteps.customerMap.SummaryOfDetails"
                        )}
                        {...register("summaryOfDetails")}
                        id="summaryOfDetails"
                    />
                </Grid>
            )}
            <AddressWrapperStyle>
                <AddressMap
                    onUpdateCoords={handleCoordsUpdate}
                    longitude={Number(longitude?.value)}
                    latitude={Number(latitude?.value)}
                    isEditable={isSales}
                />
            </AddressWrapperStyle>
            <Box sx={{ padding: "1rem" }}>
                <FormControlLabel
                    key={"12"}
                    control={
                        <Checkbox
                            checked={!isView ? check : true}
                            name={"checkbox"}
                            color={isView ? "default" : "primary"}
                            onChange={handleCheckboxChange}
                            data-testid="checkbox"
                        />
                    }
                    sx={{ color: "#000000" }}
                    label={
                        <span style={{ fontSize: "1.45rem" }}>
                            {message("account.form.agree")}
                        </span>
                    }
                    style={{
                        opacity: isView ? 0.4 : 1,
                    }}
                />
                {!isView && (
                    <Grid item xs={12} md={12} py={5}>
                        <NavButton
                            name={message("account.form.button.previous")}
                            variant="contained"
                            color="success"
                            onClick={handleBack}
                            sx={{
                                float: "left",
                            }}
                        />
                        <NavButton
                            name={message("account.form.button.next")}
                            variant="contained"
                            color="success"
                            onClick={handleNext}
                            disabled={!check}
                            sx={{
                                float: "right",
                            }}
                        />
                    </Grid>
                )}
            </Box>
        </Box>
    );
};

export default CustomerMap;
