import Axios from "./axiosInstance";
import { StatusEnum } from "./enumConstant";
import { GET_LEADS } from "./uriConstant";

// axios.defaults.proxy.host = "https://dev-bsale.betagro.com"

export const getLeads = (page, rows) => {
    return Axios.post(`${GET_LEADS}`, {
        filters: {
            page: page,
            pageSize: rows,
            isBranchAssigned: false,
            statusFilter: [StatusEnum.SUCCESSFUL, StatusEnum.FAILED],
        },
    });
};

export const getBranchLeads = (page, rows) => {
    return Axios.post(`${GET_LEADS}`, {
        filters: {
            page: page,
            pageSize: rows,
            isBranchAssigned: true,
            archive: false,
            statusFilter: [StatusEnum.SUCCESSFUL, StatusEnum.FAILED],
        },
    });
};
export const getAssignedLeads = (page, rows) => {
    return Axios.post(`${GET_LEADS}`, {
        filters: {
            page: page,
            pageSize: rows,
            isLeadAssigned: true,
            isBranchAssigned: true,
            statusFilter: [StatusEnum.SUCCESSFUL, StatusEnum.FAILED],
        },
    });
};

export const getSuccessfulLeads = (page, rows) => {
    return Axios.post(`${GET_LEADS}`, {
        filters: {
            page: page,
            pageSize: rows,
            isBranchAssigned: true,
            status: StatusEnum.SUCCESSFUL,
        },
    });
};

export const getArchivedLeads = (page, rows) => {
    return Axios.post(`${GET_LEADS}`, {
        filters: {
            page: page,
            pageSize: rows,
            archive: true,
            statusFilter: [StatusEnum.SUCCESSFUL, StatusEnum.FAILED],
        },
    });
};
export const getFailedLeads = (page, rows) => {
    return Axios.post(`${GET_LEADS}`, {
        filters: {
            page: page,
            pageSize: rows,
            isBranchAssigned: true,
            status: StatusEnum.FAILED,
        },
    });
};
