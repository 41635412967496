import React from "react";

import {
    ExcelExport,
    ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { IExportExcel } from "../../../interfaces/common";

const ExportExcel = ({ exporter, fileName, columnList }: IExportExcel) => {
    const defaultColumnList = [
        { field: "id", title: "ID", width: 20 },
        { field: "shopName", title: "Shop Name" },
        { field: "channel.channelName", title: "Channel" },
        { field: "subChannel.subChannelName", title: "Segment" },
        { field: "leadSalesMappings.dueDate", title: "Due Date" },
        { field: "importDate", title: "Import Date" },
        {
            field: "leadBranchMappings.branches.branchName",
            title: "Branch Name",
        },
        { field: "leadSalesMappings.userId", title: "User" },
        { field: "status", title: "Statue" },
    ];

    const getColumns = (list) => {
        return list.map((val,idx) => {
            return (
                // eslint-disable-next-line react/jsx-key
                <ExcelExportColumn key={idx}
                    {...val}
                    cellOptions={{
                        textAlign: "center",
                        borderBottom: "true",
                        borderTop: "true",
                        borderRight: "true",
                        borderLeft: "true",
                    }}
                    headerCellOptions={{
                        background: "#7ae014",
                        textAlign: "center",
                        borderBottom: "true",
                        borderTop: "true",
                        borderRight: "true",
                        borderLeft: "true",
                    }}
                />
            );
        });
    };

    return (
        <>
            <ExcelExport
                data-testid="export-excel"
                // group={group}
                // collapsible={true}
                fileName={fileName}
                ref={exporter}
            >
                {getColumns(
                    columnList?.length ? columnList : defaultColumnList
                )}
            </ExcelExport>
        </>
    );
};

export default ExportExcel;
