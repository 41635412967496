import React, { useState } from "react";

import { Alert } from "../components/sharedComponent";
import Snackbar from "@mui/material/Snackbar";
// import ErrorMessage from "../component/shared/errorMessage";

interface callbackType {
    (myArgument: string): void;
}

export default function useAlert() {
    const [error, setMessage] = useState(null);

    const setError = (
        message: any,
        dataObject = {
            isOffline: false,
        }
    ) => {
        setMessage({
            type: "error",

            message: message,

            isOffline: dataObject.isOffline,
        });
    };

    const setSuccess = (message: any) => {
        setMessage({
            type: "success",

            message: message,
        });
    };

    const setInfo = (message: any) => {
        setMessage({
            type: "info",

            message: message,
        });
    };

    const setWarning = (message: any) => {
        setMessage({
            type: "warning",

            message: message,
        });
    };

    const reset = (callBack?: callbackType) => {
        setMessage(null);

        callBack;
    };
    const handleClose = () => {
        reset();
    };
    const registerAlert = () => {
        return (
            <>
                {error && (
                    <Snackbar
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        open={error}
                        autoHideDuration={6000}
                        onClose={handleClose}
                    >
                        <Alert
                            onClose={handleClose}
                            severity={error.type}
                            sx={{ width: "100%" }}
                        >
                            {error.message}
                        </Alert>
                    </Snackbar>
                )}
            </>
        );
    };

    return {
        registerAlert,

        setError,

        setInfo,

        setSuccess,

        setWarning,

        reset,
    };
}
