import Axios from "./axiosInstance";
import {
    GET_LEADS_INFO,
    LEAD_SEGMENTATION,
    POCKET_SIZE,
    UPDATE_LEAD_SEGMENTATION,
    POCKET_SIZE_CONFIG,
    POST_LEADS,
    GET_ACTIVITY_LIST,
    GET_ACTIVITY_LIST_NOTE,
    LEAD_IMAGE_UPDATE,
    GET_LEAD_MARK_AS_SUCCESSFUL,
    GET_LEAD_MARK_AS_FAILED,
    UPLOAD_FILES,
    REMOVE_FILES,
    POCKET_SIZE_RANGE_CONFIG,
} from "./uriConstant";

export const getLeadsInfo = (id: number) => {
    return Axios.get(GET_LEADS_INFO + id);
};
export const updateLeadsInfo = (id: any, data: any) => {
    if (id === "-1") {
        return Axios.post(POST_LEADS, data);
    }
    return Axios.put(GET_LEADS_INFO + id, data);
};

export const updateLeadLeadLogoOrBackdropImg = (data: any) => {
    return Axios.put(LEAD_IMAGE_UPDATE, data);
};

export const updatePocketSize = (data: any) => {
    return Axios.post(`${POCKET_SIZE}`, data);
};

export const pocketSizeConfig = (data: any) => {
    return Axios.get(`${POCKET_SIZE_CONFIG}`, data);
};

export const pocketRangeConfig = (data: any) => {
    return Axios.get(`${POCKET_SIZE_RANGE_CONFIG}`, data);
};

export const getPocketSize = (leadId: number) => {
    return Axios.get(`${POCKET_SIZE}/${leadId}`);
};

export const getChannel = () => {
    return Axios.get(`${LEAD_SEGMENTATION}`);
};

export const updateChannel = (leadId: number, data) => {
    return Axios.put(`${UPDATE_LEAD_SEGMENTATION}/${leadId}`, data);
};

export const getActivityList = (leadId: number) => {
    return Axios.get(`${GET_ACTIVITY_LIST}/${leadId}`);
};

export const setActivityList = (leadId: number, data: any) => {
    return Axios.post(`${GET_ACTIVITY_LIST}`, data);
};

export const uploadFiles = (data: any) => {
    return Axios.post(`${UPLOAD_FILES}`, data);
};

export const removeFile = (data: any) => {
    return Axios.put(`${REMOVE_FILES}`, data);
};

export const setActivityListNote = (leadId: number, data: any) => {
    return Axios.post(`${GET_ACTIVITY_LIST_NOTE}`, data);
};

export const setLeadMarkAsSuccesfull = (leadId: number) => {
    return Axios.post(`${GET_LEAD_MARK_AS_SUCCESSFUL}/${leadId}`);
};
export const setLeadMarkAsfailed = (leadId: number) => {
    return Axios.post(`${GET_LEAD_MARK_AS_FAILED}/${leadId}`);
};
