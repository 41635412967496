import * as React from "react";
import {
    Typography,
    Link,
    Breadcrumbs,
    Container,
} from "../../sharedComponent";
import { useNavigate } from "react-router-dom";
import "./index.scss";
function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault();
}

export default function BasicBreadcrumbs({ crumbData, leadName }) {
    const navigate = useNavigate();

    return (
        <Container maxWidth="xl">
            <div
                role="presentation"
                className="breadcrumbs"
                onClick={handleClick}
            >
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        underline="hover"
                        color="inherit"
                        onClick={() => navigate(-1) as any}
                    >
                        {leadName}
                    </Link>
                    {/* <Link
          underline="hover"
          color="inherit"
          href="/material-ui/getting-started/installation/"
        >
          Core
        </Link> */}
                    <Typography color="text.primary">
                        {crumbData?.shopName}
                    </Typography>
                </Breadcrumbs>
            </div>
        </Container>
    );
}
