import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { isLatitude, isLongitude } from "../../utils/lat-lng-check";
import { IMapProps } from "../../interfaces/maps";
import "./index.scss";

mapboxgl.accessToken =
    "pk.eyJ1IjoidHVzaGFyMDAxMiIsImEiOiJjbGd5czMzbXYwNDZ6M2VveGF4Z3BveHMwIn0.awE-gP4lE7TLdyABigcu7Q";

const AddressMap = ({
    latitude,
    longitude,
    zoom = Number(15),
    isEditable = false,
    onUpdateCoords,
}: IMapProps) => {
    const lat = isLatitude(latitude) ? latitude : longitude;
    const lng = isLongitude(longitude) ? longitude : latitude;
    const [coords, setCoords] = useState({
        lat: null,
        lng: null,
    });
    const mapContainer = useRef(null);
    const map = useRef(null);
    const marker = useRef(null);

    const coordinateFeature = (lng: number, lat: number) => {
        return {
            center: [lng, lat],
            geometry: {
                type: "Point",
                coordinates: [lng, lat],
            },
            place_name: "Lat: " + lat + " Lng: " + lng,
            place_type: ["coordinate"],
            properties: {},
            type: "Feature",
        };
    };

    const coordinatesGeocoder = (query: string) => {
        const matches = query.match(
            /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
        );

        if (!matches) {
            return null;
        }

        const coord1 = Number(matches[1]);
        const coord2 = Number(matches[2]);
        const geocodes = [];

        if (coord1 < -90 || coord1 > 90) {
            geocodes.push(coordinateFeature(coord1, coord2));
        }

        if (coord2 < -90 || coord2 > 90) {
            geocodes.push(coordinateFeature(coord2, coord1));
        }

        if (geocodes.length === 0) {
            geocodes.push(coordinateFeature(coord1, coord2));
            geocodes.push(coordinateFeature(coord2, coord1));
        }
        return geocodes;
    };

    useEffect(() => {
        if (map.current) {
            map.current.setCenter([lng, lat]);
            if (marker.current && lat && lng) {
                marker.current.setLngLat([lng, lat]);
            }
            return;
        }

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/e-crm/clmp1q9o301yh01nzb1hv6ziw",
            center: [lng, lat],
            zoom,
            attributionControl: false,
            interactive: isEditable,
            preserveDrawingBuffer: true,
            dragPan: isEditable,
        });

        if (isEditable) {
            const geocoder = new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                localGeocoder: coordinatesGeocoder,
                zoom: 14,
                placeholder: "Try: 70, 78",
                mapboxgl: mapboxgl,
                reverseGeocode: true,
                language: "th",
            });

            map.current.addControl(geocoder);

            geocoder.on("result", (e: any) => {
                if (e?.result?.center) {
                    const center = e?.result?.center;
                    setCoords({ ...coords, lat: center[1], lng: center[0] });
                }
            });
        }
    }, [lat, lng]);

    useEffect(() => {
        if (map.current) {
            if (marker.current) {
                marker.current.remove();
            }
            marker.current = new mapboxgl.Marker({
                draggable: false,
            })
                .setLngLat([lng, lat])
                .addTo(map.current);
        }
    }, [map, lat, lng]);

    useEffect(() => {
        if (coords?.lat && coords?.lng) {
            onUpdateCoords(coords.lat, coords.lng);
        }
    }, [coords]);

    const mapOpacity = !latitude || !longitude ? 0.1 : 1;

    return (
        <div className="maps-container" data-testid="map-container">
            {!latitude || !longitude ? (
                <div className="map-overlay">Location not Available</div>
            ) : null}
            <div
                ref={mapContainer}
                className="map-container"
                style={{ opacity: mapOpacity }}
            />
        </div>
    );
};
export { AddressMap };
