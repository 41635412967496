import * as React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Box,
    Checkbox,
    Pagination,
    PaginationItem,
    InputLabel,
    Select,
    MenuItem,
} from "../../sharedComponent";
import {
    statusConvert,
    statusCheck,
    accountStatusConvert,
    accountStatusCheck,
} from "../../../utils/statusConversion";
import { Iorder, Itab } from "../../../components/pages/type";
import useTrans from "../../../hooks/useTranslation";
import {
    EnhancedTableHead,
    EnhancedTableToolbar,
} from "./enhancedTableToolbar/EnhancedTableToolbar";
import "./index.scss";
import { dateFormat } from "../../../utils/global";

const DEFAULT_ORDER = "asc";
const DEFAULT_ORDER_BY = "calories";

const TableData = ({
    tabName,
    data = [],
    icon,
    buttonLabel,
    columnData,
    count,
    pageAction,
    handlePageChange = () => {
        return;
    },
    isAccount = false,
    handleButtonClick = () => {
        return;
    },
    getSelectedRows,
    handleRowsPerPage = () => {
        return;
    },
    page,
    rowsPerPage,
    handleExport,
    setExportList,
}: Itab) => {
    const [order, setOrder] = React.useState(DEFAULT_ORDER);
    const [orderBy, setOrderBy] = React.useState(DEFAULT_ORDER_BY);
    const [selected, setSelected] = React.useState([]);
    const [paddingHeight, setPaddingHeight] = React.useState(0);
    const [message] = useTrans();

    React.useEffect(() => {
        if (getSelectedRows && data?.length > 0) {
            const selectedData = data?.filter((row) =>
                selected.includes(row.id)
            );
            getSelectedRows(selectedData);
        }
    }, [selected]);

    React.useEffect(() => {
        if (setExportList) setExportList(selected);
    }, [selected]);

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    const getValue = (rowData: any, columnData: any) => {
        switch (columnData.type) {
            case "text":
                return rowData[columnData.id] ? (
                    <>
                        {columnData.icon} {` ${rowData[columnData.id]}`}
                    </>
                ) : (
                    "-"
                );
            case "date":
                return dateFormat(rowData[columnData.id]);
            case "status":
                return isAccount
                    ? accountStatusCheck(
                          message(accountStatusConvert(rowData[columnData.id])),
                          message
                      )
                    : statusCheck(
                          message(statusConvert(rowData[columnData.id])),
                          message
                      );
            case "custom":
                return columnData.render && columnData.render(rowData, message);
            default:
                return rowData[columnData.id];
        }
    };

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.checked) {
            const newSelected = data.map((n: any) => n.id);
            setSelected(newSelected);
        } else {
            setSelected([]);
        }
    };

    const handleClick = (
        event: React.MouseEvent<HTMLTableCellElement>,
        id: string
    ) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const showRowData = (rowList: any[], columnInfo: any) => {
        const rows = rowList.map((val, index) => {
            const isItemSelected = isSelected(val.id);
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
                <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={val.id || index}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                    data-testid="table-row"
                >
                    <>
                        {columnInfo.checkBox && (
                            <TableCell
                                padding="checkbox"
                                onClick={(
                                    event: React.MouseEvent<HTMLTableCellElement>
                                ) => handleClick(event, val?.id)}
                            >
                                <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{
                                        "aria-labelledby": labelId,
                                    }}
                                />
                            </TableCell>
                        )}
                        {columnInfo?.column
                            ? columnInfo?.column.map(
                                  (value: {
                                      type: any;
                                      id: string | number;
                                  }) => {
                                      // eslint-disable-next-line react/jsx-key
                                      return (
                                          <TableCell
                                              component="th"
                                              id={labelId}
                                              scope="row"
                                              align="left"
                                              key={value.id}
                                          >
                                              {getValue(val, value)}
                                          </TableCell>
                                      );
                                  }
                              )
                            : []}
                        <TableCell align="left">
                            {pageAction && pageAction(val, index, tabName)}
                        </TableCell>
                    </>
                </TableRow>
            );
        });
        return rows;
    };

    return (
        <>
            <EnhancedTableToolbar
                numSelected={selected.length}
                count={data?.length}
                name={tabName}
                icon={icon}
                buttonLabel={buttonLabel}
                showExport={true}
                handleExport={handleExport}
                handleButtonClick={handleButtonClick}
            />
            <TableContainer sx={{ width: "98%" }}>
                <Table
                    sx={{
                        borderCollapse: "separate",
                        borderSpacing: "1px 8px",
                    }}
                    aria-labelledby="tableTitle"
                    size={"medium"}
                >
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order as any}
                        columnData={columnData}
                        orderBy={orderBy}
                        pageAction={pageAction}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={() => {
                            return null;
                        }}
                        rowCount={data && data?.length | 0}
                    />
                    <TableBody>
                        {data ? showRowData(data, columnData) : null}
                        {paddingHeight > 0 && (
                            <TableRow
                                className=""
                                style={{
                                    height: paddingHeight,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box
                component="div"
                className="table-controll"
                sx={{ display: "flex", alignItem: "baseline" }}
            >
                <Box component="span" className="page-display-count">{`${
                    page * rowsPerPage - rowsPerPage + 1
                }-${
                    data.length < rowsPerPage
                        ? page * rowsPerPage - rowsPerPage + data.length
                        : page * rowsPerPage
                } of ${count}`}</Box>
                <InputLabel id="simple-select-label">
                    {message("table.button.rowsPerPage")}
                </InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={rowsPerPage}
                    label={message("table.button.rowsPerPage")}
                    onChange={handleRowsPerPage}
                >
                    <MenuItem value={10}>{10}</MenuItem>
                    <MenuItem value={15}>{15}</MenuItem>
                    <MenuItem value={25}>{25}</MenuItem>
                </Select>
                <Pagination
                    count={Math.ceil(count / rowsPerPage)}
                    variant="outlined"
                    shape="rounded"
                    size="large"
                    onChange={handlePageChange}
                    page={page}
                    renderItem={(item) => (
                        <PaginationItem
                            components={{
                                next: () => (
                                    <span>{`${message(
                                        "table.button.next"
                                    )} >`}</span>
                                ),
                                previous: () => (
                                    <span>{`< ${message(
                                        "table.button.previous"
                                    )}`}</span>
                                ),
                            }}
                            {...item}
                        />
                    )}
                />
            </Box>
        </>
    );
};
export default TableData;
