import React, { useEffect } from "react";
import BasicModal from "../../../sharedComponent/modal";
import AddActivity from "../addActivity";
import ActivityList from "../activity/activityList";
import ButtonComponent from "../../../sharedComponent/button/Button";
import { Divider, Card, Typography } from "../../../sharedComponent";
import {
    BoxDivider,
    ButtonStyle,
    HeadingActivityStyle,
    BoxActivityStyle,
    NoDataWrapper,
} from "../segment/activityStyle";
import { ReactComponent as FormatListBulletedIcon } from "../../../../assets/FormatListBulletedIcon.svg";
import AddNotes from "../addNotes";
import useTrans from "../../../../hooks/useTranslation";
import useCall from "../../../../hooks/useCall";
import {
    getActivityList,
    setActivityList,
} from "../../../../services/LeadsInfoApi";
import { useParams } from "react-router-dom";
import { useWaitCall } from "../../../../hooks/useCall";
import { AlertContext } from "../../../../context/alterContext";
import { useCommonContext } from "@betagro/ui-common";
import SpinnerWrapper from "../../../sharedComponent/spinWrapper";
import { setActivityListNote } from "../../../../services/LeadsInfoApi";

const Activity = ({ isEditPermission }: any) => {
    const params = useParams();
    const leadId = params?.id as any;
    const [open, setOpen] = React.useState(false);
    const [noteId, setNoteId] = React.useState(0);
    const [openNotes, setNotes] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [message] = useTrans();
    const commonContext = useCommonContext();
    const alert = React.useContext(AlertContext);

    const getActivityDetails = useCall(
        () => getActivityList(leadId),
        [refresh]
    );

    const handleModelClose = () => {
        setOpen(false);
        setNotes(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleNote = (e, id) => {
        setNotes(true);
        setNoteId(id);
    };

    const submitData = (value) => {
        activityData.callback(value);
        handleModelClose();
    };

    const activityData = useWaitCall(
        (data) => setActivityList(leadId, data),
        [],
        (result) => {
            getActivityDetails?.data?.data?.push(result?.data?.data);
            alert.setSuccess("Save Successfully");
            commonContext.refreshCount();
            setRefresh(!refresh);
        },
        () => {
            alert.setError("something went wrong");
        }
    );

    const activityNoteData = useWaitCall(
        (data) => setActivityListNote(leadId, data),
        [],
        () => {
            alert.setSuccess("Save Successfully");
            // setEdit(false);
            commonContext.refreshCount();
            setRefresh(!refresh);
            handleModelClose();
        },
        () => {
            alert.setError("something went wrong");
        }
    );

    const submitNotesData = (value) => {
        const apidata = {
            leadId: Number(leadId),
            activityId: noteId,
            remark: value?.notes,
            attachment: value?.attachment,
        };
        activityNoteData.callback(apidata);
    };

    return (
        <SpinnerWrapper isLoading={getActivityDetails?.isLoading}>
            <BoxActivityStyle component={Card}>
                <BoxDivider>
                    <HeadingActivityStyle>
                        <Typography variant="poster2">
                            {message("pages.leadDetails.leadInfo.activities")}
                        </Typography>
                    </HeadingActivityStyle>
                    <Divider />
                    {getActivityDetails?.data?.data?.length > 0 ? (
                        getActivityDetails?.data?.data.map((list, index) => (
                            <ActivityList
                                isEditPermission={isEditPermission}
                                key={list.id}
                                list={list}
                                handleOpen={handleOpen}
                                handleNote={handleNote}
                            />
                        ))
                    ) : (
                        <NoDataWrapper>
                            <FormatListBulletedIcon height={50} wigth={50} />
                            {message(
                                "pages.leadDetails.leadInfo.noDataAvailable"
                            )}
                        </NoDataWrapper>
                    )}
                    {isEditPermission && (
                        <ButtonStyle>
                            <ButtonComponent
                                name={message(
                                    "pages.leadDetails.leadInfo.plusaddActivity"
                                )}
                                variant="contained"
                                color="success"
                                onClick={handleOpen}
                                sx={{
                                    borderRadius: "16px",
                                    backgroundColor: "#84BD00",
                                    textTransform: "none",
                                }}
                            />
                        </ButtonStyle>
                    )}
                </BoxDivider>
            </BoxActivityStyle>
            <BasicModal open={open} handleClose={handleModelClose}>
                {!openNotes ? (
                    <AddActivity
                        handleClose={handleModelClose}
                        submitData={submitData}
                    />
                ) : (
                    <AddNotes
                        handleClose={handleModelClose}
                        submitData={submitNotesData}
                    />
                )}
            </BasicModal>
        </SpinnerWrapper>
    );
};

export default Activity;
