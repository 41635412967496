import React from "react";
import { ReactComponent as CircleInfo } from "../assets/circle-info.svg";
import { ReactComponent as TagIcon } from "../assets/tag.svg";

import { ReactComponent as Repeat } from "../assets/repeat.svg";
import { ReactComponent as FileCheck } from "../assets/file-check.svg";
import { ReactComponent as CircleClose } from "../assets/circle-x.svg";
import { ReactComponent as CircleCheck } from "../assets/circle-check.svg";
import { ReactComponent as ShareIcon } from "../assets/share-icon.svg";
import { ReactComponent as FileIcon } from "../assets/file-filled.svg";
import { ReactComponent as InboxOutIcon } from "../assets/inbox-out.svg";
import { ReactComponent as ClockRotateIcon } from "../assets/clock-rotate-left.svg";

import { ACCOUNT_STATUS } from "../constants/account.status";

export const statusConvert = (val: number) => {
    switch (val) {
        case 1:
            return "leads.sharedComponent.table.status.new";
        case 2:
            return "leads.sharedComponent.table.status.progress";
        case 3:
            return "leads.sharedComponent.table.status.pending";
        case 4:
            return "leads.sharedComponent.table.status.successful";
        case 5:
            return "leads.sharedComponent.table.status.failed";
        case 6:
            return "leads.sharedComponent.table.status.assigned";
        case 7:
            return "leads.sharedComponent.table.status.ready";
        case 8:
            return "leads.sharedComponent.table.status.archived";
        case 9:
            return "leads.sharedComponent.table.status.fromInProgress";
        case 10:
            return "leads.sharedComponent.table.status.reviewed";
        case 11:
            return "leads.sharedComponent.table.status.completed";
        case 12:
            return "leads.sharedComponent.table.status.rejected";
    }
};
export const accountStatusConvert = (val: number) => {
    return ACCOUNT_STATUS[val];
};

export const statusCheck = (val: any, message: any) => {
    const styleData: React.CSSProperties = {
        backgroundColor: "#FF9F1C33",
        // width: "70%",
        alignItems: "center",
        borderRadius: "26px",
        fontWeight: 600,
        color: "#FF9F1C",
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        padding: "6px 12px",
        fontSize: "14px",
        gap: "4px",
        whiteSpace: "nowrap",
        height: "1rem",
    };

    switch (val) {
        case message("leads.sharedComponent.table.status.new"):
            return (
                <div style={{ ...styleData, height: "10%" }}>
                    <TagIcon /> {val}
                </div>
            );
        case message("leads.sharedComponent.table.status.progress"):
            return (
                <div
                    style={{
                        ...styleData,
                        color: "#226CE0",
                        backgroundColor: "#DEE9FA",
                        height: "10%",
                    }}
                >
                    <Repeat width={12} height={12} fill="#226CE0" /> {val}
                </div>
            );
        case message("leads.sharedComponent.table.status.pending"):
            return (
                <div
                    style={{
                        ...styleData,
                        color: "#89813D",
                        backgroundColor: "#89813D26",
                    }}
                >
                    <CircleInfo width={12} height={12} fill="#89813D" /> {val}
                </div>
            );
        // case message("leads.sharedComponent.table.status.pending"):
        //   return (
        //     <div
        //       style={{
        //         ...styleData,
        //         color: "#226CE0",
        //         backgroundColor: "#DEE9FA",
        //         height: "10%"
        //       }}
        //     >
        //       <Repeat width={12} height={12} fill="#226CE0" />{val}
        //     </div>
        //   );
        case message("leads.sharedComponent.table.status.successful"):
            return (
                <div
                    style={{
                        ...styleData,
                        color: "#84BD00",
                        backgroundColor: "rgba(132, 189, 0, 0.15)",
                        height: "10%",
                    }}
                >
                    <CircleCheck
                        width={12}
                        height={12}
                        style={{ fill: "#84bd00" }}
                    />
                    {val}
                </div>
            );
        case message("leads.sharedComponent.table.status.failed"):
            return (
                <div
                    style={{
                        ...styleData,
                        color: "#76777B",
                        backgroundColor: "rgba(118, 119, 123, 0.15)",
                        height: "10%",
                    }}
                >
                    <CircleClose width={12} height={12} fill="#76777B" />
                    {val}
                </div>
            );
        case message("leads.sharedComponent.table.status.assigned"):
            return val;
        case message("leads.sharedComponent.table.status.ready"):
            return (
                <div
                    style={{
                        ...styleData,
                        color: "#EA7AF4",
                        backgroundColor: "rgba(234, 122, 244, 0.15)",
                        height: "10%",
                    }}
                >
                    <FileCheck width={12} height={12} />
                    {val}
                </div>
            );
        case message("leads.sharedComponent.table.status.archived"):
            return val;
        case message("leads.sharedComponent.table.status.fromInProgress"):
            return val;
        case message("leads.sharedComponent.table.status.reviewed"):
            return val;
        case message("leads.sharedComponent.table.status.complete"):
            return val;
        case message("leads.sharedComponent.table.status.rejected"):
            return val;
    }
};

export const accountStatusCheck = (val: any, message: any) => {
    const styleData: React.CSSProperties = {
        backgroundColor: "#FF9F1C33",
        // width: "70%",
        alignItems: "center",
        borderRadius: "26px",
        fontWeight: 600,
        color: "#FF9F1C",
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        padding: "6px 12px",
        fontSize: "14px",
        gap: "4px",
        whiteSpace: "nowrap",
        height: "1rem",
    };

    switch (val) {
        case message("createAccount.table.column.status.new"):
        case message("leads.sharedComponent.table.status.new"):
            return (
                <div style={{ ...styleData, height: "10%" }}>
                    <TagIcon /> {val}
                </div>
            );
        case message("createAccount.table.column.status.pending"):
            return (
                <div
                    style={{
                        ...styleData,
                        color: "#2708A0",
                        backgroundColor: "rgba(39, 8, 160, 0.15)",
                    }}
                >
                    <ClockRotateIcon width={12} height={12} fill="#2708A0" />{" "}
                    {val}
                </div>
            );
        case message("createAccount.table.column.status.linkSent"):
        case message("createAccount.table.column.status.sendToLead"):
            return (
                <div
                    style={{
                        ...styleData,
                        color: "#89813D",
                        backgroundColor: "rgba(137, 129, 61, 0.15)",
                        height: "10%",
                    }}
                >
                    <ShareIcon width={12} height={12} fill="#89813D" /> {val}
                </div>
            );
        case message("createAccount.table.column.status.progress"):
            return (
                <div
                    style={{
                        ...styleData,
                        color: "#226CE0",
                        backgroundColor: "#DEE9FA",
                        height: "10%",
                    }}
                >
                    <Repeat width={12} height={12} fill="#226CE0" /> {val}
                </div>
            );
        case message("createAccount.table.column.status.submitted"):
            return (
                <div
                    style={{
                        ...styleData,
                        color: "#EA7AF4",
                        backgroundColor: "rgba(234, 122, 244, 0.15)",
                        height: "10%",
                    }}
                >
                    <FileIcon width={12} height={12} fill="#EA7AF4" />
                    {val}
                </div>
            );
        case message("createAccount.table.column.status.approved"):
        case message("createAccount.table.column.status.completed"):
            return (
                <div
                    style={{
                        ...styleData,
                        color: "#84BD00",
                        backgroundColor: "rgba(132, 189, 0, 0.15)",
                        height: "10%",
                    }}
                >
                    <CircleCheck
                        width={12}
                        height={12}
                        style={{ fill: "#84bd00" }}
                    />
                    {val}
                </div>
            );
        case message("createAccount.table.column.status.sentToBCOS"):
            return (
                <div
                    style={{
                        ...styleData,
                        color: "#8E3B46",
                        backgroundColor: "rgba(142, 59, 70, 0.15)",
                        height: "10%",
                    }}
                >
                    <InboxOutIcon
                        width={12}
                        height={12}
                        style={{ fill: "#8E3B46" }}
                    />
                    {val}
                </div>
            );
        case message("createAccount.table.column.status.rejected"):
            return (
                <div
                    style={{
                        ...styleData,
                        color: "#F4442E",
                        backgroundColor: "rgba(244, 68, 46, 0.10)",
                        height: "10%",
                    }}
                >
                    <CircleClose width={12} height={12} fill="#F4442E" />
                    {val}
                </div>
            );
    }
};
