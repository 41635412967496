import { Box, styled, Grid, Chip } from "../../../sharedComponent";

export const BoxStyle = styled(Box)(() => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    maxWidth: "80%",
    maxHeight: "70%",
}));

interface DivProps {
    type?: boolean;
}

export const ChipWrapperStyle = styled("div")<DivProps>(({ theme, type }) => ({
    display: "flex",
    alignItems: "center",
    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), ${
        type ? "#6C6D71" : "#84BD00"
    } `,
    borderRadius: "26px",
    border: !type ? "" : "2px solid #84BD00",
    padding: type ? "4px 16px" : "4px 16px 4px 12px",
    gap: type ? "4px" : "0px",
}));

export const TypeWrapperStyle = styled("div")(() => ({
    display: "flex",
    gap: ".5rem",
}));

export const HeadingStyle = styled("div")(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}));

export const IconWrapper = styled("div")(() => ({
    backgroundColor: "rgba(132, 189, 0, 0.1)",
    display: "flex",
    justifyContent: "center",
    borderRadius: "100px",
    height: "7vh",
    alignItems: "center",
    width: "-webkit-fill-available",
}));

export const HeadingWrapper = styled("div")(() => ({
    display: "flex",
    gap: ".3rem",
    color: "#919295",
}));

export const SubHeadingWrapper = styled("div")(() => ({
    display: "flex",
    color: "#919295",
    alignItems: "center",
    gap: ".6rem",
    marginTop: "1rem",
    width: "131%",
}));

export const BoxDivider = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
}));

export const ButtonStyle = styled("div")(() => ({
    display: "flex",
    justifyContent: "end",
    padding: "1rem",
}));

export const HeadingActivityStyle = styled("div")(() => ({
    padding: "0rem 1rem",
}));

export const BoxActivityStyle = styled(Box)(() => ({
    flex: 2,
    borderRadius: "16px",
}));

export const DividerStyle = styled("div")(() => ({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    background: "rgba(234, 235, 235, 1)",
}));

export const GridWrapperStyle = styled(Grid)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px",
}));

export const GridContainerWrapper = styled(Grid)(() => ({
    alignItems: "flex-start",
    justifyContent: "space-between",
}));

export const GridButtonWrapper = styled(Grid)(() => ({
    display: "flex",
    justifyContent: "end",
}));

export const NoDataWrapper = styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    height: "62vh",
}));

export const BoxHeadingStyle = styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    gap: "0.6rem",
}));

export const ChipWrapperMargin = styled(Chip)(() => ({
    margin: "0px 9px 8px 0px",
}));

export const BoxChipWrapper = styled(Box)(() => ({
    background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #6C6D71",
    borderRadius: "8px",
    padding: "12px",
}));
