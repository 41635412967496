import React, { useState, createContext } from "react";

interface LoginProviderProps {
    children: React.ReactNode;
}

interface Ilogin {
    isLogout: boolean;
}

interface LoginContextType {
    loginInfo: Ilogin;
    setloginContext?: (userContext: Ilogin) => void;
}
/* eslint-disable @typescript-eslint/no-unused-vars */
const contextDefaultValues: LoginContextType = {
    loginInfo: { isLogout: false },
    setloginContext: (loginInfo: Ilogin) => {
        return;
    },
};

export const TodosContext =
    createContext<LoginContextType>(contextDefaultValues);

export const LoginContext = createContext<LoginContextType | null>({
    loginInfo: { isLogout: false },
});

export const LoginContextProvider = ({ children }: LoginProviderProps) => {
    const [loginInfo, setloginContext] = useState<Ilogin>({ isLogout: false });

    return (
        <LoginContext.Provider value={{ loginInfo, setloginContext }}>
            {children}
        </LoginContext.Provider>
    );
};
