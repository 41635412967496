import React from "react";
import BasicMenu from "../menu/Menu";

const ActionComponent = ({ row, actions, tabName, children, styled }: any) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div style={styled ? styled : { position: "relative" }}>
            <div
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                data-testid="basic-menu"
            >
                {children}
            </div>
            <BasicMenu
                options={actions as any}
                anchorEl={anchorEl}
                handleClose={handleClose}
                open={open}
                data={row}
                id={row?.id}
                tabName={tabName}
                inputProps={{"data-testid":"basic-menu-popup"}}
            />
        </div>
    );
};

export default ActionComponent;
