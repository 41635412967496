export const ACCOUNT_STATUS = {
    1: "createAccount.table.column.status.new",
    2: "createAccount.table.column.status.progress",
    3: "createAccount.table.column.status.pending",
    4: "createAccount.table.column.status.approved",
    5: "createAccount.table.column.status.sendToLead",
    6: "createAccount.table.column.status.linkSent",
    7: "createAccount.table.column.status.submitted",
    8: "createAccount.table.column.status.completed",
    9: "createAccount.table.column.status.sentToBCOS",
    15: "createAccount.table.column.status.rejected",
};

export const ACCOUNT_STATUS_MAPPING = {
    NEW: 1,
    IN_PROGRESS: 2,
    APPROVED: 4,
    SEND_TO_LEAD: 5,
    PENDING_FOR_APPROVAL: 3,
    CUSTOMER_LINK_SENT: 6,
    CUSTOMER_FORM_SUBMITTED: 7,
    COMPLETED: 8,
    REJECTED: 15,
    SEND_TO_BCOS: 9,
};
