import * as React from "react";
import Button from "@mui/material/Button";

interface IProps {
    variant?: any;
    name?: any;
    color?: any;
    sx?: any;
    icon?: any;
    onClick?: any;
    width?: any;
    children?: React.Component | string | null;
    type?: any;
    disabled?: boolean;
}

const ButtonComponent = ({
    variant,
    name,
    color = "#84BD00",
    type = "button",
    disabled = false,
    sx = {
        borderRadius: "16px",
        backgroundColor: "#84BD00",
        textTransform: "none",
        color: "white",
        width: "100%",
        whiteSpace: "nowrap !important",
    },
    icon,
    onClick,
    children,
}: IProps) => {
    return (
        <Button
            disabled={disabled}
            variant={variant}
            color={color}
            sx={sx}
            onClick={onClick}
            type={type}
        >
            {icon} {name || children}
        </Button>
    );
};
export default ButtonComponent;
