import React from "react";
import { Paper, Typography, Grid, Button, Box } from "../../sharedComponent";
import { ReactComponent as CloseIcon } from "../../../assets/xmark-solid.svg";
import SecurePayment from "../../../utils/assets/secure-payment.png";
import { styled } from "@mui/material/styles";
import useTrans from "../../../hooks/useTranslation";
import { IApprove } from "../../../interfaces/accountForm";

export const BoxStyle = styled(Box)(() => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    maxWidth: "80%",
    maxHeight: "70%",
    textAlign: "center",
}));
export const Icon = styled("span")(() => ({
    float: "right",
}));
export const Ptext = styled("p")(() => ({
    margin: "auto",
}));

const Approve = ({ handleClose, handleApprove, name = "" }: IApprove) => {
    const [message] = useTrans();

    return (
        <BoxStyle component={Paper} p={9}>
            <div>
                <Icon>
                    <span data-testid="closeApproveModal" onClick={handleClose}>
                        <CloseIcon
                            height={25}
                            width={25}
                            fill={"#47474A"}
                            cursor={"pointer"}
                        />
                    </span>
                </Icon>
                <Grid item lg={12}>
                    <img
                        src={SecurePayment}
                        width="80px"
                        height="80px"
                        alt="secure-payment"
                    />
                    <Typography variant="h5">
                        {message(
                            "accountForm.modal.approve.confirmAgreement.head"
                        )}
                    </Typography>
                    <Ptext>
                        {message(
                            "accountForm.modal.approve.confirmAgreement.text"
                        )}
                    </Ptext>
                    <Ptext>{`${name} ?`}</Ptext>
                </Grid>

                <Button
                    name={message("accountForm.modal.approve.cancel")}
                    variant="outlined"
                    color="success"
                    onClick={handleClose}
                    sx={{
                        color: "#84BD00",
                        "&:hover": {
                            color: "white",
                        },
                        backgroundColor: "white",
                        textTransform: "none",
                        margin: "2rem 0.5rem 0rem",
                        width: "43%",
                    }}
                />

                <Button
                    name={message("accountForm.modal.approve.confirm")}
                    variant="contained"
                    color="success"
                    onClick={handleApprove}
                    sx={{
                        textTransform: "none",
                        margin: "2rem 0.5rem 0rem",
                        width: "43%",
                    }}
                />
            </div>
        </BoxStyle>
    );
};
export default Approve;
