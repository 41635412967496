import React, { useMemo, useState, useEffect } from "react";
import { Card } from "../../../sharedComponent";
import useTrans from "../../../../hooks/useTranslation";
import { ReactComponent as DashBoardIcon } from "../../../../assets/dashboard-icon.svg";
import {
    CardActionsWrapper,
    CardContainerWrapper,
    CardHeaderWrapper,
    CardListWrapper,
    CardVAlueWrapper,
    CardWrapper,
    MainContainer,
    MainWrapper,
    TableWrapper,
    ValueWrapper,
} from "./segmentStyle";
import LeadSegement from "./leadSegment";
import LeadPocket from "./leadPocket";
import { normalizedPocketSize } from "./pocketSize";
import { useParams } from "react-router-dom";
import useCall from "../../../../hooks/useCall";
import {
    getChannel,
    getPocketSize,
    pocketRangeConfig,
} from "../../../../services/LeadsInfoApi";
import SpinnerWrapper from "../../../sharedComponent/spinWrapper";

interface IField {
    ProductID?: number;
    ProductName_en?: string;
    volume?: number;
    value?: number;
    price?: number;
}

const LeadSegmentComponent = ({ leadInfo, refresh, isEditPermission }: any) => {
    const params = useParams();
    const leadId = params?.id as any;
    const [message] = useTrans();
    const [fields, setFields] = useState<IField>({});
    const [refreshPocket, setRefreshPocket] = useState<boolean>(false);
    const leadPocketApi = useCall(
        () => getPocketSize(leadId),
        [refreshPocket],
        []
    );
    const pocketRangeConfigApi = useCall(() => pocketRangeConfig({}), []);
    const leadSegementApi = useCall(() => getChannel(), [], []);

    useEffect(() => {
        if (leadPocketApi?.data?.data?.data) {
            setFields(normalizedPocketSize(leadPocketApi?.data.data?.data));
        }
    }, [leadPocketApi.isLoading]);

    const handleCancelRequest = () => {
        //refresh();
        if (leadPocketApi?.data?.data?.data) {
            setFields(normalizedPocketSize(leadPocketApi?.data?.data?.data));
        }
    };
    const handleVolumeChange = (
        ProductID: string,
        price: string,
        productName: string,
        unit: string
    ) => {
        return (e) => {
            // eslint-disable-next-line no-debugger
            debugger;
            const targetVolumn = e.target.value.split(".");
            let curentVolumn = targetVolumn[0].slice(0, 10);

            if (unit !== "EGG" && targetVolumn.length > 1) {
                curentVolumn =
                    curentVolumn + "." + targetVolumn[1].slice(0, 2) || "";
            }

            fields[ProductID] = {
                ...fields[ProductID],
                volume: curentVolumn,
                productName: productName,
                productPrice: price,
                productId: ProductID,
                value: (parseFloat(curentVolumn) * parseFloat(price)).toFixed(
                    2
                ),
                leadId,
            };

            setFields({ ...fields });
        };
    };
    const totalVolume = () => {
        let total = 0;
        for (const key in fields) {
            total =
                total +
                parseFloat(fields[key].volume || "0") *
                    parseFloat(fields[key].productPrice);
        }
        return total.toFixed(2);
    };

    const rangeSize = useMemo(
        () =>
            pocketRangeConfigApi?.data?.data?.data?.find(
                (item) =>
                    parseInt(item.maxValue) >= parseInt(totalVolume()) &&
                    parseInt(item.minValue) <= parseInt(totalVolume())
            )?.pocketSize,
        [totalVolume(), pocketRangeConfigApi.isLoading]
    );

    return (
        <MainContainer>
            <SpinnerWrapper
                isLoading={
                    leadSegementApi.isLoading || leadSegementApi.isLoading
                }
            >
                <CardListWrapper>
                    <Card>
                        <CardHeaderWrapper
                            avatar={<DashBoardIcon />}
                            title={
                                <b>{message("leadInfo.segment.pocketValue")}</b>
                            }
                        ></CardHeaderWrapper>
                        <CardContainerWrapper>
                            <CardVAlueWrapper>
                                ฿ {totalVolume().toLocaleString()}
                            </CardVAlueWrapper>
                        </CardContainerWrapper>
                    </Card>
                    <Card>
                        <CardHeaderWrapper
                            avatar={<DashBoardIcon />}
                            title={
                                <b>{message("leadInfo.segment.pocketSize")}</b>
                            }
                        ></CardHeaderWrapper>
                        <CardContainerWrapper>
                            <CardVAlueWrapper>{rangeSize}</CardVAlueWrapper>
                        </CardContainerWrapper>
                    </Card>
                </CardListWrapper>
                <LeadSegement
                    refresh={refresh}
                    leadInfo={leadInfo}
                    channels={leadSegementApi.data?.data?.data}
                    isEditPermission={isEditPermission}
                />
                <LeadPocket
                    pocketFields={fields}
                    isEditPermission={isEditPermission}
                    handleVolumeChange={handleVolumeChange}
                    handleCancelRequest={handleCancelRequest}
                    refresh={() => {
                        setRefreshPocket(!refresh);
                    }}
                />
            </SpinnerWrapper>
        </MainContainer>
    );
};

export default LeadSegmentComponent;
