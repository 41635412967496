import React from "react";
import LeadInfo from "../leadsDetails/leadInfo/leadInfo";
import { Box } from "../../sharedComponent";

const BranchLeads = () => {
    return (
        <>
            <Box py={4}>
                {/* <BasicBreadcrumbs/> */}
                <LeadInfo />
            </Box>
        </>
    );
};

export default BranchLeads;
