import React, { useEffect, useContext } from "react";
import { LoginContext } from "../../../context/loginContext";
import { styled } from "@mui/material/styles";
import { Typography, Grid, Button, UploadInput } from "../../sharedComponent";
import useTrans from "../../../hooks/useTranslation";
import useForm from "../../../hooks/useForm";
import {
    setUserRole,
    setUser,
    setPriceData,
    setPriceRangeData,
} from "../../../services/masterUpload";
import UsersTemp from "../../../assets/UsersTemp.xlsx";
import RolesTemp from "../../../assets/RolesTemp.xlsx";
import ProductPriceTemp from "../../../assets/ProductPriceTemp.xlsx";
import ProductPriceRangeTemp from "../../../assets/ProductPriceRangeTemp.xlsx";
import { useCommonContext } from "@betagro/ui-common";
import { createAccouuntButtons } from "../../../utils/constants";
import { useWaitCall } from "../../../hooks/useCall";
import { AlertContext } from "../../../context/alterContext";
import { ROLES } from "../../../constants/role.status";
const Main = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0rem",
    gap: "1.688rem",
    width: "55.5rem",
    left: "27rem",
    top: "8rem",
}));

const FormContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0rem 0rem 2rem",
    gap: "1.5rem",
    width: "55.5rem",
    marginBottom: "3rem",
    background: "#FFFFFF",
    boxShadow: "0rem 0.25rem 5rem rgba(0, 0, 0, 0.04)",
    borderRadius: "1rem",
}));

const FormHead = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: "5.125rem",
    borderBottom: "0.063rem solid #EAEBEB",
    borderRadius: "1rem 16rem 0rem 0rem",
}));

const FormFrame = styled("form")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "4rem",
    padding: "0rem",
    width: "49.5rem",
}));

const NavButton = styled(Button)(() => ({
    borderRadius: "2rem",
    backgroundColor: "#84BD00",
    width: "8.25rem",
    textTransform: "none",
    marginTop: "1rem",
    marginLeft: "85%",
}));

const UploadFile = () => {
    const [message] = useTrans();
    const { register } = useForm({});
    const [emp, setEmp] = React.useState([]);
    const [empRole, setEmpRole] = React.useState([]);
    const [productPrice, setProductPrice] = React.useState([]);
    const [productPriceRange, setProductPriceRange] = React.useState([]);
    const { userContextInfo } = useCommonContext();
    const { loginInfo, setloginContext } = useContext(LoginContext);

    useEffect(() => {
        setloginContext({ isLogout: true });
    }, [loginInfo.isLogout]);
    const alert = React.useContext(AlertContext);
    const updateUserRole = useWaitCall(
        (data) => setUserRole(data),
        [],
        () => {
            alert.setSuccess("Save Successfully");
            setEmpRole([]);
        },
        () => {
            alert.setError("something went wrong");
        }
    );
    const updateUser = useWaitCall(
        (data) => setUser(data),
        [],
        () => {
            alert.setSuccess("Save Successfully");
            setEmp([]);
        },
        () => {
            alert.setError("something went wrong");
        }
    );

    const handleEmpData = (val) => {
        if (userContextInfo.role !== ROLES.ADMIN) {
            return;
        }
        if (val.length) {
            setEmp(val);
        } else {
            setEmp([]);
        }
    };
    const handleEmployee = () => {
        const data = new FormData() as any;
        data.append("File", emp[0] as any);
        updateUser.callback(data);
    };

    const handleEmpRoleData = (val) => {
        if (userContextInfo.role !== ROLES.ADMIN) {
            return;
        }
        if (val.length) {
            setEmpRole(val);
        } else {
            setEmpRole([]);
        }
    };
    const handleEmployeeRole = () => {
        const data = new FormData() as any;
        data.append("File", empRole[0] as any);
        updateUserRole.callback(data);
    };
    const handleEmpDataClose = () => {
        setEmp([]);
    };
    const handleEmpRoleDataClose = () => {
        setEmpRole([]);
    };

    const handleProductPriceRangeData = (val) => {
        if (val.length) {
            setProductPriceRange(val);
        } else {
            setProductPriceRange([]);
        }
    };
    const handleProductPriceData = (val) => {
        if (val.length) {
            setProductPrice(val);
        } else {
            setProductPrice([]);
        }
    };

    const handleProductPriceRangeDataClose = () => {
        setProductPriceRange([]);
    };
    const handleProductPriceDataClose = () => {
        setProductPrice([]);
    };

    const handleProductPriceRange = () => {
        const data = new FormData() as any;
        data.append("File", productPriceRange[0] as any);
        updateProductPriceRange.callback(data);
    };
    const updateProductPriceRange = useWaitCall(
        (data) => setPriceRangeData(data),
        [],
        () => {
            alert.setSuccess("Save Successfully");
            setProductPriceRange([]);
        },
        (error) => {
            alert.setError(error.message);
        }
    );
    const handleProductPrice = () => {
        const data = new FormData() as any;
        data.append("File", productPrice[0] as any);
        updateProductPrice.callback(data);
    };
    const updateProductPrice = useWaitCall(
        (data) => setPriceData(data),
        [],
        () => {
            alert.setSuccess("Save Successfully");
            setProductPrice([]);
        },
        (error) => {
            alert.setError(error.message);
        }
    );
    return (
        <Main>
            <FormContainer>
                <FormHead>
                    <Typography sx={{ mx: 5 }} variant="poster2">
                        Employee creation
                    </Typography>
                </FormHead>
                <FormFrame>
                    <Grid container spacing={1}>
                        <UploadInput
                            xs={12}
                            md={12}
                            label="Upload  Roles"
                            {...register("role", null)}
                            tempName="RolesTemp"
                            tempFile={RolesTemp}
                            id="idCard"
                            name="idCard"
                            multiple={false}
                            files={empRole}
                            handleChange={handleEmpRoleData}
                            handleClose={handleEmpRoleDataClose}
                        />

                        <NavButton
                            name={message(createAccouuntButtons.save)}
                            variant="contained"
                            color="success"
                            onClick={handleEmployeeRole}
                            disabled={userContextInfo.role !== ROLES.ADMIN}
                        />

                        <UploadInput
                            xs={12}
                            md={12}
                            label="Upload Employees"
                            {...register("empExcel", null)}
                            tempName="UsersTemp"
                            tempFile={UsersTemp}
                            id="idCard"
                            name="idCard"
                            multiple={false}
                            files={emp}
                            handleChange={handleEmpData}
                            handleClose={handleEmpDataClose}
                        />
                        <NavButton
                            name={message(createAccouuntButtons.save)}
                            variant="contained"
                            color="success"
                            onClick={handleEmployee}
                            disabled={userContextInfo.role !== ROLES.ADMIN}
                        />
                    </Grid>
                </FormFrame>
            </FormContainer>
            <FormContainer>
                <FormHead>
                    <Typography sx={{ mx: 5 }} variant="poster2">
                        Pocket Price Upload
                    </Typography>
                </FormHead>
                <FormFrame>
                    <Grid container spacing={1}>
                        <UploadInput
                            xs={12}
                            md={12}
                            label="Pocket Price"
                            {...register("productPrice", null)}
                            tempName="ProductPriceTemp"
                            tempFile={ProductPriceTemp}
                            id="productPrice"
                            name="productPrice"
                            multiple={false}
                            files={productPrice}
                            handleChange={handleProductPriceData}
                            handleClose={handleProductPriceDataClose}
                        />

                        <NavButton
                            name={message(createAccouuntButtons.save)}
                            variant="contained"
                            color="success"
                            onClick={handleProductPrice}
                            disabled={userContextInfo.role !== ROLES.ADMIN}
                        />

                        <UploadInput
                            xs={12}
                            md={12}
                            label="Pocket Price range"
                            {...register("productPriceRange", null)}
                            tempName="ProductPriceRangeTemp"
                            tempFile={ProductPriceRangeTemp}
                            id="productPriceRange"
                            name="productPriceRange"
                            multiple={false}
                            files={productPriceRange}
                            handleChange={handleProductPriceRangeData}
                            handleClose={handleProductPriceRangeDataClose}
                        />
                        <NavButton
                            name={message(createAccouuntButtons.save)}
                            variant="contained"
                            color="success"
                            onClick={handleProductPriceRange}
                            disabled={userContextInfo.role !== ROLES.ADMIN}
                        />
                    </Grid>
                </FormFrame>
            </FormContainer>
        </Main>
    );
};

export default UploadFile;
