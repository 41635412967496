import { v4 as uuidv4 } from "uuid";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Wave from "../assets/watermark.png";

export const formatedPhoneNumber = (number) => {
    if (number) {
        return (
            number.slice(0, 3) +
            "-" +
            number.slice(3, 6) +
            "-" +
            number.slice(6)
        );
    }
};

export const formatIncomeTaxId = (number) => {
    if (number) {
        return (
            number.slice(0, 1) +
            "-" +
            number.slice(1, 5) +
            "-" +
            number.slice(5, 10) +
            "-" +
            number.slice(10, 12) +
            "-" +
            number.slice(13)
        );
    }
};

export async function createFile(
    elementId: string,
    formNumber: number,
    leadid: number
) {
    const pdf = new jsPDF("p", "mm", "letter");
    pdf.setFontSize(10);
    const element: HTMLElement = document.querySelector(`#${elementId}`);
    const imgData = await html2canvas(element, { scale: 2 });
    //pdf = addWaterMark(pdf);

    pdf.addImage(
        imgData,
        "PNG",
        5,
        5,
        pdf.internal.pageSize.getWidth() - 20,
        0,
        "",
        "FAST"
    );

    const outputBase64 = pdf.output("blob");
    const file = new File([outputBase64], `FORM_${formNumber}_${leadid}.pdf`, {
        type: "application/pdf",
    });

    return file;
}

export async function createFileforUpload(uploadfile: File, index: number) {
    const pdf = new jsPDF("p", "pt", "a4");
    pdf.setFontSize(10);
    pdf.addImage(
        URL.createObjectURL(uploadfile),
        "PNG",
        10,
        10,
        pdf.internal.pageSize.getWidth() - 20,
        0,
        "",
        "FAST"
    );

    pdf.addImage(
        Wave,
        "PNG",
        10,
        10,
        pdf.internal.pageSize.getWidth() - 20,
        0,
        "",
        "FAST"
    );

    const outputBase64 = pdf.output("blob");
    const file = new File(
        [outputBase64],
        `ID-CARD-${index + 1}.pdf`,
        {
            type: "application/pdf",
        }
    );

    return file;
}

export enum UserType {
    Sales = "sales",
    Manager = "manager",
    Customer = "customer",
    HManager = "hmanager",
}

export enum AccountStatus {
    NEW = 1,
    IN_PROGRESS = 2,
    APPROVED = 4,
    SEND_TO_LEAD = 5,
    PENDING_FOR_APPROVAL = 3,
    CUSTOMER_LINK_SENT = 6,
    CUSTOMER_FORM_SUBMITTED = 7,
    COMPLETED = 8,
    REJECTED = 15,
    SEND_TO_BCOS = 9,
}

export function getAttachmentInfo(
    attachmentCollection: string[],
    leadId: number,
    accountId: number,
    attachmentType: number[]
) {
    return attachmentCollection.map((attachment, index) => {
        return {
            leadId,
            accountId,
            attachment,
            attachmentId: uuidv4(),
            attachmentType: attachmentType[index],
        };
    });
}

export function getUserType(role: number, type: string) {
    if (type === "3") return UserType.Customer;

    switch (role) {
        case 1:
            return UserType.Sales;
        case 2:
            return UserType.Manager;
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
            return UserType.HManager;
        default:
            return UserType.Customer;
    }
}
