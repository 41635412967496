import React from "react";
import { Grid, FormControl } from "../../index";
import { styled } from "@mui/material/styles";
import { ITextInput } from "../../../../interface/InputField";
import useTrans from "../../../../hooks/useTranslation";

const errorStyle = {
    border: "2px solid red",
};

const FormLabel = styled("label")(() => ({
    fontSize: "1.25rem",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    color: "#464B55",
}));
const InputError = styled("span")(() => ({
    color: "red",
}));

const Input = styled("input")(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "1.538rem",
    padding: "0.75rem 1.5rem",
    background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #6C6D71",
    borderRadius: "0.5rem",
    borderWidth: "0px",
    borderStyle: "solid",
}));

const FormControlWrapper = styled(FormControl)(() => ({
    "&:focus": {
        backgroundColor: "green",
    },
}));

const TextInput = ({
    xs,
    md,
    value,
    label = "",
    name,
    hidden = false,
    disabled = false,
    error,
    placeholder,
    onChange,
    labelType = "column",
    subText,
    isView,
    displayValueOnly,
    labelSize = 3,
    inputBoxStyle = {},
    inputStyle = {},
}: ITextInput) => {
    const labelStyle = {
        color: "#919295",
        fontSize: inputStyle.fontSize ? inputStyle.fontSize : "1.25rem",
        fontFamily: "DB Helvethaica X 65 Regular",
        height: "1.5rem",
    };
    const gridLabelStyle = {
        display: "flex",
        alignItems: "center",
        // justifyContent: "end",
    };
    const textStyle = {
        color: "#000000",
    };
    const DisplayVal = styled("span")(() => ({
        fontSize: inputStyle.fontSize ? inputStyle.fontSize : "1.25rem",
        fontWeight: "400",
        display: "flex",
        alignItems: "center",
        color: "#464B55",
        margin: "0.5rem",
        wordBreak: "break-all",
    }));

    const [message] = useTrans();

    return displayValueOnly ? (
        <span>{value} </span>
    ) : (
        <>
            {label && labelType == "row" && (
                <Grid item xs={labelSize} md={labelSize} sx={gridLabelStyle}>
                    <FormLabel
                        style={
                            isView != undefined
                                ? { ...labelStyle, ...textStyle }
                                : labelStyle
                        }
                    >
                        {message(label)}
                    </FormLabel>
                </Grid>
            )}
            {isView && !(xs || md) && (
                <span>
                    <b>
                        {"\u00A0"}
                        {value}
                    </b>
                </span>
            )}

            {isView && (xs || md) && (
                <Grid item xs={xs} md={md}>
                    <DisplayVal style={isView != undefined ? textStyle : {}}>
                        {value}
                    </DisplayVal>
                </Grid>
            )}

            {!isView && (
                <Grid item xs={xs} md={md}>
                    <FormControlWrapper fullWidth>
                        {label && labelType != "row" && (
                            <FormLabel
                                style={isView != undefined ? textStyle : {}}
                            >
                                {message(label)}
                            </FormLabel>
                        )}
                        <Input
                            type="text"
                            placeholder={placeholder}
                            id="input-with-icon-adornment"
                            value={value}
                            name={name}
                            data-testid={name}
                            hidden={hidden}
                            // multiline={multiline}
                            disabled={disabled}
                            onChange={onChange}
                            style={
                                error
                                    ? errorStyle
                                    : {
                                          ...inputBoxStyle,
                                          margin: "6px 0px 6px 0px",
                                      }
                            }
                        />
                        {subText && (
                            <span style={{ marginLeft: "-20px" }}>
                                Kg/volumne
                            </span>
                        )}
                        {error && <InputError>{error}</InputError>}
                    </FormControlWrapper>
                </Grid>
            )}
        </>
    );
};

export default TextInput;
