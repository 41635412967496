enum PocketSize {
    SS = "SS",
    S = "S",
    M = "M",
    L = "L",
    XL = "XL",
    XXL = "XXL",
}

export const normalizedPocketSize = (fields: any) => {
    const result = {};
    fields?.forEach((field) => {
        result[field.productId] = {
            ...field,
            volume: parseFloat(field.volume).toFixed(2),
            value: (
                parseFloat(field.volume) * parseFloat(field.productPrice)
            ).toFixed(2),
        };
    });
    return result;
};

export const convertObjectToArray = (fields: any) => {
    const result = [];
    for (const fieldKey in fields) {
        result.push({ ...fields[fieldKey], value: 0 });
    }
    return result;
};

export const prepareDropdownData = (
    channels: any,
    channelId: any,
    labelName?: string
) => {
    const result = [];
    for (const item of channels) {
        result.push({
            value: item[channelId],
            label: labelName ? item[labelName] : item.name,
        });
    }

    const processedList = result.filter(
        (value, index, array) =>
            array.findIndex((val) => val.value === value.value) === index &&
            value.value &&
            value.label
    );

    return processedList;
};
