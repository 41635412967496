import React, { useMemo } from "react";
import dayjs from "dayjs";
import { styled } from "../../../sharedComponent/index";
import ListItemComp from "../../../sharedComponent/listItems/ListItems";
import { IconButton } from "../../../sharedComponent";
import { AddressMap } from "../../../AddressMap";
import useTrans from "../../../../hooks/useTranslation";
import BasicModal from "../../../sharedComponent/modal";
import { ReactComponent as SearchIcon } from "../../../../assets/magnifying-glass-solid.svg";
import ModelList from "../../../sharedComponent/modalList";
import { checkboxData } from "../../../../utils/apiData";
import { LeadSourceWrapper } from "./LeadStyle";
import SpinnerWrapper from "../../../sharedComponent/spinWrapper";
import { dateFormat } from "../../../../utils/global";
const LeadSourceUrl = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "1.538rem",
    color: "#76A900",
    margin: "6px 0px 6px 0px",
}));

interface ILeadInfoData {
    data: any;
    isLoading?: boolean;
    duplicateData: any;
    updateCreatePlaceId?: () => void;
}

const LeadInfoListData = (props: ILeadInfoData) => {
    const { data, duplicateData, updateCreatePlaceId, isLoading } = props;
    const [open, setOpen] = React.useState(false);
    const [message] = useTrans();
    const {
        shopNumber,
        address1,
        address2,
        pincode,
        city,
        district,
        province,
        blockNumber,
        latitude,
        longitude,
    } = data?.locations || {};

    const valueCheck = (val) => {
        if (val != null && val != undefined) {
            return val;
        } else {
            return "-";
        }
    };

    const timing = useMemo(() => {
        const timingData = data?.timing?.split("||") || [];

        if (
            timingData.length > 0 &&
            dayjs(timingData[0]).isValid() &&
            dayjs(timingData[1]).isValid()
        ) {
            return `${dayjs(timingData[0]).format("HH:mm")} - ${dayjs(
                timingData[1]
            ).format("HH:mm")}`;
        }

        return "-";
    }, [data]);

    const openDays = useMemo(() => {
        let daysData = data?.openday?.split(",") || [];
        let daysToShow = "";
        daysData = daysData.map((val, index) =>
            val ? checkboxData[index] : ""
        );
        for (let index = 0; index < daysData.length; index++) {
            if (daysData[index]) {
                if (!daysToShow) {
                    daysToShow = daysToShow + (checkboxData[index]?.label || 0);
                } else if (daysData[index - 1] && !daysData[index + 1]) {
                    daysToShow =
                        daysToShow + ` - ${checkboxData[index]?.label}`;
                } else if (!daysData[index - 1]) {
                    daysToShow = daysToShow + `, ${checkboxData[index]?.label}`;
                }
            }
        }
        return daysToShow;
    }, [data]);

    const openModal = () => {
        setOpen(true);
    };

    const handleModalClose = () => {
        setOpen(false);
    };

    const AddressData = () => {
        return `${valueCheck(shopNumber)} ${valueCheck(
            blockNumber
        )} ${valueCheck(address1)}  ${valueCheck(address2)}

${valueCheck(city)} ${valueCheck(district)} ${valueCheck(
            province
        )} ${valueCheck(pincode)} `;
    };

    return (
        <>
            <ListItemComp
                name={
                    message("sharedComponent.listDivider.leadInfo.shopName") +
                    `*`
                }
                value={valueCheck(data?.shopName)}
            />
            <ListItemComp
                name={message(
                    "sharedComponent.listDivider.leadInfo.importDate"
                )}
                value={
                    valueCheck(data?.importDate)
                        ? dateFormat(data?.importDate)
                        : "-"
                }
            />
            <ListItemComp
                name={message("sharedComponent.listDivider.leadInfo.placeId")}
                value={valueCheck(data?.placeId)}
                icon={
                    data?.placeId ? (
                        ""
                    ) : (
                        <IconButton onClick={openModal}>
                            <SearchIcon
                                height={15}
                                width={15}
                                fill={"#47474A"}
                            />
                        </IconButton>
                    )
                }
            />
            <ListItemComp
                name={
                    message(
                        "sharedComponent.listDivider.leadInfo.contactPerson"
                    ) + `*`
                }
                value={valueCheck(data?.contactPerson)}
            />
            <ListItemComp
                name={
                    message(
                        "sharedComponent.listDivider.leadInfo.contactNumber"
                    ) + `*`
                }
                value={
                    <LeadSourceUrl style={{ color: "#76A900" }}>{`${valueCheck(
                        data?.contactNumber?.join(" / ")
                    )}`}</LeadSourceUrl>
                }
            />
            <ListItemComp
                name={message("sharedComponent.listDivider.leadInfo.address")}
                value={AddressData()}
            />
            <ListItemComp
                name={
                    message("sharedComponent.listDivider.leadInfo.geoData") +
                    `*`
                }
                value={`${valueCheck(latitude)} , ${valueCheck(longitude)}`}
            />
            <ListItemComp
                name={message(
                    "sharedComponent.listDivider.leadInfo.priceLevel"
                )}
                value={
                    `${valueCheck(data?.priceLevelMin)} - ${valueCheck(
                        data?.priceLevelMax
                    )}` || "-"
                }
            />
            <ListItemComp
                name={message(
                    "sharedComponent.listDivider.leadInfo.openingHours"
                )}
                value={`${openDays}   ${timing}` || "-"}
            />
            <ListItemComp
                name={message(
                    "sharedComponent.listDivider.leadInfo.ratingReview"
                )}
                value={`${valueCheck(data?.rating)} rating ( ${valueCheck(
                    data?.reviews
                )} reviews )`}
            />
            <ListItemComp
                name={message("sharedComponent.listDivider.leadInfo.noOfSeats")}
                value={valueCheck(data?.noOfSeats)}
            />

            <ListItemComp
                name={message(
                    "sharedComponent.listDivider.leadInfo.leadSource"
                )}
                value={
                    data?.leadSourceUrl ? (
                        <LeadSourceWrapper>
                            <span>
                                {message(
                                    "sharedComponent.listDivider.leadInfo.facebook"
                                )}
                            </span>
                            {">"}
                            <a href={data?.leadSourceUrl}>
                                {message(
                                    "sharedComponent.listDivider.leadInfo.post"
                                )}
                            </a>
                        </LeadSourceWrapper>
                    ) : (
                        message("sharedComponent.listDivider.leadInfo.manual")
                    )
                }
            />

            <ListItemComp
                name={message("sharedComponent.listDivider.leadInfo.notes")}
                value={valueCheck(data?.note)}
            />
            <BasicModal open={open} handleClose={handleModalClose}>
                <SpinnerWrapper isLoading={duplicateData.isLoading}>
                    <ModelList
                        list={duplicateData?.data?.data?.data || []}
                        isLoading={isLoading}
                        handleModalClose={handleModalClose}
                        updateCreatePlaceId={updateCreatePlaceId}
                    />
                </SpinnerWrapper>
            </BasicModal>
            <AddressMap
                longitude={Number(data?.locations?.longitude)}
                latitude={Number(data?.locations?.latitude)}
            />
        </>
    );
};

export default LeadInfoListData;
