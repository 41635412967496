import { styled } from "@mui/material/styles";

export const LeadSourceWrapper = styled("div")(() => ({
    "& > span": {
        marginRight: "5px",
    },
    "& > a": {
        marginLeft: "5px",
        color: "#84BD00",
        textDecoration: "auto",
    },
    "& > a:hover": {
        textDecoration: "revert",
    },
}));
