import React from "react";
import dayjs from "dayjs";
import {
    Typography,
    Divider,
    Box,
    Paper,
    Grid,
    Checkbox,
    FormControlLabel,
    Stack,
    Button,
    DateInput,
    TextInput,
    AttestInput,
} from "../../sharedComponent/index";
import { styled } from "@mui/system";
import { ReactComponent as HomeIcon } from "../../../assets/house-solid.svg";
import useTrans from "../../../hooks/useTranslation";
import { aggrement } from "../../../utils/constants";
import { ICreateAccountForm } from "../type";
import { ReactComponent as RemoveIcon } from "../../../assets/trash-can.svg";
import CreateFullAddress from "./address/createFullAddress";
import { useCommonContext } from "@betagro/ui-common";

const StackStyledWrapper = styled(Stack)({
    flexDirection: "row",
    alignItems: "center",
    gap: ".5rem",
    justifyContent: "flex-end",
});

const LabelWrapper = styled("span")({
    padding: "4px",
    fontWeight: "600",
});

const NavButton = styled(Button)(() => ({
    borderRadius: "2rem",
    backgroundColor: "#84BD00",
    width: "8.25rem",
    textTransform: "none",
}));

const SignatureStyledWrapper = styled("div")(() => ({
    display: "flex",
    gap: ".5rem",
}));
const styleIcon = {
    color: "#919295",
    marginLeft: "auto",
};

const MemorandumSummary = ({
    register,
    handleNext,
    handleBack,
    isView,
    setValues,
    id,
}: ICreateAccountForm) => {
    const [message] = useTrans();
    const [address, setAddress] = React.useState([]);
    const [check, setCheck] = React.useState(false);
    const { selectedLanguage } = useCommonContext();
    const handleCheckboxChange = () => {
        if (isView) {
            return;
        }

        setCheck(!check);
    };

    const removeAddress = (index) => {
        index = index - 2;
        setAddress((data) => {
            const addressList = data.filter((val, ind) => ind !== index);

            return addressList;
        });
    };

    const AddressWraper = ({ index, removeAddress }) => {
        return (
            <>
                <Divider />
                <StackStyledWrapper>
                    <HomeIcon fill="#84BD00" height={15} width={15} />
                    <Typography variant="poster2">{`${message(
                        aggrement.address
                    )} ${index}`}</Typography>
                    <Typography
                        variant="poster2"
                        onClick={() => removeAddress(index)}
                        style={styleIcon}
                    >
                        <span>
                            <RemoveIcon fill="currentColor" /> Remove
                        </span>
                    </Typography>
                </StackStyledWrapper>

                <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label={message(
                        "account.form.six.aggrement.text.defaultDelivertAddress"
                    )}
                />
            </>
        );
    };

    // const handleAddAddress = () => {
    //     // eslint-disable-next-line react/jsx-key
    //     const addressList = address.map((val, index) => (
    //         <AddressWraper
    //             key={index}
    //             isRemove={false}
    //             index={index + 2}
    //             removeAddress={removeAddress}
    //         />
    //     ))
    //     addressList.push(
    //         <AddressWraper
    //             isRemove={true}
    //             index={address.length + 2}
    //             removeAddress={removeAddress}
    //         />
    //     )
    //     setAddress([...addressList])
    // }
    const addresses = register("memorandumAddress").value;

    return (
        <>
            <Box id={id} component={Paper} sx={{ borderRadius: "1rem" }}>
                <Typography variant="poster2" sx={{ padding: "1rem 2rem" }}>
                    {message(aggrement.title)}
                </Typography>
                <Divider />
                <Box
                    sx={{
                        padding: "1rem 2rem",
                        fontFamily: "DB Helvethaica X 55 Regular",
                        fontWeight: 400,
                        fontSize: "1.1428571428571428rem",
                    }}
                >
                    {register("date").value ? (
                        <StackStyledWrapper>
                            {" "}
                            <Typography>
                                {
                                    <span>
                                        <b>
                                            {"\u00A0"}
                                            {dayjs(register("date").value)
                                                .locale(selectedLanguage)
                                                .format("DD MMMM YYYY")}
                                        </b>
                                    </span>
                                }
                            </Typography>
                        </StackStyledWrapper>
                    ) : (
                        ""
                    )}

                    <div
                        style={{
                            padding: "10px 0px",
                            color: "#000000",
                            fontFamily: "Arial",
                            fontSize: "0.975rem",
                            fontStyle: "normal",
                        }}
                    >
                        <span>{"   "}</span>
                        {message(aggrement.firstAggrement)}
                        <LabelWrapper>
                            {register("location").value}
                        </LabelWrapper>
                        {message(aggrement.branch)}
                        {message(aggrement.secondAggrement)}
                        <LabelWrapper>{register("item").value}</LabelWrapper>
                        {message(aggrement.thirdAggrement)}
                        {message(aggrement.with)}
                        <LabelWrapper>{`${register("firstName").value} ${
                            register("lastName").value
                        }`}</LabelWrapper>
                        {message(aggrement.fourthAggrement)}
                        {register("adjustedCreditLimit").value > 20000 ? (
                            <LabelWrapper>
                                {register("date2").value &&
                                    dayjs(register("date2").value)
                                        .locale(selectedLanguage)
                                        .format("DD MMMM YYYY")}
                            </LabelWrapper>
                        ) : (
                            <LabelWrapper>{"-"}</LabelWrapper>
                        )}
                        {message(aggrement.fifthAggrement)}
                    </div>
                    <div
                        style={{
                            padding: "10px 0px",
                            color: "#000000",
                            fontFamily: "Arial",
                            fontSize: "0.975rem",
                            fontStyle: "normal",
                        }}
                    >
                        <Stack flexDirection={"row"} mt={1} ml={2}>
                            <Box my={{ marginRight: "4px" }}></Box>
                            {message(aggrement.SixthAggrement)}
                        </Stack>
                    </div>
                    {addresses &&
                        addresses?.map((item, index) => {
                            return (
                                <Stack key={index} flexDirection={"row"} ml={4}>
                                    <Stack flexDirection={"row"} ml={2}>
                                        <Box my={{ margin: "4px 4px 0 0 " }}>
                                            {index + 1}.
                                        </Box>
                                        <LabelWrapper>
                                            <CreateFullAddress
                                                houseNo={item.houseNo}
                                                soi={item.soi}
                                                building={item.building}
                                                address={item.address}
                                                province={item.province}
                                                district={item.district}
                                                subdistrict={item.subdistrict}
                                                postalCode={item.postalCode}
                                            ></CreateFullAddress>
                                        </LabelWrapper>
                                    </Stack>
                                </Stack>
                            );
                        })}

                    <div
                        style={{
                            padding: "10px 0px",
                            color: "#000000",
                            fontFamily: "Arial",
                            fontSize: "0.975rem",
                            fontStyle: "normal",
                        }}
                    >
                        {message(aggrement.seventhAggrement)}
                        <span style={{ padding: "0px 5px" }}>
                            {register("description").value}
                        </span>
                        {message(aggrement.eigthAggrement)}
                    </div>

                    <Stack flexDirection={"row"} mt={1} ml={2}>
                        <Box my={{ marginRight: "4px" }}></Box>
                        <Typography
                            sx={{
                                color: "#000000",
                                fontFamily: "Arial",
                                fontSize: "0.975rem",
                                fontStyle: "normal",
                            }}
                        >
                            {message(aggrement.nineAggrement)}
                        </Typography>
                    </Stack>
                    <Typography
                        sx={{
                            color: "#000000",
                            fontFamily: "Arial",
                            fontSize: "0.975rem",
                            fontStyle: "normal",
                        }}
                        my={4}
                    >
                        {message(aggrement.tenthAggrement)}
                    </Typography>
                </Box>
                <Grid item container spacing={2} p={5}>
                    <Grid item md={6}>
                        <Typography
                            sx={{
                                color: "#000000",
                                fontFamily: "Arial",
                                fontSize: "0.975rem",
                                fontStyle: "normal",
                            }}
                        >
                            {message(aggrement.buyer)}
                        </Typography>
                        <AttestInput
                            isView={isView}
                            width={400}
                            height={200}
                            label=""
                            register={register}
                            disabled={true}
                            name="buyerSign"
                            id="buyerSign"
                            dateFieldName="buyerSignDate"
                            handleClick={() => {
                                setValues({ buyerSignDate: dayjs() });
                            }}
                        />
                        <SignatureStyledWrapper>
                            <TextInput
                                xs={6}
                                md={6}
                                value={`${register("firstName").value} ${
                                    register("lastName").value
                                }`}
                                disabled={true}
                                id="buyerName"
                                isView={isView}
                                placeholder={message(aggrement.buyerSign)}
                            />
                            <DateInput
                                xs={3}
                                md={3}
                                label=""
                                name={"buyerSignDate"}
                                disabled={true}
                                register={register}
                                id="buyerSignDate"
                                isView={isView}
                            />
                        </SignatureStyledWrapper>
                    </Grid>

                    <Grid item md={6}>
                        <Typography>{message(aggrement.seller)}</Typography>
                        <AttestInput
                            isView={isView}
                            width={400}
                            height={200}
                            label=""
                            disabled={true}
                            register={register}
                            name="sellerSign"
                            id="sellerSign"
                            dateFieldName="salesSignDate"
                            handleClick={() => {
                                setValues({ salesSignDate: dayjs() });
                            }}
                        />
                        <SignatureStyledWrapper>
                            <TextInput
                                xs={6}
                                md={6}
                                {...register("sellerSignName")}
                                id="sellerSignName"
                                disabled={true}
                                isView={isView}
                                placeholder={message(aggrement.sellerSign)}
                            />
                            <DateInput
                                xs={3}
                                md={3}
                                label=""
                                name="salesSignDate"
                                register={register}
                                id="salesSignDate"
                                isView={isView}
                                disabled={true}
                            />
                        </SignatureStyledWrapper>
                    </Grid>
                </Grid>
                <Box sx={{ padding: "1rem 2rem", color: "#000000" }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={true}
                                name={"checkbox"}
                                color={isView ? "default" : "primary"}
                                onChange={handleCheckboxChange}
                            />
                        }
                        label={
                            <span style={{ fontSize: "1.45rem" }}>
                                {message("account.form.agree")}
                            </span>
                        }
                        style={{
                            opacity: isView ? 0.4 : 1,
                        }}
                    />
                </Box>

                {!isView && (
                    <Grid item xs={12} md={12} p={5} mb={4}>
                        <NavButton
                            name={message("account.form.button.previous")}
                            variant="contained"
                            color="success"
                            onClick={handleBack}
                            sx={{
                                float: "left",
                            }}
                        />
                        <NavButton
                            name={message("account.form.button.next")}
                            variant="contained"
                            color="success"
                            onClick={handleNext}
                            disabled={!check}
                            sx={{
                                float: "right",
                            }}
                        />
                    </Grid>
                )}
            </Box>
        </>
    );
};

export default MemorandumSummary;
