import * as React from "react";
import TypographyWrapper from "../typographyWrapper";
import { TabsData } from "../../../utils/apiData";
import { Tabs, Tab, Box, Chip, Typography } from "../../sharedComponent";
import PropTypes from "prop-types";
import "./index.scss";
import useTrans from "../../../hooks/useTranslation";
import { useCommonContext } from "@betagro/ui-common";
import { ROLES } from "../../../constants/role.status";

interface ITableProps {
    children: any;
    value: number;
    index: number;
    panelSx?: any;
}

function TabPanel(props: ITableProps) {
    const { children, panelSx, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={panelSx}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index: Number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
        "data-testid": `simple-tabpanel-${index}`,
    };
}
interface TabProps {
    labels: Array<string>;
    tabsData: Array<{}>;
    icons?: Function;
    PanelComp: any;
    search: any;
    data?: any;
    panelSx?: any;
    tabsSx?: any;
    refresh?: any;
}
const TabsComponent = (props: TabProps) => {
    const { userContextInfo } = useCommonContext();
    const [value, setValue] = React.useState(0);

    const [message] = useTrans();

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        newValue: number
    ) => {
        setValue(newValue);
    };
    const { PanelComp, data, panelSx, tabsSx } = props;
    return (
        <>
            <Box sx={tabsSx} data-testid="tabs">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="lead-tabs"
                    className="lead-tabs"
                    TabIndicatorProps={{
                        style: { background: "#84BD00", color: "#84BD00" },
                    }}
                >
                    {props?.labels.map((label, index) => (
                        <Tab
                            key={`${label}${index}`}
                            label={
                                <Typography variant="subtitle1">
                                    {message(label)}
                                </Typography>
                            }
                            {...a11yProps(index)}
                            icon={
                                props.icons &&
                                (userContextInfo.role === ROLES.SALES ||
                                    userContextInfo.role === ROLES.MANAGER)
                                    ? props.icons(label)
                                    : undefined
                            }
                            iconPosition="end"
                            data-testid="tab-select"
                        />
                    ))}
                </Tabs>
            </Box>
            {props.tabsData?.map((tab: any, idx) => {
                return (
                    <TabPanel
                        value={value}
                        key={idx}
                        index={tab?.value}
                        panelSx={panelSx}
                    >
                        <PanelComp
                            tabName={tab?.name}
                            data={data}
                            refresh={props.refresh}
                            value={value}
                        />
                    </TabPanel>
                );
            })}
        </>
    );
};
export default TabsComponent;
