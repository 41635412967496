import React, { useContext } from "react";
import ProtectedRoutes from "./router/protectedRoutes";
import PublicRoutes from "./router/publicRoutes";
import { styled } from "@mui/material/styles";
import RequestInterceptor from "./context/axiosHandler";
import { AlertContextProvider } from "./context/alterContext";
import { ThemeProvider } from "@mui/material/styles";
import { I18nextProvider } from "react-i18next";
import { LoginContextProvider } from "./context/loginContext";
import i18n from "./i18n";
import theme from "./theme";

const Main = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: "20px 20px",
}));

const App = () => {
    return (
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <LoginContextProvider>
                    <I18nextProvider i18n={i18n}>
                        <AlertContextProvider>
                            <RequestInterceptor />
                            <Main>
                                <ProtectedRoutes />
                                <PublicRoutes />
                            </Main>
                        </AlertContextProvider>
                    </I18nextProvider>
                </LoginContextProvider>
            </ThemeProvider>
        </React.StrictMode>
    );
};

export default App;
