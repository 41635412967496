import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Reject from "./reject";
import Approve from "./approve";
import SendForApproval from "./sendForApproval";
import dayjs from "dayjs";
import useTrans from "../../hooks/useTranslation";
import { useCommonContext } from "@betagro/ui-common";
import { Typography, Grid, Button, Alert } from "../sharedComponent";
import useForm from "../../hooks/useForm";
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router";
import { getRole, getStepper } from "../pages/createAccountSteps/stepperConfig";
import useCall, { useWaitCall } from "../../hooks/useCall";
import { AlertContext } from "../../context/alterContext";
import {
    getAccountForm,
    putAccountForm,
    setAttachments,
    submitAccountForm,
    submitCustomerForm,
    rejectAccountForm,
    verifySignOTP,
    createOnlineForm,
} from "../../services/AccountApi";
import SpinnerWrapper from "../sharedComponent/spinWrapper";
import BasicModal from "../sharedComponent/modal";
import {
    AccountStatus,
    UserType,
    createFile,
    getAttachmentInfo,
    getUserType,
} from "../../utils/commonFunc";
import { getLeadsInfo, uploadFiles } from "../../services/LeadsInfoApi";
import { getFieldCollectionData, getFormError } from "./validationConfig";
import { OtpComponent } from "../pages/otpLogoin";
import { useTranslation } from "react-i18next";
import axios from "axios";

const TitleMain = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px",
    width: "55.5rem",
    // height: "3.125rem",
    marginBottom: "2rem",
}));

const NavButton = styled(Button)(() => ({
    borderRadius: "2rem",
    backgroundColor: "#84BD00",
    textTransform: "none",
    padding: "5px 30px",
}));

const ErrorContainer = styled(`div`)(() => ({
    padding: "10px",
}));

const AlertContainer = styled(Alert)(() => ({
    borderLeft: "5px #FF0000 solid",
}));

const StepLabelWrapper = styled(StepLabel)((props) => ({
    "& .MuiStepIcon-root.Mui-active": {
        color: props?.error ? "red" : "#84BD00",
        cursor: "pointer",
    },
    "& .MuiSvgIcon-root.Mui-completed": {
        color: props?.error ? "red" : "#84BD00",
        cursor: "pointer",
    },
    "& .MuiStepLabel-label": { color: "#919295", cursor: "pointer" },
}));

export default function FormPageStepper() {
    const location = useLocation();
    const [message] = useTrans();
    const [activeStep, setActiveStep] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [isApprove, setIsApprove] = React.useState(false);
    const [isApproval, setIsApproval] = React.useState(false);
    const [isPrint, setIsprint] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [showOtpModel, setShowOtpModel] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [branchName, setBranchName] = React.useState<{
        th: string;
        en: string;
    }>({
        th: "",
        en: "",
    });

    const { userContextInfo } = useCommonContext();
    const { selectedLanguage } = useCommonContext();

    const {
        register,
        handleSubmit,
        setValues,
        fieldValue,
        getValue,
        validate,
        isValid,
        setFieldValidation,
        getErrorCollection,
    } = useForm({
        defaultValue: {},
    });
    const commonContext = useCommonContext();
    const [searchParams] = useSearchParams();
    const leadId = location?.state?.leadId || searchParams.get("leadId");
    const type = searchParams.get("type");
    const isValidApprover =
        commonContext?.userContextInfo?.role ==
        getRole(getValue("adjustedCreditLimit", "0"));

    const handleModelClose = () => {
        setOpen(false);
        setIsApprove(false);
        setIsApproval(false);
    };

    const handleApprove = () => {
        if (isSummary) {
            handleProceedAction();
        }
    };

    const openApprove = () => {
        setOpen(true);
        setIsApprove(true);
    };

    const openForApproval = () => {
        setOpen(true);
        setIsApproval(true);
    };

    const openReject = () => {
        setOpen(true);
        setIsApprove(false);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        getFormError(steps, message, getErrorCollection());
        handleSubmit(handleSave)();
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        getFormError(steps, message, getErrorCollection());
    };

    const handleReset = () => {
        setActiveStep(0);
        getFormError(steps, message, getErrorCollection());
    };

    const currentUserType = getUserType(
        commonContext?.userContextInfo?.role,
        type
    );

    const steps = getStepper(
        getValue("adjustedCreditLimit", "0"),
        currentUserType === UserType.Customer,
        currentUserType === UserType.Manager,
        isValidApprover
    ) as Array<any>;

    const manangerStepper = getStepper(
        getValue("adjustedCreditLimit", "0"),
        false,
        true,
        false
    ) as Array<any>;

    const leadApi = useCall(() => getAccountForm(leadId), [refresh], []);
    const accountInfo = leadApi?.data?.data?.data;
    // const accountApprover = accountInfo.accountApprovalHistory.length > 0 ? accountInfo.accountApprovalHistory[0].approverEmpId === commonContext?.userContextInfo?.employeeId : false
    const isSummary = activeStep === steps.length - 1;
    const isEdit = (status) => {
        if (currentUserType === UserType.Sales) {
            if (
                [AccountStatus.NEW].includes(status) ||
                [AccountStatus.IN_PROGRESS].includes(status) ||
                [AccountStatus.REJECTED].includes(status)
            ) {
                return true;
            } else {
                return false;
            }
        } else if (currentUserType === UserType.Manager) {
            if ([AccountStatus.PENDING_FOR_APPROVAL].includes(status))
                return true;
            else return false;
        } else if (currentUserType === UserType.Customer) {
            if ([AccountStatus.CUSTOMER_LINK_SENT].includes(status))
                return true;
            else return false;
        }
    };
    const isManager = currentUserType === UserType.Manager;
    const isCustomer = currentUserType === UserType.Customer;
    const isSales =
        currentUserType === UserType.Sales && isEdit(accountInfo?.status);

    React.useEffect(() => {
        setFieldValidation(getFieldCollectionData(steps));
    }, [accountInfo?.leadId, steps.length]);

    React.useEffect(() => {
        async function getLeadInfo() {
            if (!accountInfo) {
                return;
            }

            const { data } = await getLeadsInfo(accountInfo?.leadId);
            setBranchName({
                th: data.data.leadBranchMappings.branches.branchName,
                en: data.data.leadBranchMappings.branches.branchNameEN,
            });
        }

        getLeadInfo();
    }, [accountInfo]);

    React.useEffect(() => {
        if (!branchName) {
            return;
        }

        if (accountInfo?.leadId) {
            if (accountInfo?.formDatajson) {
                setValues({
                    ...JSON.parse(accountInfo?.formDatajson),
                    location:
                        commonContext?.selectedLanguage === "th"
                            ? branchName.th
                            : branchName.en,
                });
            } else {
                setValues({
                    companyName: accountInfo.shopName,
                    latitude: accountInfo?.locations?.latitude,
                    longitude: accountInfo?.locations?.longitude,
                    contactNumber: accountInfo?.backgroundInfos?.phoneNumber,
                    firstName: accountInfo.leads?.contactPerson,
                    lastName: "",
                    nationalId: accountInfo?.backgroundInfos?.nationalId,
                    salesRepresntative: `${commonContext?.userContextInfo?.first_name} ${commonContext?.userContextInfo?.last_name}`,
                    sellerSignName: `${commonContext?.userContextInfo?.first_name} ${commonContext?.userContextInfo?.last_name}`,
                    managerSignName:
                        commonContext?.userContextInfo?.managerName,
                    location:
                        commonContext?.selectedLanguage === "th"
                            ? branchName.th
                            : branchName.en,
                    businessName: message(
                        "account.form.three.aggrement.body.businessName"
                    ),
                    item: "-",
                    date: dayjs(),
                    date2: dayjs(),
                    productPurchaseDate: dayjs(),
                    betagrocompanyName: message(
                        "account.form.three.aggrement.body.betagroBusinessName"
                    ),
                });
            }
        }
    }, [
        leadApi?.data?.data?.data,
        commonContext?.selectedLanguage,
        branchName,
    ]);

    const alert = React.useContext(AlertContext);

    const verifySignOTPApi = useWaitCall(
        (otp) => verifySignOTP({ otp: otp, type: 3, leadId }),
        [],
        () => {
            //handleProceed();
            submitOnlineForm();
            setShowOtpModel(false);
        },
        () => {
            alert.setError("Wrong OTP");
            setShowOtpModel(false);
        }
    );

    const handleOtpSubmit = (otp) => {
        verifySignOTPApi.callback(otp);
    };

    const updateApiData = useWaitCall(
        (data) => putAccountForm(data),
        [],
        () => {
            alert.setSuccess("Save Successfully");
            commonContext.refreshCount();
        },
        () => {
            alert.setError("Update data something went wrong");
        }
    );

    const setAttachmentsApi = useWaitCall(
        (data) => setAttachments(data),
        [],
        () => {
            alert.setSuccess("Save Successfully");
            setRefresh(!refresh);
        },
        () => {
            alert.setError("Attachments something went wrong");
        }
    );

    const form = {
        leadId: 0,
        formDatajson: null,
        creditLimit: 0,
    };

    const handleSave = (data) => {
        form.leadId = leadId;
        form.formDatajson = JSON.stringify(data);
        form.creditLimit = Math.floor(getValue("adjustedCreditLimit", "0"));
        updateApiData.callback(form);
    };

    const handleSavePdf = async () => {
        const forms = manangerStepper
            .slice(0, manangerStepper.length - 2)
            .reverse();
        const calls = [];
        const collectType = [];
        try {
            setIsLoading(true);
            for (let index = 0; index < forms.length; index += 1) {
                const data = new FormData();
                const file = await createFile(
                    "step-two-form" + index,
                    index + 1,
                    leadId
                );
                data.append("File", file);
                collectType.push(forms[index].type.toString());
                calls.push(uploadFiles(data));
            }

            const uploadResult = await Promise.all(calls);
            const pdfID = uploadResult
                .map((response) => response.data.data)
                .flat(1);
            const idCard = register("idCard").value || [];

            idCard.forEach((element) => {
                pdfID.push(element);
                collectType.push("1");
            });
            const customerImages = register("customerImages").value || [];
            customerImages.forEach((element) => {
                pdfID.push(element);
                collectType.push("5");
            });

            setAttachmentsApi.callback(
                getAttachmentInfo(
                    pdfID,
                    accountInfo.leadId,
                    accountInfo.accountId,
                    collectType
                )
            );
            setIsprint(false);
            setIsLoading(false);

            return Promise.resolve("successfull");
        } catch (e) {
            console.error(e);
            alert.setError("PDF something went wrong");
            setIsprint(false);
            setIsLoading(false);
            return Promise.reject(e);
        }
    };

    const handleReject = (note) => {
        //keep Lead ID as is do not change json values.
        submitReject.callback({ accountId: leadId, note });
    };

    const handleProceedAction = () => {
        const data = {
            leadId: leadId,
            currentRole: commonContext?.userContextInfo?.role,
            requiredRole: getRole(getValue("adjustedCreditLimit", "0")),
            leadEmail: "TusharA_Ext@BETAGRO.com",
            currentUser: {
                name: `${commonContext?.userContextInfo?.first_name} ${commonContext?.userContextInfo?.last_name}`,
                email: commonContext?.userContextInfo?.email,
                empId: commonContext?.userContextInfo?.employeeId,
            },
            managerUser: {
                name: commonContext?.userContextInfo?.managerName,
                email: commonContext?.userContextInfo?.managerEmail,
                empId: commonContext?.userContextInfo?.managerId,
            },
        };

        setIsprint(true);
        setTimeout(() => {
            submitData.callback(data);
        }, 100);
    };

    const handleProceed = () => {
        if (isSummary) {
            if (accountInfo?.status == 2 || accountInfo?.status == 1) {
                openForApproval();
            } else if (currentUserType === UserType.Manager) {
                openApprove();
            } else {
                handleProceedAction();
            }
        }
    };

    const submitOnlineForm = async () => {
        setIsLoading(true);

        try {
            await createOnlineForm({
                leadId,
                language: selectedLanguage,
            });

            setRefresh(true);
            alert.setSuccess("Submitted Successfully");
        } catch (e) {
            if (axios.isAxiosError(e)) {
                alert.setError(e.response.data.message);
            } else {
                alert.setError(
                    "Unable to submit e-form, please try again later"
                );
            }
        } finally {
            setIsLoading(false);
        }
    };

    const submitData = useWaitCall(
        (data) => (type === "3" ? handleSavePdf() : submitAccountForm(data)),
        [],
        (result) => {
            if (
                [AccountStatus.CUSTOMER_LINK_SENT].includes(accountInfo?.status)
            ) {
                submitCustomerForm()
                    .then((res) => {
                        setOpen(false);
                        setIsApprove(false);
                        setIsApproval(false);
                    })
                    .catch((err) => {
                        console.error(err);
                        setIsprint(false);
                        alert.setError("Submit data something went wrong");
                    });
            } else {
                setOpen(false);
                setIsApprove(false);
                setIsApproval(false);
                alert.setSuccess("Submitted Successfully");
                setRefresh(!refresh);
            }
        },
        (err) => {
            setIsprint(false);
            // const errResponse = err?.data?.errors[0];
            // if (errResponse || err) {
            //   alert.setError(errResponse.message || err);
            // } else {
            //   alert.setError("something went wrong");
            // }

            alert.setError(
                err?.data?.errors
                    ? err?.data?.errors.map((item) => item.message).join(", ")
                    : "Something went wrong"
            );
        }
    );

    const submitReject = useWaitCall(
        (data) => rejectAccountForm(data),
        [],
        (result) => {
            alert.setSuccess("Rejected Successfully");
            setOpen(false);
            setIsApprove(false);
            setRefresh(!refresh);
        },
        (err) => {
            setIsprint(false);
            const errResponse = err?.data?.errors[0];
            if (errResponse) {
                alert.setError(errResponse.message);
            } else {
                alert.setError("Rejected something went wrong");
            }
        }
    );

    React.useEffect(() => {
        commonContext.setHideNav(true);
        return () => commonContext.setHideNav(false);
    });

    const CurrentComponent = steps[activeStep].component;
    const formInvalid = !steps.every((item) => !item.error);
    const handleStep = (index, error) => {
        if (!isCustomer || error) {
            setActiveStep(index);
            getFormError(steps, message, getErrorCollection());
        }
    };

    return (
        <SpinnerWrapper isLoading={leadApi.isLoading}>
            <Grid item container>
                <Grid item md={3} lg={3}>
                    {" "}
                    <Box sx={{ maxWidth: 400 }}>
                        <Stepper
                            activeStep={activeStep}
                            data-testid="stepper"
                            orientation="vertical"
                        >
                            {steps.map((step, index) => (
                                <Step key={`${step.label}-${index}`}>
                                    <StepLabelWrapper
                                        onClick={() =>
                                            handleStep(index, step.error)
                                        }
                                        error={step.error}
                                    >
                                        {message(step.label)}
                                    </StepLabelWrapper>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === steps.length && (
                            <Paper square elevation={0} sx={{ p: 3 }}>
                                <Typography>
                                    All steps completed - you&apos;re finished
                                </Typography>
                                <Button
                                    onClick={handleReset}
                                    sx={{ mt: 1, mr: 1 }}
                                >
                                    Reset
                                </Button>
                            </Paper>
                        )}
                    </Box>
                </Grid>
                <Grid item md={9} lg={9}>
                    <Box>
                        <TitleMain>
                            <Typography variant="poster1">
                                {message("account.form.heading")}
                            </Typography>
                            {isSales && (
                                <NavButton
                                    name={message("account.form.button.save")}
                                    variant="contained"
                                    color="success"
                                    data-testid="save"
                                    onClick={handleSubmit(handleSave)}
                                />
                            )}
                            {isSummary ? (
                                <>
                                    {(currentUserType === UserType.Manager ||
                                        isValidApprover) && (
                                        <NavButton
                                            isLoading={isLoading}
                                            name={message(
                                                "account.form.button.reject"
                                            )}
                                            variant="outlined"
                                            color="success"
                                            sx={{
                                                color: "#84BD00",
                                                "&:hover": {
                                                    color: "white",
                                                },
                                                backgroundColor: "white",
                                                textTransform: "none",
                                            }}
                                            disabled={
                                                ![
                                                    AccountStatus.PENDING_FOR_APPROVAL,
                                                ].includes(
                                                    accountInfo?.status
                                                ) ||
                                                (isManager &&
                                                    accountInfo?.accountApprovalHistory.filter(
                                                        (item) =>
                                                            item.isApproved &&
                                                            item.approverEmpId ===
                                                                userContextInfo.employeeId
                                                    ).length > 0)
                                            }
                                            onClick={openReject}
                                        />
                                    )}
                                    {(accountInfo?.status == 2 ||
                                        accountInfo?.status == 1 ||
                                        accountInfo?.status == 15) && (
                                        <NavButton
                                            isLoading={
                                                isLoading ||
                                                submitData.isLoading
                                            }
                                            name={message(
                                                "account.form.button.sendApproval"
                                            )}
                                            variant="contained"
                                            color="success"
                                            disabled={
                                                formInvalid ||
                                                !(
                                                    currentUserType ===
                                                    UserType.Sales
                                                )
                                            }
                                            onClick={handleProceed}
                                        />
                                    )}
                                    {!isCustomer &&
                                        (isManager || isValidApprover) && (
                                            <NavButton
                                                isLoading={
                                                    isLoading ||
                                                    submitData.isLoading
                                                }
                                                name={message(
                                                    "account.form.button.approve"
                                                )}
                                                variant="contained"
                                                color="success"
                                                disabled={
                                                    formInvalid ||
                                                    ![
                                                        AccountStatus.PENDING_FOR_APPROVAL,
                                                    ].includes(
                                                        accountInfo?.status
                                                    ) ||
                                                    (isManager &&
                                                        accountInfo?.accountApprovalHistory.filter(
                                                            (item) =>
                                                                item.isApproved &&
                                                                item.approverEmpId ===
                                                                    userContextInfo.employeeId
                                                        ).length > 0)
                                                }
                                                onClick={handleProceed}
                                            />
                                        )}
                                    {currentUserType === UserType.Customer &&
                                        AccountStatus.CUSTOMER_LINK_SENT ===
                                            accountInfo?.status && (
                                            <NavButton
                                                disabled={formInvalid}
                                                isLoading={
                                                    isLoading ||
                                                    submitData.isLoading
                                                }
                                                name={message(
                                                    "account.form.button.submit"
                                                )}
                                                variant="contained"
                                                color="success"
                                                onClick={() =>
                                                    setShowOtpModel(true)
                                                }
                                            />
                                        )}
                                </>
                            ) : (
                                <></>
                            )}
                        </TitleMain>
                        {accountInfo?.status === 15 && accountInfo?.note && (
                            <ErrorContainer>
                                <AlertContainer
                                    severity="warning"
                                    color="error"
                                >{`Reason For Rejection: ${accountInfo?.note}`}</AlertContainer>
                            </ErrorContainer>
                        )}
                        <CurrentComponent
                            isManager={isManager && isEdit(accountInfo?.status)}
                            isValidApprover={isValidApprover}
                            isCustomer={isCustomer}
                            isSales={isSales && isEdit(accountInfo?.status)}
                            status={accountInfo?.status}
                            userId={commonContext?.userContextInfo?.employeeId}
                            register={register}
                            handleNext={handleNext}
                            handleBack={handleBack}
                            setValues={setValues}
                            fieldValue={fieldValue}
                            currentIndex={activeStep}
                            validate={validate}
                            isValid={isValid}
                            leadId={leadId}
                        />
                    </Box>

                    {isCustomer &&
                        isSummary &&
                        isPrint &&
                        manangerStepper
                            .slice(0, manangerStepper.length - 2)
                            .reverse()
                            .map((item, key) => {
                                const Component =
                                    item.summary || item.component;
                                return (
                                    <div key={key}>
                                        <Component
                                            id={`step-two-form${key}`}
                                            register={register}
                                            isView={true}
                                            currentIndex={-1}
                                            isManager={isManager}
                                            isCustomer={isCustomer}
                                            isSales={isSales}
                                            status={accountInfo?.status}
                                            userId={
                                                commonContext?.userContextInfo
                                                    ?.employeeId
                                            }
                                            handleNext={handleNext}
                                            handleBack={handleBack}
                                            setValues={setValues}
                                            getValue={getValue}
                                            fieldValue={fieldValue}
                                            validate={validate}
                                            isValid={isValid}
                                        />
                                    </div>
                                );
                            })}
                </Grid>
            </Grid>
            <BasicModal open={open} handleClose={handleModelClose}>
                {isApprove ? (
                    <Approve
                        handleClose={handleModelClose}
                        handleApprove={handleApprove}
                        name={fieldValue?.companyName}
                    />
                ) : isApproval ? (
                    <SendForApproval
                        handleClose={handleModelClose}
                        handleSendApproval={handleApprove}
                        name={fieldValue?.companyName}
                    />
                ) : (
                    <Reject
                        handleClose={handleModelClose}
                        handleReject={handleReject}
                    />
                )}
            </BasicModal>
            <BasicModal
                open={showOtpModel}
                handleClose={() => setShowOtpModel(!showOtpModel)}
            >
                <div style={{ height: "100vh" }}>
                    <OtpComponent
                        onSubmit={handleOtpSubmit}
                        isLoading={verifySignOTPApi.isLoading}
                        type={3}
                        leadId={accountInfo?.leadId}
                    />
                </div>
            </BasicModal>
        </SpinnerWrapper>
    );
}
