export default class Result {
    name: string;
    defaultValue: any;
    data: {} | any;
    error: any;
    constructor(name: string, defaultValue: any) {
        this.name = name;
        this.defaultValue = defaultValue;
        this.data = {};
        this.data[name] = {
            isLoading: false,
            error: undefined,
            data: defaultValue,
        };
    }

    setLoading(loading: boolean, defaultValue: any) {
        this.data[this.name].isLoading = loading;
        if (!this.data[this.name].data) {
            this.data[this.name].data = defaultValue;
        }
        return { ...this.data[this.name] };
    }

    setData(data: any) {
        this.data[this.name].isLoading = false;
        this.data[this.name].data = data;
        this.data[this.name].error = undefined;
        return { ...this.data[this.name] };
    }

    setError(error: any) {
        this.data[this.name].isLoading = false;
        this.data[this.name].data = this.defaultValue;
        this.data[this.name].error = {
            errorMessage: "Application Error",
            description: error,
        };
        return { ...this.data[this.name], error: true, isOffline: false };
    }

    getState() {
        return this.data[this.name];
    }
}
