/* eslint-disable no-undef */
import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { ReactComponent as LocationIcon } from "../assets/location-dot.svg";
import { ReactComponent as SuitCaseIcon } from "../assets/suitcase.svg";
import { ReactComponent as StarIcon } from "../assets/star.svg";
import { ReactComponent as LinkIcon } from "../assets/link.svg";
import { ReactComponent as UserCheckIcon } from "../assets/user-approve.svg";
import { ReactComponent as CloseIcon } from "../assets/cross-icon.svg";
import { ReactComponent as CheckIcon } from "../assets/check-icon.svg";
import { ReactComponent as CircleDashIcon } from "../assets/circle-dash.svg";
import { ACCOUNT_STATUS } from "../constants/account.status";

const styleData = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "2px 6px",
    gap: "4px",
    width: "48px",
    height: "20px",
    color: "red",
    background: "rgba(173, 173, 176, 0.15)",
    borderRadius: "100px",
    marginBottom: "5px",
};

export const TabsData = [
    {
        name: "layout.sideNav.branchLeads",
        value: 0,
    },
    {
        name: "pages.leadDashboard.assignedLeads",
        value: 1,
    },
    {
        name: "pages.leadDashboard.successful",
        value: 2,
    },
    {
        name: "pages.leadDashboard.failed",
        value: 3,
    },
];
export const headCells = [
    {
        id: "leadName",
        numeric: true,
        disablePadding: false,
        label: "sharedComponent.table.header.leadName",
    },
    {
        id: "channel",
        numeric: true,
        disablePadding: false,
        label: "sharedComponent.table.header.channel",
    },
    {
        id: "subChannel",
        numeric: true,
        disablePadding: false,
        label: "sharedComponent.table.header.subChannel",
    },
    {
        id: "importDate",
        numeric: true,
        disablePadding: false,
        label: "sharedComponent.table.header.importDate",
    },
    {
        id: "dueDate",
        numeric: true,
        disablePadding: false,
        label: "sharedComponent.table.header.dueDate",
    },
    {
        id: "assignee",
        numeric: true,
        disablePadding: false,
        label: "sharedComponent.table.header.assignee",
    },
    {
        id: "status",
        numeric: true,
        disablePadding: false,
        label: "sharedComponent.table.header.status",
    },
    {
        id: "colon",
        numeric: true,
        disablePadding: false,
        label: "-",
    },
];

export const createAccountList = {
    data: [
        {
            id: 5,

            shopName: "อรวรรณแสงแก้ว(Joon Studio & Cafe1",

            importDate: "2023-04-27T07:21:40.173Z",

            placeId: null,

            assignee: "Thailand",

            latitude: "102.83",

            longitude: "16.43",

            priceLevelStart: null,

            priceLevelEnd: null,

            openingHourStart: null,

            openingHourEnd: null,

            openday: null,

            rating: null,

            reviews: null,

            leadeSourceUrl: null,

            status: "new",

            favorite: false,

            createdAt: "2023-04-27T07:21:40.175Z",

            updatedAt: "2023-04-27T07:21:40.175Z",

            deletedAt: null,
        },

        {
            id: 6,

            shopName: "อรวรรณแสงแก้ว(Joon Studio & Cafe1",

            importDate: "2023-04-27T07:21:40.173Z",

            placeId: null,

            assignee: "Thailand",

            latitude: "102.83",

            longitude: "16.45",

            priceLevelStart: null,

            priceLevelEnd: null,

            openingHourStart: null,

            openingHourEnd: null,

            openday: null,

            rating: null,

            reviews: null,

            leadeSourceUrl: null,

            status: "new",

            favorite: false,

            createdAt: "2023-04-27T07:21:40.176Z",

            updatedAt: "2023-04-27T07:21:40.176Z",

            deletedAt: null,
        },

        {
            id: 7,

            shopName: "อรวรรณแสงแก้ว(Joon Studio & Cafe1",

            importDate: "2023-04-27T07:21:40.173Z",

            placeId: null,

            assignee: "Thailand",

            latitude: "77.36",

            longitude: "28.67",

            priceLevelStart: null,

            priceLevelEnd: null,

            openingHourStart: null,

            openingHourEnd: null,

            openday: null,

            rating: null,

            reviews: null,

            leadeSourceUrl: null,

            status: "pendingInfo",

            favorite: false,

            createdAt: "2023-04-27T07:21:40.176Z",

            updatedAt: "2023-04-27T07:21:40.176Z",

            deletedAt: null,
        },

        {
            id: 8,

            shopName: "อรวรรณแสงแก้ว(Joon Studio & Cafe1",

            importDate: "2023-04-27T07:21:40.173Z",

            placeId: null,

            assignee: "Thailand",

            latitude: "102.84",

            longitude: "16.42",

            priceLevelStart: null,

            priceLevelEnd: null,

            openingHourStart: null,

            openingHourEnd: null,

            openday: null,

            rating: null,

            reviews: null,

            leadeSourceUrl: null,

            status: "pending",

            favorite: false,

            createdAt: "2023-04-27T07:21:40.176Z",

            updatedAt: "2023-04-27T07:21:40.176Z",

            deletedAt: null,
        },
    ],
    total: 4,
};

export const exportColumnList = [
    { field: "id", title: "ID", width: 20 },
    { field: "shopName", title: "Shop Name" },
    { field: "channel.channelName", title: "Channel" },
    { field: "subChannel.subChannelName", title: "Segment" },
    { field: "createdAt", title: "Date" },
    { field: "leadBranchMappings.branches.branchName", title: "Branch Name" },
    { field: "leadSalesMappings.userId", title: "User" },
    { field: "status", title: "Statue" },
];

export const createLeadColumn = {
    checkBox: true,
    indexId: "shopName",
    column: [
        {
            id: "shopName",
            // eslint-disable-next-line react/react-in-jsx-scope
            numeric: true,
            type: "custom",
            render: ({ shopName, leadExternal }, message) => {
                return shopName ? (
                    <Tooltip title={message(shopName)} placement="top" arrow>
                        <>
                            {leadExternal && leadExternal?.customerId && (
                                <div style={{ color: "gray" }}>{`${message(
                                    "sharedComponent.modalLis.duplicateChecks.CustomerId"
                                )} : ${leadExternal?.customerId}`}</div>
                            )}
                            <span>
                                <StarIcon /> {` ${shopName}`}
                            </span>
                        </>
                    </Tooltip>
                ) : (
                    "-"
                );
            },
            disablePadding: false,
            sortable: true,
            label: "createAccount.table.column.shopName",
        },
        {
            id: "channel",
            numeric: true,
            disablePadding: false,
            sortable: false,
            label: "createAccount.table.column.channel",
            type: "custom",
            render: ({ channel }) => {
                // eslint-disable-next-line react/react-in-jsx-scope
                return channel?.channelName || "-";
            },
        },
        {
            id: "subChannel",
            numeric: true,
            type: "custom",
            disablePadding: false,
            sortable: false,
            label: "createAccount.table.column.subChannel",
            render: ({ subChannel }) => {
                // eslint-disable-next-line react/react-in-jsx-scope
                return subChannel?.subChannelName || "-";
            },
        },
        {
            id: "assignee",
            numeric: true,
            type: "custom",
            render: ({ leadSalesMappings, leadBranchMappings }) => {
                // eslint-disable-next-line react/react-in-jsx-scope
                return (
                    <>
                        <div>
                            <LocationIcon />
                            {`" "${leadBranchMappings?.branches?.branchName}` ||
                                " - "}
                        </div>
                        <div>
                            <SuitCaseIcon />
                            {`" "${leadSalesMappings?.userId}` || " - "}
                        </div>
                    </>
                );
            },
            disablePadding: false,
            sortable: false,
            label: "createAccount.table.column.assignee",
        },
        {
            id: "createdAt",
            numeric: true,
            type: "date",
            disablePadding: false,
            sortable: true,
            label: "createAccount.table.column.createdAt",
        },
        {
            id: "approval",
            numeric: true,
            type: "custom",
            render: ({ status, note }, message) => {
                return (
                    <>
                        {status < 3 && (
                            <Tooltip
                                title={message(ACCOUNT_STATUS[status])}
                                placement="top"
                                arrow
                            >
                                <div
                                    style={{
                                        ...styleData,
                                        color: "#ADADB0",
                                        background: "rgba(173, 173, 176, 0.15)",
                                    }}
                                >
                                    <CircleDashIcon />
                                    <UserCheckIcon fill="currentColor" />
                                </div>
                            </Tooltip>
                        )}

                        {status > 3 && status != 15 && (
                            <Tooltip
                                title={message(ACCOUNT_STATUS[status])}
                                placement="top"
                                arrow
                            >
                                <div
                                    style={{
                                        ...styleData,
                                        color: "#84BD00",
                                        background: "rgba(237, 245, 217, 1)",
                                    }}
                                >
                                    <CheckIcon />
                                    <UserCheckIcon fill="currentColor" />
                                </div>
                            </Tooltip>
                        )}

                        {status === 15 && (
                            <Tooltip title={note} placement="top" arrow>
                                <div
                                    style={{
                                        ...styleData,
                                        color: "#F4442E",
                                        background: "rgba(254, 237, 235, 1)",
                                    }}
                                >
                                    <CloseIcon />
                                    <UserCheckIcon fill="currentColor" />
                                </div>
                            </Tooltip>
                        )}
                    </>
                );
            },
            disablePadding: false,
            sortable: false,
            label: "createAccount.table.column.approval",
        },
        {
            id: "status",
            numeric: true,
            type: "status",
            disablePadding: false,
            sortable: true,
            label: "createAccount.table.column.status",
        },
    ],
};

function createData(
    leadName,
    channel,
    segment,
    importDate,
    dueDate,
    assignee,
    status
) {
    return {
        leadName,
        channel,
        segment,
        importDate,
        dueDate,
        assignee,
        status,
    };
}
export const rows = [
    createData(
        "อรวรรณ แสงแก้ว (Joon Studio & Cafe1)",
        "FS (XXL)",
        "Restaurant ราดหน้า,ผัดซีอิ๊ว,ผั...",
        "11/11/2022",
        "11/11/2022",
        "Prachachuen",
        "new"
    ),
    createData(
        "อรวรรณ แสงแก้ว (Joon Studio & Cafe2)",
        "FS (XXL)",
        "Restaurant ราดหน้า,ผัดซีอิ๊ว,ผั...",
        "11/11/2022",
        "11/11/2022",
        "Prachachuen",
        "new"
    ),
    createData(
        "อรวรรณ แสงแก้ว (Joon Studio & Cafe3)",
        "FS (XXL)",
        "Restaurant ราดหน้า,ผัดซีอิ๊ว,ผั...",
        "11/11/2022",
        "11/11/2022",
        "Prachachuen",
        "new"
    ),
    createData(
        "อรวรรณ แสงแก้ว (Joon Studio & Cafe13)",
        "FS (XXL)",
        "Restaurant ราดหน้า,ผัดซีอิ๊ว,ผั...",
        "11/11/2022",
        "11/11/2022",
        "Prachachuen",
        "pendingInfo"
    ),
    createData(
        "อรวรรณ แสงแก้ว (Joon Studio & Cafe4)",
        "FS (XXL)",
        "Restaurant ราดหน้า,ผัดซีอิ๊ว,ผั...",
        "11/11/2022",
        "11/11/2022",
        "Prachachuen",
        "Prachachuen"
    ),
    createData(
        "อรวรรณ แสงแก้ว (Joon Studio & Cafe5)",
        "FS (XXL)",
        "Restaurant ราดหน้า,ผัดซีอิ๊ว,ผั...",
        "11/11/2022",
        "11/11/2022",
        "Prachachuen",
        "Action Required"
    ),
    createData(
        "อรวรรณ แสงแก้ว (Joon Studio & Cafe6)",
        "FS (XXL)",
        "Restaurant ราดหน้า,ผัดซีอิ๊ว,ผั...",
        "11/11/2022",
        "11/11/2022",
        "Prachachuen",
        "In Progress"
    ),
    createData(
        "อรวรรณ แสงแก้ว (Joon Studio & Cafe7)",
        "FS (XXL)",
        "Restaurant ราดหน้า,ผัดซีอิ๊ว,ผั...",
        "11/11/2022",
        "11/11/2022",
        "Prachachuen",
        "Failed"
    ),
    createData(
        "อรวรรณ แสงแก้ว (Joon Studio & Cafe8)",
        "FS (XXL)",
        "Restaurant ราดหน้า,ผัดซีอิ๊ว,ผั...",
        "11/11/2022",
        "11/11/2022",
        "Prachachuen",
        "Overdue"
    ),
    createData(
        "อรวรรณ แสงแก้ว (Joon Studio & Cafe9)",
        "FS (XXL)",
        "Restaurant ราดหน้า,ผัดซีอิ๊ว,ผั...",
        "11/11/2022",
        "11/11/2022",
        "Prachachuen",
        "new"
    ),
    createData(
        "อรวรรณ แสงแก้ว (Joon Studio & Cafe19)",
        "FS (XXL)",
        "Restaurant ราดหน้า,ผัดซีอิ๊ว,ผั...",
        "11/11/2022",
        "11/11/2022",
        "Prachachuen",
        "new"
    ),
    createData(
        "อรวรรณ แสงแก้ว (Joon Studio & Cafe0)",
        "FS (XXL)",
        "Restaurant ราดหน้า,ผัดซีอิ๊ว,ผั...",
        "11/11/2022",
        "11/11/2022",
        "Prachachuen",
        "new"
    ),
];

export const dummyData = {
    data: [
        {
            id: 5,

            shopName: "อรวรรณแสงแก้ว(Joon Studio & Cafe1",

            importDate: "2023-04-27T07:21:40.173Z",

            placeId: null,

            latitude: "102.83",

            longitude: "16.43",

            priceLevelStart: null,

            priceLevelEnd: null,

            openingHourStart: null,

            openingHourEnd: null,

            openday: null,

            rating: null,

            reviews: null,

            leadeSourceUrl: null,

            status: "new",

            favorite: false,

            createdAt: "2023-04-27T07:21:40.175Z",

            updatedAt: "2023-04-27T07:21:40.175Z",

            deletedAt: null,
        },

        {
            id: 6,

            shopName: "อรวรรณแสงแก้ว(Joon Studio & Cafe1",

            importDate: "2023-04-27T07:21:40.173Z",

            placeId: null,

            latitude: "102.83",

            longitude: "16.45",

            priceLevelStart: null,

            priceLevelEnd: null,

            openingHourStart: null,

            openingHourEnd: null,

            openday: null,

            rating: null,

            reviews: null,

            leadeSourceUrl: null,

            status: "new",

            favorite: false,

            createdAt: "2023-04-27T07:21:40.176Z",

            updatedAt: "2023-04-27T07:21:40.176Z",

            deletedAt: null,
        },

        {
            id: 7,

            shopName: "อรวรรณแสงแก้ว(Joon Studio & Cafe1",

            importDate: "2023-04-27T07:21:40.173Z",

            placeId: null,

            latitude: "77.36",

            longitude: "28.67",

            priceLevelStart: null,

            priceLevelEnd: null,

            openingHourStart: null,

            openingHourEnd: null,

            openday: null,

            rating: null,

            reviews: null,

            leadeSourceUrl: null,

            status: "pendingInfo",

            favorite: false,

            createdAt: "2023-04-27T07:21:40.176Z",

            updatedAt: "2023-04-27T07:21:40.176Z",

            deletedAt: null,
        },

        {
            id: 8,

            shopName: "อรวรรณแสงแก้ว(Joon Studio & Cafe1",

            importDate: "2023-04-27T07:21:40.173Z",

            placeId: null,

            latitude: "102.84",

            longitude: "16.42",

            priceLevelStart: null,

            priceLevelEnd: null,

            openingHourStart: null,

            openingHourEnd: null,

            openday: null,

            rating: null,

            reviews: null,

            leadeSourceUrl: null,

            status: "pending",

            favorite: false,

            createdAt: "2023-04-27T07:21:40.176Z",

            updatedAt: "2023-04-27T07:21:40.176Z",

            deletedAt: null,
        },
    ],
    total: 4,
};

export const leadsData = [
    {
        key: "Shop name",
        name: "shopName",
        value: "Joon Cafe",
        datepicker: false,
    },
    {
        key: "Import Date",
        name: "importDate",
        value: "21/02/23",
        datepicker: true,
    },
    {
        key: "Place Id",
        name: "placeId",
        value: "1000000000",
        datepicker: false,
    },
    {
        key: "Contact Person",
        name: "contactPerson",
        value: "อรวรรณ แสงแก้ว",

        datepicker: false,
    },
    {
        key: "Contact Number",
        value: "081-234-5678",
        name: "contactNum",
        datepicker: false,
    },
    {
        key: "Address",
        name: "address",
        value: "323 ซอย วิภาวดีรังสิต Thung Song Hong, Lak Si, Bangkok 10210",
        datepicker: false,
    },
    {
        key: "Geo Data",
        name: "geoData",
        value: "41.40338, 2.17403",
        datepicker: false,
    },
    {
        key: "Price Level",
        name: "price",
        value: "251",
        datepicker: false,
    },
    {
        key: "Opening Hours",
        name: "openHours",
        value: "-",
        datepicker: false,
    },
    {
        key: "Rating & Review",
        name: "rating",
        value: "4.7 ratings",
        datepicker: false,
    },
    {
        key: "No. of Seats",
        name: "seats",
        value: "-",
        datepicker: false,
    },
    {
        key: "Lead Source",
        name: "leadSource",
        value: "4.7 ratings",
        datepicker: false,
    },
    {
        key: "Note",
        name: "note",
        datepicker: false,
        value: "-",
    },
];

export const assignedData = [
    {
        key: "Assigned Branch",
        value: "Joon Cafe",
    },
    {
        key: "Assigned Sale",
        value: "21/02/23",
    },
    {
        key: "Due Date",
        value: "1000000000",
    },
    {
        key: "Assigner",
        value: "อรวรรณ แสงแก้ว",
    },
    {
        key: "Assign Date",
        value: "081-234-5678",
    },
];
export const LeadsDetailsData = [
    {
        name: "sharedComponent.tabs.header.leadInfo",
        value: 0,
    },
    {
        name: "sharedComponent.tabs.header.segmentation",
        value: 1,
    },
    // {
    //   "name": "sharedComponent.tabs.header.sales&Gap",
    //   value: 2
    // },
    {
        name: "sharedComponent.tabs.header.backgroundInfo",
        value: 2,
    },
    {
        name: "sharedComponent.tabs.header.activity",
        value: 3,
    },
];

export const checkboxData = [
    { checked: false, label: "Sun" },
    { checked: false, label: "Mon" },
    { checked: false, label: "Tue" },
    { checked: false, label: "Wed" },
    { checked: false, label: "Thu" },
    { checked: false, label: "Fri" },
    { checked: false, label: "Sat" },
];

export const ModalList = [
    {
        customerId: "1234567890",
        mobileNo: "081-234-5678",
        cafeName: `อรวรรณ แสงแก้ว (Joon Studio & Cafe)`,
        address:
            "304 ซอย 5 ตึกวิภาวดี ถนนวิภาวดีรังสิต แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพมหานคร 10250",
        latitide: 41.40338,
        logitude: 2.17403,
    },
    {
        customerId: "1234567890",
        mobileNo: "081-234-5678",
        cafeName: `อรวรรณ แสงแก้ว (Joon Studio & Cafe)`,
        address:
            "304 ซอย 5 ตึกวิภาวดี ถนนวิภาวดีรังสิต แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพมหานคร 10250",
        latitide: 41.40338,
        logitude: 2.17403,
    },
    {
        customerId: "1234567890",
        mobileNo: "081-234-5678",
        cafeName: `อรวรรณ แสงแก้ว (Joon Studio & Cafe)`,
        address:
            "304 ซอย 5 ตึกวิภาวดี ถนนวิภาวดีรังสิต แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพมหานคร 10250",
        latitide: 41.40338,
        logitude: 2.17403,
    },
    {
        customerId: "1234567890",
        mobileNo: "081-234-5678",
        cafeName: `อรวรรณ แสงแก้ว (Joon Studio & Cafe)`,
        address:
            "304 ซอย 5 ตึกวิภาวดี ถนนวิภาวดีรังสิต แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพมหานคร 10250",
        latitide: 41.40338,
        logitude: 2.17403,
    },
    {
        customerId: "1234567890",
        mobileNo: "081-234-5678",
        cafeName: `อรวรรณ แสงแก้ว (Joon Studio & Cafe)`,
        address:
            "304 ซอย 5 ตึกวิภาวดี ถนนวิภาวดีรังสิต แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพมหานคร 10250",
        latitide: 41.40338,
        logitude: 2.17403,
    },
    {
        customerId: "1234567890",
        mobileNo: "081-234-5678",
        cafeName: `อรวรรณ แสงแก้ว (Joon Studio & Cafe)`,
        address:
            "304 ซอย 5 ตึกวิภาวดี ถนนวิภาวดีรังสิต แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพมหานคร 10250",
        latitide: 41.40338,
        logitude: 2.17403,
    },
    {
        customerId: "1234567890",
        mobileNo: "081-234-5678",
        cafeName: `อรวรรณ แสงแก้ว (Joon Studio & Cafe)`,
        address:
            "304 ซอย 5 ตึกวิภาวดี ถนนวิภาวดีรังสิต แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพมหานคร 10250",
        latitide: 41.40338,
        logitude: 2.17403,
    },
];

export const ActivityMainActivity = [
    {
        name: "leadDetails.leadInfo.activityMainActivity.main1",
        discription: null,
        mainActivityId: 1,
        subActivity: [
            {
                name: "leadDetails.leadInfo.activityMainActivity.main1.sub1",
                discripion: "",
                subActivityId: 1,
            },
            {
                name: "leadDetails.leadInfo.activityMainActivity.main1.sub2",
                discripion: "",
                subActivityId: 2,
            },
        ],
    },
    {
        name: "leadDetails.leadInfo.activityMainActivity.main2",
        mainActivityId: 2,
        discription: null,
        subActivity: [
            {
                description: "Product1",
                name: "leadDetails.leadInfo.activityMainActivity.main2.sub1",
                subActivityId: 3,
            },
            {
                description: "Product2",
                name: "leadDetails.leadInfo.activityMainActivity.main2.sub2",
                subActivityId: 4,
            },
            {
                description: "Price",
                name: "leadDetails.leadInfo.activityMainActivity.main2.sub3",
                subActivityId: 5,
            },
            {
                description: "Place/Delivery",
                name: "leadDetails.leadInfo.activityMainActivity.main2.sub4",
                subActivityId: 6,
            },
            {
                description: "Promo1",
                name: "leadDetails.leadInfo.activityMainActivity.main2.sub5",
                subActivityId: 7,
            },
            {
                description: "Promo2",
                name: "leadDetails.leadInfo.activityMainActivity.main2.sub6",
                subActivityId: 8,
            },
            {
                description: "Promo3",
                name: "leadDetails.leadInfo.activityMainActivity.main2.sub7",
                subActivityId: 9,
            },
            {
                description: "Promo4",
                name: "leadDetails.leadInfo.activityMainActivity.main2.sub8",
                subActivityId: 10,
            },
            {
                description: "Service",
                name: "leadDetails.leadInfo.activityMainActivity.main2.sub9",
                subActivityId: 11,
            },
            {
                description: "Service",
                name: "leadDetails.leadInfo.activityMainActivity.main2.sub10",
                subActivityId: 12,
            },
            {
                description: "Service",
                name: "leadDetails.leadInfo.activityMainActivity.main2.sub11",
                subActivityId: 13,
            },
            {
                description: "Service",
                name: "leadDetails.leadInfo.activityMainActivity.main2.sub12",
                subActivityId: 14,
            },
            {
                description: "Others",
                name: "leadDetails.leadInfo.activityMainActivity.main2.sub13",
                subActivityId: 15,
            },
        ],
    },
    {
        name: "leadDetails.leadInfo.activityMainActivity.main3",
        discription: null,
        mainActivityId: 3,
        subActivity: [{ description: "", name: "N/A", subActivityId: 16 }],
    },
    {
        name: "leadDetails.leadInfo.activityMainActivity.main4",
        discription: null,
        mainActivityId: 4,
        subActivity: [{ description: "", name: "N/A", subActivityId: 17 }],
    },
    {
        name: "leadDetails.leadInfo.activityMainActivity.main5",
        discription: null,
        mainActivityId: 5,
        subActivity: [{ description: "", name: "N/A", subActivityId: 18 }],
    },
];
