import { required } from "../../validation/validation";

export const getFieldCollectionData = (steps: any) => {
    const result = {};
    let validtionFields = [];

    steps.forEach((step) => {
        const validationField = step.validationField || [];
        validtionFields = [...validtionFields, ...validationField];
    });

    for (const iterator of validtionFields) {
        result[iterator] = [required("")];
    }

    return result;
};

export const getFormError = (
    steps: any,
    message: any,
    errorCollection: any
) => {
    const errorResultCollection = steps.map((step, key) => {
        let messageComponent =
            step.validationField &&
            step.validationField.map((fieldName, index) => {
                if (!errorCollection[fieldName]) {
                    errorCollection[fieldName] = { error: "" };
                }
                return errorCollection[fieldName].error;
            });
        messageComponent = messageComponent
            ? messageComponent.filter((item) => item)
            : null;
        const showMessge = messageComponent && messageComponent.length > 0;
        step.error = showMessge ? "Error" : "";
        return showMessge ? "Error" : "";
    });
    return errorResultCollection.join("");
};
