import React, { useState, createContext } from "react";
import useAlert from "../hooks/useAlert";

interface AlertProviderProps {
    children: React.ReactNode;
}

interface AlertContextType {
    setSuccess?: (message: any) => unknown;
    setError?: (message: any) => unknown;
    setInfo?: (message: any) => unknown;
    setWarning?: (message: any) => unknown;
    reset?: () => unknown;
}

export const AlertContext = createContext<AlertContextType | null>({});

export const AlertContextProvider = ({ children }: AlertProviderProps) => {
    const alert = useAlert();
    return (
        <AlertContext.Provider value={{ ...alert }}>
            {alert.registerAlert()}
            {children}
        </AlertContext.Provider>
    );
};
