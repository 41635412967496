import React from "react";
import { Box, Card, Typography, ActionComponent } from "../../sharedComponent";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import TableData from "../../sharedComponent/table/Table";
import useCall from "../../../hooks/useCall";
import { getLeads } from "../../../services/LeadsgetApi";
import useTrans from "../../../hooks/useTranslation";
import { AlertContext } from "../../../context/alterContext";
import { ReactComponent as EllipsisIcon } from "../../../assets/ellipsis-vertical.svg";
import { updateLeadsInfo } from "../../../services/LeadsInfoApi";
import { useWaitCall } from "../../../hooks/useCall";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { useCommonContext } from "@betagro/ui-common";
import ExportExcel from "../../sharedComponent/excelExport";
import { ROLES } from "../../../constants/role.status";

const ImportedLeads = () => {
    const [message] = useTrans();
    const navigate = useNavigate();
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [refresh, setRefresh] = React.useState(false);
    const commonContext = useCommonContext();

    const handlePageChange = (pageVal, rowsPerPageVal) => {
        setPage(pageVal);
        setRowsPerPage(rowsPerPageVal);
    };

    const leadApi = useCall(
        () => getLeads(page, rowsPerPage),
        [page, rowsPerPage, refresh],
        []
    );
    const alert = React.useContext(AlertContext);
    const updateApiData = useWaitCall(
        (data) => updateLeadsInfo(data.id, { leads: { ...data.leads } }),
        [],
        () => {
            alert.setSuccess("Save Successfully");
            // setEdit(false);
            setRefresh(!refresh);
            commonContext.refreshCount();
        },
        () => {
            alert.setError("something went wrong");
        }
    );
    const handleArchive = (row, name, tabName, handleClose) => {
        if (name === "sharedComponent.listDivider.assignmentList.archive") {
            const apiData = {
                id: row?.id,
                leads: {
                    archive: true,
                },
            };
            updateApiData.callback(apiData);
            setRefresh(!refresh);
            handleClose();
        } else {
            return false;
        }
    };

    const _exporter = React.createRef<ExcelExport>();
    const [exportList, setExportList] = React.useState([]);
    const [downloadList, setDownloadList] = React.useState([]);

    const fetchExpoerData = useWaitCall(
        (count) => getLeads(1, count),
        {},
        async (response) => {
            setDownloadList(response?.data?.data?.data || []);
        },
        (error) => {
            alert.setError(
                error?.data?.errors?.join(",") || "Something went wrong"
            );
        }
    );

    React.useEffect(() => {
        if (downloadList?.length && _exporter.current) {
            _exporter.current.save(downloadList);
        }
    }, [downloadList]);

    const handleExport = async () => {
        if (exportList.length) {
            const list = leadApi?.data?.data?.data
                ? leadApi?.data?.data?.data.filter(
                      (val) => exportList.indexOf(val.id) >= 0
                  )
                : [];
            setDownloadList(list);
        } else if (leadApi.data?.data?.count) {
            fetchExpoerData.callback(leadApi.data?.data?.count);
        }
    };

    const [editBtn, setEditBtn] = React.useState(false);

    React.useEffect(() => {
        const roleCheck = commonContext?.userContextInfo?.role === ROLES.SALES;
        setEditBtn(roleCheck);
    }, []);

    const handleEdit = (row, name, tabName, handleClose) => {
        const tabNameConvert = message(tabName).replace(/\s+/g, "-");

        if (row?.id != null) {
            navigate(`/leadInfo/${row?.id}/${tabNameConvert}/edit`);
        }
        handleClose();
    };

    const actions = [
        { name: "Edit", handleClick: handleEdit },
        {
            name: "sharedComponent.listDivider.assignmentList.archive",
            handleClick: handleArchive,
        },
    ];

    return (
        <div>
            <Typography
                variant="poster1"
                sx={{
                    padding: "1rem 0rem",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                {message("layout.sideNav.importedLeads")}
                {/* <Button height={"40px"} name={message("layout.sideNav.newLead")} onClick={handleLeadNavigation}/> */}
            </Typography>
            <Box>
                <Card sx={{ padding: "15px" }}>
                    <TableData
                        setExportList={setExportList}
                        isLoading={leadApi?.isLoading}
                        tabName={message("layout.sideNav.importedLeads")}
                        handlePageChange={handlePageChange}
                        count={leadApi?.data?.data?.count || 0}
                        data={leadApi?.data?.data?.data}
                        buttonLabel={message(
                            "sharedComponent.listDivider.assignmentList.archive"
                        )}
                        icon={false}
                        handleExport={handleExport}
                        // popup={["Assign to", "Edit", "Archive"]}
                        pageAction={(row) =>
                            editBtn && (
                                <ActionComponent
                                    row={row}
                                    tabName={"layout.sideNav.importedLeads"}
                                    actions={actions}
                                >
                                    <EllipsisIcon />
                                </ActionComponent>
                            )
                        }
                    />
                    {
                        <ExportExcel
                            exporter={_exporter}
                            fileName={message("layout.sideNav.importedLeads")}
                        />
                    }
                </Card>
            </Box>
        </div>
    );
};

export default ImportedLeads;
