import React, { useState, useMemo, useContext, useEffect } from "react";
import {
    Card,
    CardHeader,
    IconButton,
    Typography,
    Divider,
    Button,
    SelectInput,
} from "../../../sharedComponent";
import useTrans from "../../../../hooks/useTranslation";
import { ReactComponent as EditIcon } from "../../../../assets/pen.svg";
import {
    CardActionsWrapper,
    CardWrapper,
    MainWrapper,
    TableWrapper,
    FieldsWrapper,
    CardContentWrapper,
    Heading,
} from "./segmentStyle";
import { prepareDropdownData } from "./pocketSize";
import useForm from "../../../../hooks/useForm";
import { useWaitCall } from "../../../../hooks/useCall";
import { updateChannel } from "../../../../services/LeadsInfoApi";
import { AlertContext } from "../../../../context/alterContext";
import { required } from "../../../../validation/validation";
import { ROLES } from "../../../../constants/role.status";
interface ILeadSegment {
    channels: any;
    leadInfo: any;
    refresh: Function;
    isEditPermission: boolean;
}
const LeadSegment = ({
    channels,
    leadInfo,
    isEditPermission,
}: ILeadSegment) => {
    const [message] = useTrans();
    const initialValue = {
        channelGroup: leadInfo?.channel?.channelGroup,
        channelId: leadInfo?.channel?.channelID,
        subChannelId: leadInfo?.subChannel?.subChannelID,
    };
    const { register, getValue, handleSubmit, validate, setValues, isValid } =
        useForm({
            defaultValues: initialValue,
        });

    const alert = useContext(AlertContext);
    const segementApi = useWaitCall(
        (segmentInfo) => updateChannel(leadInfo.id, segmentInfo),
        {},
        () => {
            alert.setSuccess("Save Successfully");
            setEdit(false);
            // refresh();
        },
        () => {
            alert.setError("something went wrong");
        }
    );
    const selectedChannelGroup = getValue("channelGroup", "");
    const selectedChannel = getValue("channelId", "");

    const selectedChannels = useMemo(() => {
        return getSegementCollection(
            channels,
            selectedChannelGroup,
            "channelGroup"
        )();
    }, [selectedChannelGroup]);

    const selectedSubChannels = useMemo(() => {
        if (
            !selectedChannels.find(
                (item) => item.channelGroup === selectedChannelGroup
            )
        ) {
            setValues({ subChannelId: "", channelId: "" });
        }

        const subChannelList = getSegementCollection(
            selectedChannels,
            selectedChannel,
            "channelID"
        )();

        return subChannelList.length === ROLES.SALES ? subChannelList[0].subChannel : [];
    }, [selectedChannelGroup, selectedChannel]);

    useEffect(() => {
        if (
            !selectedSubChannels.find(
                (item) => item.channelID === selectedChannel
            )
        ) {
            setValues({ subChannelId: "" });
        }
    }, [selectedChannel]);

    const onSubmit = (data) => {
        segementApi.callback({
            channelId: data.channelId,
            subChannelId: data.subChannelId,
        });
    };

    const [isEdit, setEdit] = useState(false);

    return (
        <MainWrapper>
            <Card>
                <CardHeader
                    action={
                        !isEdit &&
                        isEditPermission && (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setEdit(true);
                                    validate();
                                }}
                            >
                                <IconButton aria-label="settings">
                                    <EditIcon
                                        width={12}
                                        height={12}
                                        fill={"#919295"}
                                        cursor={"pointer"}
                                    />
                                </IconButton>
                                {message("edit")}
                            </div>
                        )
                    }
                    title={
                        <>
                            <Heading variant="h6">
                                {message(
                                    "sharedComponent.tabs.header.segmentation"
                                )}
                            </Heading>
                        </>
                    }
                />
                <Divider />
                <CardContentWrapper>
                    <CardWrapper>
                        <TableWrapper gridSize={2}>
                            <label>Channel*</label>
                            <FieldsWrapper>
                                <SelectInput
                                    {...register("channelGroup", required(""))}
                                    isView={!isEdit}
                                    options={prepareDropdownData(
                                        channels,
                                        "channelGroup",
                                        "channelGroup"
                                    )}
                                />
                                {!isEdit && getValue("channelId", "") && (
                                    <div>{">"}</div>
                                )}
                                <SelectInput
                                    {...register("channelId", required(""))}
                                    isView={!isEdit}
                                    options={prepareDropdownData(
                                        selectedChannels,
                                        "channelID",
                                        "channelName"
                                    )}
                                    disabled={selectedChannelGroup === ""}
                                />
                            </FieldsWrapper>
                        </TableWrapper>
                        <TableWrapper gridSize={4}>
                            <label>Segment*</label>
                            <FieldsWrapper>
                                <SelectInput
                                    isView={!isEdit}
                                    {...register("subChannelId", required(""))}
                                    options={prepareDropdownData(
                                        selectedSubChannels,
                                        "subChannelID",
                                        "subChannelName"
                                    )}
                                    disabled={selectedChannel === ""}
                                />
                            </FieldsWrapper>
                        </TableWrapper>
                    </CardWrapper>
                </CardContentWrapper>
                {isEdit && (
                    <CardActionsWrapper>
                        <Button
                            onClick={() => {
                                setEdit(false);
                                setValues(initialValue);
                            }}
                            name={message(
                                "sharedComponent.listDivider.leadInfo.button.cancel"
                            )}
                            variant="text"
                        ></Button>
                        <Button
                            disabled={!isValid}
                            isLoading={segementApi.isLoading}
                            onClick={handleSubmit(onSubmit)}
                            name={message(
                                "sharedComponent.listDivider.leadInfo.button.saveChanges"
                            )}
                        ></Button>
                    </CardActionsWrapper>
                )}
            </Card>
        </MainWrapper>
    );
};

export default LeadSegment;

function getSegementCollection(
    items: any,
    selectedItem: any,
    name: string
): () => any {
    return () => {
        const mychannels = items.filter((item) => item[name] === selectedItem);

        if (mychannels && mychannels.length) {
            return mychannels;
        }
        return items;
    };
}
