import React from "react";

import {
    Grid,
    FormControl,
    Radio,
    FormControlLabel,
    RadioGroup,
} from "../../../sharedComponent";
import { styled } from "@mui/material/styles";
import { IRadioInput } from "../../../../interface/InputField";

const errorStyle = {
    border: "2px solid red",
};
const FormLabel = styled("label")(() => ({
    fontSize: "1.25rem",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    color: "#464B55",
}));

const InputError = styled("span")(() => ({
    color: "red",
}));
const InputRadio = styled(FormControlLabel)(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "1.538rem",
    padding: "0.75rem 1rem",
    borderRadius: "0.5rem",
    // borderWidth: "1px",
    // borderStyle: "solid",
}));
const labelStyle = {
    color: "#919295",
    fontSize: "1.25rem",
    fontFamily: "DB Helvethaica X 65 Regular",
};
const gridLabelStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
};

const textStyle = {
    color: "#000000",
};

const TextInput = ({
    xs,
    md,
    value,
    label,
    options = [],
    name,
    id,
    error,
    onChange,
    labelType = "column",
    labelVal = true,
    disabled,
    isView = undefined,
}: IRadioInput) => {
    return (
        <>
            {labelVal && (
                <Grid item md={3} sx={gridLabelStyle}>
                    {labelType == "row" && (
                        <FormLabel
                            style={
                                isView != undefined
                                    ? { ...labelStyle, ...textStyle }
                                    : labelStyle
                            }
                        >
                            {label}
                        </FormLabel>
                    )}
                </Grid>
            )}

            <Grid item xs={xs} md={md}>
                <FormControl fullWidth>
                    {isView &&
                        options.find((item) => item.value === value)?.label}
                    {labelType != "row" && (
                        <FormLabel style={isView != undefined ? textStyle : {}}>
                            {label}
                        </FormLabel>
                    )}
                    {!isView && (
                        <RadioGroup
                            onChange={onChange}
                            name={name}
                            data-textid={id}
                            value={value}
                            row
                        >
                            {options.map((op, idx) => {
                                return (
                                    // eslint-disable-next-line react/jsx-key
                                    <Grid
                                        item
                                        key={`${op.label}-${idx}`}
                                        xs={6}
                                        md={6}
                                    >
                                        <InputRadio
                                            value={op.value}
                                            control={<Radio />}
                                            label={op.label}
                                            disabled={disabled}
                                            hidden={op.hidden}
                                            data-textid={name}
                                            style={error ? errorStyle : null}
                                        />
                                    </Grid>
                                );
                            })}
                            {error && <InputError>{error}</InputError>}
                        </RadioGroup>
                    )}
                </FormControl>
            </Grid>
        </>
    );
};

export default TextInput;
