import React from "react";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";
import { ReactComponent as UserFillIcon } from "../../../assets/user-fill.svg";
import { ReactComponent as AddressIcon } from "../../../assets/address.svg";
import { ReactComponent as CorporateIcon } from "../../../assets/corporate.svg";
import { ReactComponent as AttestIcon } from "../../../assets/attest.svg";
import {
    TextInput,
    Typography,
    Grid,
    AttestInput,
    Button,
    DateInput,
    FormControlLabel,
    Checkbox,
} from "../../sharedComponent";
import { ICreateAccountForm } from "../type";
import useTrans from "../../../hooks/useTranslation";
import { createAccountFormTwo } from "../../../utils/constants";
import CreateFullAddress from "./address/createFullAddress";
import AddressWrapper from "./address";
import { addressRequired, required } from "../../../validation/validation";

const Main = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0rem",
    gap: "1.688rem",
    width: "55.5rem",
    left: "27rem",
    top: "8rem",
}));

const FormContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0rem 0rem 2rem",
    gap: "1.5rem",
    width: "55.5rem",
    height: "100.875rem",
    background: "#FFFFFF",
    boxShadow: "0rem 0.25rem 5rem rgba(0, 0, 0, 0.04)",
    borderRadius: "1rem",
}));

const FormHead = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: "5.125rem",
    borderBottom: "0.063rem solid #EAEBEB",
    borderRadius: "1rem 16rem 0rem 0rem",
}));

const FormFrame = styled("form")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "4rem",
    padding: "0rem",
    width: "49.5rem",
    height: "67.25rem",
}));

const SubFormTitle = styled("p")(
    (props: { underline?: string }) => css`
        font-size: 1.5rem;
        font-weight: 600;
        text-decoration: ${props.underline || ""};
    `
);

const NavButton = styled(Button)(() => ({
    borderRadius: "2rem",
    backgroundColor: "#84BD00",
    width: "8.25rem",
    textTransform: "none",
}));

const styleIcon = {
    color: "#84BD00",
};

const CreateAccountStepThree = ({
    register,
    handleBack,
    handleNext,
    isView,
    isSales,
    setValues,
    id,
}: ICreateAccountForm) => {
    const [message] = useTrans();
    const [check, setCheck] = React.useState(false);
    const handleCheckboxChange = () => {
        if (isView) {
            return;
        }

        setCheck(!check);
    };
    const addressField = register(
        "memorandumAddress",
        required(""),
        addressRequired("")
    );
    const addresses = Array.isArray(addressField?.value)
        ? addressField.value
        : [{ defaultAddress: false }];

    const handleAddressChange = (values, index) => {
        if (values?.defaultAddress) {
            addresses.forEach((element) => {
                element.defaultAddress = false;
            });
        }
        addresses[index] = values;
        setValues({ memorandumAddress: addresses });
    };

    const firstName = register("firstName");
    const lastName = register("lastName");

    const currentAddress = addresses?.length > 0 ? addresses[0] : {};

    const underline = isView ? "underline" : "";

    return (
        <Main>
            <FormContainer id={id}>
                <FormHead>
                    <Typography sx={{ mx: 5 }} variant="poster2">
                        {message(createAccountFormTwo.title)}
                    </Typography>
                </FormHead>
                <FormFrame>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <SubFormTitle underline={underline}>
                                {isView ? (
                                    ""
                                ) : (
                                    <span style={styleIcon}>
                                        <UserFillIcon fill="currentColor" />
                                    </span>
                                )}
                                {message(createAccountFormTwo.secOne)}{" "}
                            </SubFormTitle>
                        </Grid>
                        {isView ? (
                            <>
                                <div
                                    style={{
                                        fontSize: 18,
                                        paddingLeft: 8,
                                        display: "flex",
                                    }}
                                >
                                    Name:{" "}
                                    {`${firstName?.value}  ${lastName?.value}`}
                                </div>
                            </>
                        ) : (
                            <>
                                <TextInput
                                    xs={6}
                                    md={6}
                                    label={message(
                                        createAccountFormTwo.labelFirstName
                                    )}
                                    {...firstName}
                                    id="businessFistName"
                                    isView={isView}
                                    disabled={!isSales}
                                />
                                <TextInput
                                    xs={6}
                                    md={6}
                                    label={message(
                                        createAccountFormTwo.labelLastName
                                    )}
                                    {...lastName}
                                    id="businessLastName"
                                    isView={isView}
                                    disabled={!isSales}
                                />
                            </>
                        )}
                        <Grid item xs={12} md={12}>
                            <SubFormTitle underline={underline}>
                                {isView ? (
                                    ""
                                ) : (
                                    <span style={styleIcon}>
                                        <AddressIcon fill="currentColor" />
                                    </span>
                                )}
                                {message(createAccountFormTwo.secTwo)}{" "}
                            </SubFormTitle>
                        </Grid>

                        {isView ? (
                            <div
                                style={{
                                    paddingLeft: 8,
                                    fontSize: 18,
                                }}
                            >
                                Address:
                                <CreateFullAddress
                                    houseNo={currentAddress.houseNo}
                                    soi={currentAddress.soi}
                                    building={currentAddress.building}
                                    address={currentAddress.address}
                                    province={currentAddress.province}
                                    district={currentAddress.district}
                                    subdistrict={currentAddress.subdistrict}
                                    postalCode={currentAddress.postalCode}
                                ></CreateFullAddress>
                            </div>
                        ) : (
                            <>
                                <AddressWrapper
                                    key={0}
                                    index={0}
                                    defaultValues={currentAddress}
                                    handleChange={handleAddressChange}
                                    isView={isView}
                                    isSales={isSales}
                                />
                            </>
                        )}
                        <hr />

                        {!isView && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <SubFormTitle>
                                        <span style={styleIcon}>
                                            <CorporateIcon fill="currentColor" />
                                        </span>{" "}
                                        {message(createAccountFormTwo.secThree)}{" "}
                                    </SubFormTitle>
                                </Grid>
                                <TextInput
                                    xs={6}
                                    md={6}
                                    {...register("betagrocompanyName")}
                                    value={message(
                                        createAccountFormTwo.businessName
                                    )}
                                    id="companyName"
                                    isView={isView}
                                    disabled={true}
                                />
                            </>
                        )}

                        <Grid item xs={12} md={12}>
                            <SubFormTitle underline={underline}>
                                {!isView && (
                                    <span style={styleIcon}>
                                        <AttestIcon fill="currentColor" />
                                    </span>
                                )}
                                {message(createAccountFormTwo.secFour)}{" "}
                            </SubFormTitle>
                        </Grid>
                        <AttestInput
                            xs={6}
                            md={6}
                            label={message(createAccountFormTwo.labelSignOne)}
                            dateFieldName="buyerSignDate"
                            register={register}
                            disabled={true}
                            name="buyerSign"
                            id="buyerSign"
                            isView={isView}
                            handleClick={() => {
                                setValues({ buyerSignDate: dayjs() });
                            }}
                        />
                        <AttestInput
                            xs={6}
                            md={6}
                            label={message(createAccountFormTwo.labelSignTwo)}
                            register={register}
                            disabled={!isSales}
                            name="sellerSign"
                            dateFieldName="salesSignDate"
                            id="sellerSign"
                            handleClick={() => {
                                setValues({ salesSignDate: dayjs() });
                            }}
                            isView={isView}
                        />
                        <TextInput
                            xs={3}
                            md={3}
                            label=""
                            id="buyerSignName"
                            value={`${register("firstName")?.value} ${
                                register("lastName")?.value
                            }`}
                            isView={isView}
                            disabled={true}
                        />

                        <DateInput
                            xs={3}
                            md={3}
                            label=""
                            name={"buyerSignDate"}
                            register={register}
                            id="buyerSignDate"
                            isView={isView}
                            disabled={true}
                        />
                        <TextInput
                            xs={3}
                            md={3}
                            label=""
                            {...register("sellerSignName")}
                            id="sellerSignName"
                            isView={isView}
                            disabled={!isSales}
                        />

                        <DateInput
                            xs={3}
                            md={3}
                            label=""
                            register={register}
                            name={"salesSignDate"}
                            id="salesSignDate"
                            isView={isView}
                            disabled={true}
                        />

                        {isView && (
                            <>
                                <Grid item xs={12} md={12} mt={2}>
                                    {message(createAccountFormTwo.secThree)}{" "}
                                    <TextInput
                                        {...register("betagrocompanyName")}
                                        value={message(
                                            createAccountFormTwo.businessName
                                        )}
                                        id="companyName"
                                        isView={isView}
                                        disabled={true}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12} md={12} mt={3}>
                            <FormControlLabel
                                key={"12"}
                                control={
                                    <Checkbox
                                        checked={!isView ? check : true}
                                        name={"checkbox"}
                                        color={isView ? "default" : "primary"}
                                        onChange={handleCheckboxChange}
                                    />
                                }
                                sx={{ color: "#000000" }}
                                label={
                                    <span style={{ fontSize: "1.45rem" }}>
                                        {message("account.form.agree")}
                                    </span>
                                }
                                style={{
                                    opacity: isView ? 0.4 : 1,
                                }}
                            />
                        </Grid>
                        {!isView && (
                            <Grid item xs={12} md={12} mt={5}>
                                <NavButton
                                    name={message(
                                        "account.form.button.previous"
                                    )}
                                    variant="contained"
                                    color="success"
                                    onClick={handleBack}
                                    sx={{
                                        float: "left",
                                    }}
                                />
                                <NavButton
                                    name={message("account.form.button.next")}
                                    variant="contained"
                                    color="success"
                                    onClick={handleNext}
                                    disabled={!check}
                                    sx={{
                                        float: "right",
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </FormFrame>
            </FormContainer>
        </Main>
    );
};

export default CreateAccountStepThree;
