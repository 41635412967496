import React from "react";
import {
    Paper,
    Typography,
    TextAreaInput,
    Grid,
    Box,
    Button,
} from "../../sharedComponent";
import { ReactComponent as CloseIcon } from "../../../assets/xmark-solid.svg";
import { styled } from "@mui/material/styles";

import useTrans from "../../../hooks/useTranslation";
import { IReject } from "../../../interfaces/accountForm";
const HeadingStyle = styled("div")(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}));
export const BoxStyle = styled(Box)(() => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    maxWidth: "80%",
    maxHeight: "70%",
}));

export const Icon = styled("span")(() => ({
    marginLeft: "3rem",
}));

const Reject = ({ handleClose, handleReject }: IReject) => {
    const [message] = useTrans();
    const [note, setNote] = React.useState("");

    const handleNoteChange = (e) => {
        setNote(e.target.value);
    };

    return (
        <BoxStyle component={Paper} p={7}>
            <div>
                <HeadingStyle>
                    <Typography variant="h4">
                        {message("accountForm.modal.reject.reason")}
                    </Typography>
                    <Icon>
                        <span data-testid="closeReject" onClick={handleClose}>
                            <CloseIcon
                                height={25}
                                width={25}
                                fill={"#47474A"}
                                cursor={"pointer"}
                            />
                        </span>
                    </Icon>
                </HeadingStyle>
                <Grid item lg={12}>
                    <TextAreaInput
                        label={message(
                            "accountForm.modal.reject.additionalNote"
                        )}
                        minRows={4}
                        multiline={true}
                        onChange={handleNoteChange}
                        value={note}
                    />
                </Grid>

                <Button
                    name={message("accountForm.modal.reject.confirm")}
                    variant="contained"
                    color="success"
                    onClick={() => handleReject(note)}
                    sx={{
                        backgroundColor: "#84BD00",
                        textTransform: "none",
                        width: "100%",
                    }}
                />
            </div>
        </BoxStyle>
    );
};
export default Reject;
