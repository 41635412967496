import * as React from "react";
import { TabsData } from "../../../../utils/apiData";
import LeadsTable from "../leadsTable/LeadsTable";
import TabsComponent from "../../../sharedComponent/tabs/Tabs";
import "./index.scss";
import { Chip } from "../../../sharedComponent";
import { useCommonContext } from "@betagro/ui-common";

const LeadsTabs = ({ data }: any) => {
    const { statusCount } = useCommonContext();
    const countConfig = {
        "pages.leadDashboard.branchLeads": statusCount?.assignedBranchCount,
        "pages.leadDashboard.assigned": statusCount?.assignedLeadCount,
        "pages.leadDashboard.successful": statusCount?.SUCCESSFUL,
        "pages.leadDashboard.failed": statusCount?.FAILED,
    };
    const labels = [
        "pages.leadDashboard.branchLeads",
        "pages.leadDashboard.assigned",
        // "pages.leadDashboard.shortListed",
        "pages.leadDashboard.successful",
        "pages.leadDashboard.failed",
    ];
    return (
        <>
            <TabsComponent
                tabsData={TabsData}
                labels={labels}
                icons={(currentTab) => (
                    <Chip
                        className="tab-chip"
                        label={countConfig[currentTab]}
                        size="small"
                    />
                )}
                PanelComp={LeadsTable}
                search={true}
                panelSx={{ padding: "24px" }}
                tabsSx={{ borderBottom: 1, borderColor: "divider" }}
            />
        </>
    );
};
export default LeadsTabs;
