import Axios from "./axiosInstance";
import { GET_BACKGROUND_URL, GET_CUSTOMER_SEARCH } from "./uriConstant";

// axios.defaults.proxy.host = "https://dev-bsale.betagro.com"

//
export const setBackgroundInfo = (data) => {
    return Axios.post(`${GET_BACKGROUND_URL}`, data);
};

export const getBackgroundInfo = (id: number) => {
    return Axios.get(GET_BACKGROUND_URL + "/" + id);
};

export const getSearchCustomerInfo = (data) => {
    return Axios.post(GET_CUSTOMER_SEARCH, data);
};
