import React from "react";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import {
    TextInput,
    SelectInput,
    Typography,
    Grid,
    AttestInput,
    Button,
    DateInput,
    FormControlLabel,
    Checkbox,
} from "../../sharedComponent";
import { ICreateAccountForm } from "../type";
import { Trans } from "react-i18next";
import useTrans from "../../../hooks/useTranslation";
import { verifySignOTP } from "../../../services/AccountApi";
import { AlertContext } from "../../../context/alterContext";
import {
    createAccountFormOne,
    createAccouuntButtons,
} from "../../../utils/constants";
import { useCommonContext } from "@betagro/ui-common";

const Main = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0rem",
    gap: "1.688rem",
    width: "55.5rem",
    left: "27rem",
    top: "8rem",
}));

const FormContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0rem 0rem 1rem",
    gap: "1.5rem",
    width: "55.5rem",
    height: "100.875rem",
    background: "#FFFFFF",
    boxShadow: "0rem 0.25rem 5rem rgba(0, 0, 0, 0.04)",
    borderRadius: "1rem",
}));

const FormHead = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    borderBottom: "0.063rem solid #EAEBEB",
    borderRadius: "1rem 16rem 0rem 0rem",
}));

const FormFrame = styled("form")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    padding: "0rem",
    width: "49.5rem",
    height: "67.25rem",
}));

const Ptext = styled("p")(() => ({
    fontFamily: "Arial",
    fontSize: "0.960rem",
    fontStyle: "normal",
    fontWeight: "normal",
    textDecoration: "none",
    textTransform: "none",
    lineHeight: "1.2rem",
    color: "#000000",
    backgroundColor: "#ffffff",
    margin: "0px",
    marginBottom: ".5rem",
}));
const Divtext = styled("div")(() => ({
    fontFamily: "Arial",
    fontSize: "0.960rem",
    fontStyle: "normal",
    fontWeight: "normal",
    textDecoration: "none",
    textTransform: "none",
    lineHeight: "1.2rem",
    color: "#000000",
    backgroundColor: "#ffffff",
    textIndent: "1.5rem",
    margin: "0px",
}));

const OrderedList = styled("ol")(() => ({
    paddingInlineStart: "2.25rem",
    fontSize: "0.960rem",
}));

const ListItem = styled("li")(() => ({
    fontFamily: "Arial",
    fontSize: "0.960rem",
    fontStyle: "normal",
    fontWeight: "normal",
    textDecoration: "none",
    textTransform: "none",
    color: "#000000",
    lineHeight: "1.2rem",
    backgroundColor: "#ffffff",
    marginLeft: "1rem",
}));

const Link = styled("a")(() => ({
    color: "#84BD00",
}));
const HRuler = styled("hr")(() => ({
    width: "100%",
    textAlign: "left",
    color: "#84BD00",
}));

const NavButton = styled(Button)(() => ({
    borderRadius: "2rem",
    backgroundColor: "#84BD00",
    width: "8.25rem",
    textTransform: "none",
}));

const SignatureStyle = styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
}));

const NameDateStyle = styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "77%",
}));

const Note = styled("span")(() => ({
    color: "#000000",
    fontSize: "1.15rem",
}));

const SubFormTitle = styled("p")(() => ({
    fontSize: "1.5rem",
    fontWeight: "600",
    margin: "0px",
}));

const DateContainer = styled("div")(() => ({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    width: "100%",
}));

const CreateAccountStepTwo = ({
    register,
    handleNext,
    handleBack,
    isView,
    isSales,
    isCustomer,
    setValues,
    leadId,
    id,
}: ICreateAccountForm) => {
    const [message] = useTrans();
    const alert = React.useContext(AlertContext);
    const [isVerified, setVerified] = React.useState(false);
    const [check, setCheck] = React.useState(false);
    const { selectedLanguage } = useCommonContext();
    const titleInfo = register("title");
    const checkboxInfo = register("isNationalIdCheck");
    const doNotcollection = register("DoNotcollection");

    const handleCheckboxChange = () => {
        if (isView) {
            return;
        }

        setCheck(!check);
    };

    const getDisableInfo = () => {
        if (isCustomer) {
            return !(
                check &&
                checkboxInfo.value &&
                register("buyerSign").value
            );
        }

        return !check;
    };

    const handleSubmitOtp = async (otp: string) => {
        if (otp?.length < 6) {
            alert.setWarning("Otp must be 6 digits");
            return false;
        }

        try {
            const resp = await verifySignOTP({ otp, leadId: leadId, type: 2 });

            if (resp?.data?.success) {
                // setValues({ buyerSignDate: dayjs(),signOtp: otp });
                setVerified(true);
                alert.setSuccess("OTP verified!");
                return true;
            } else {
                alert.setError("Invalid OTP!");
                return false;
            }
        } catch (err) {
            console.error(err);
            alert.setError("something went wrong");
            return false;
        }
    };

    // const generateSignatureOTP = async () => {
    //   const data = {
    //     leadId: leadId,
    //     type: 2,
    //   };

    //   try {
    //     if (!isVerified) {
    //       const signatureOtpResp = await generateSignOTP(data);
    //       if (signatureOtpResp?.data?.success) {
    //         alert.setSuccess("Signature OTP generated successfully!");
    //       } else {
    //         alert.setError("something went wrong");
    //       }
    //     }
    //   } catch (err) {
    //     console.error(err)
    //     alert.setError("something went wrong");
    //   }
    // };
    const titleOption = [
        { value: 1, label: message("account.form.aggrement.mr") },
        { value: 2, label: message("account.form.aggrement.mrs") },
        { value: 3, label: message("account.form.aggrement.miss") },
    ];

    const titleLabel = titleOption.find(
        (item) => item.value === register("title")?.value
    )?.label;
    return (
        <Main>
            <FormContainer id={id}>
                <FormHead>
                    <Typography sx={{ mx: 5 }} variant="poster2">
                        <Trans i18nKey={createAccountFormOne.title}></Trans>
                    </Typography>
                </FormHead>
                <FormFrame>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <div>
                                <Trans i18nKey={createAccountFormOne.body}>
                                    {[
                                        <Ptext key="0"></Ptext>,
                                        <br key="1" />,
                                        <Ptext key="2">
                                            {["", <b key="1"></b>, ""]}
                                        </Ptext>,

                                        <br key="3" />,
                                        <OrderedList key="4">
                                            {[
                                                <ListItem key="0"></ListItem>,
                                                <ListItem key="1"></ListItem>,
                                                <ListItem key="2">
                                                    {[
                                                        <span key="1">
                                                            {[
                                                                "",
                                                                <Link
                                                                    key="1"
                                                                    href="http://www.betagro.com"
                                                                ></Link>,
                                                                "",
                                                            ]}
                                                        </span>,
                                                        <span
                                                            style={{
                                                                clear: "both",
                                                                display:
                                                                    "inline-block",
                                                                width: "100%",
                                                            }}
                                                            key="1"
                                                        >
                                                            {[
                                                                "",
                                                                <span key="1">
                                                                    {[
                                                                        "",
                                                                        <Link
                                                                            key="1"
                                                                            href="http://www.betagro.com"
                                                                        ></Link>,
                                                                        "",
                                                                    ]}
                                                                </span>,
                                                            ]}
                                                        </span>,
                                                    ]}
                                                </ListItem>,
                                            ]}
                                        </OrderedList>,
                                        <br key="5" />,
                                        <Ptext key="6">
                                            {[
                                                "",
                                                <Link
                                                    key="2"
                                                    href="http://www.betagro.com/privacy-policy/"
                                                ></Link>,
                                            ]}
                                        </Ptext>,
                                        <br key="7" />,
                                        <div key="8">
                                            {[
                                                <Divtext key="0"></Divtext>,
                                                <Divtext key="1">
                                                    {[
                                                        "",
                                                        <Link
                                                            key="1"
                                                            href="mailto:DPOoffice@betagro.com"
                                                        ></Link>,
                                                    ]}
                                                </Divtext>,
                                                <Divtext key="2">
                                                    {[
                                                        "",
                                                        <Link
                                                            key="1"
                                                            href="02-146-1999"
                                                        ></Link>,
                                                    ]}
                                                </Divtext>,
                                            ]}
                                        </div>,
                                    ]}
                                </Trans>
                            </div>
                        </Grid>
                        <HRuler />

                        <Grid item xs={12} md={12}>
                            <SubFormTitle>
                                {message(createAccountFormOne.header)}
                            </SubFormTitle>
                            {register("productPurchaseDate")?.value &&
                                isView && (
                                    <DateContainer>
                                        <Typography>
                                            {
                                                <span>
                                                    <b>
                                                        {"\u00A0"}
                                                        {dayjs(
                                                            register(
                                                                "productPurchaseDate"
                                                            ).value
                                                        )
                                                            .locale(
                                                                selectedLanguage
                                                            )
                                                            .format(
                                                                "DD MMMM YYYY"
                                                            )}
                                                    </b>
                                                </span>
                                            }
                                        </Typography>
                                    </DateContainer>
                                )}
                        </Grid>
                        {isView ? (
                            <>
                                <TextInput
                                    inputStyle={{ fontSize: "1.5rem" }}
                                    xs={9}
                                    md={9}
                                    labelSize={3}
                                    labelType="row"
                                    label={message(createAccountFormOne.name)}
                                    value={`${titleLabel} ${
                                        register("firstName").value
                                    } ${register("lastName").value}`}
                                    id="firstName"
                                    disabled={true}
                                    isView={isView}
                                />

                                <TextInput
                                    inputStyle={{ fontSize: "1.5rem" }}
                                    xs={9}
                                    md={9}
                                    labelSize={3}
                                    label={message(
                                        createAccountFormOne.labelNationalId
                                    )}
                                    {...register("nationalId")}
                                    labelType="row"
                                    id="nationalId"
                                    disabled={!isSales}
                                    isView={isView}
                                />
                            </>
                        ) : (
                            <>
                                <SelectInput
                                    xs={3}
                                    md={3}
                                    label={message(
                                        createAccountFormOne.labelTitle
                                    )}
                                    options={titleOption}
                                    {...titleInfo}
                                    isView={isView}
                                    disabled={!isSales}
                                />
                                <TextInput
                                    xs={5}
                                    md={5}
                                    {...register("firstName")}
                                    id="firstName"
                                    isView={isView}
                                    label={message(
                                        createAccountFormOne.labelFirstName
                                    )}
                                    disabled={!isSales}
                                />
                                <TextInput
                                    xs={4}
                                    md={4}
                                    label={message(
                                        createAccountFormOne.labelLastName
                                    )}
                                    {...register("lastName")}
                                    isView={isView}
                                    id="lastName"
                                    disabled={!isSales}
                                />
                                <TextInput
                                    xs={6}
                                    md={6}
                                    label={message(
                                        createAccountFormOne.labelNationalId
                                    )}
                                    {...register("nationalId")}
                                    isView={isView}
                                    id="nationalId"
                                    disabled={!isSales}
                                />
                            </>
                        )}
                        <Ptext>
                            <Trans
                                i18nKey={createAccountFormOne.consent}
                            ></Trans>
                        </Ptext>
                        <Grid item xs={12} md={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...checkboxInfo}
                                        checked={checkboxInfo?.value}
                                        color={
                                            isView ||
                                            !isCustomer ||
                                            doNotcollection.value
                                                ? "default"
                                                : "primary"
                                        }
                                        onChange={(e) => {
                                            if (
                                                isView ||
                                                !isCustomer ||
                                                doNotcollection.value
                                            ) {
                                                return;
                                            }

                                            checkboxInfo.onChange({
                                                target: {
                                                    value: e.target.checked,
                                                },
                                            });
                                        }}
                                    />
                                }
                                sx={{ color: "#000000" }}
                                label={message(
                                    createAccountFormOne.labelCheckOne
                                )}
                                style={{
                                    opacity:
                                        isView ||
                                        !isCustomer ||
                                        doNotcollection.value
                                            ? 0.4
                                            : 1,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...doNotcollection}
                                        checked={doNotcollection?.value}
                                        color={
                                            isView ||
                                            !isCustomer ||
                                            checkboxInfo.value
                                                ? "default"
                                                : "primary"
                                        }
                                        onChange={(e) => {
                                            if (
                                                isView ||
                                                !isCustomer ||
                                                checkboxInfo.value
                                            ) {
                                                return;
                                            }

                                            doNotcollection.onChange({
                                                target: {
                                                    value: e.target.checked,
                                                },
                                            });
                                        }}
                                    />
                                }
                                sx={{ color: "#000000" }}
                                label={
                                    <Trans
                                        i18nKey={
                                            createAccountFormOne.labelCheckTwo
                                        }
                                    >
                                        {[<b key="0"></b>, ""]}
                                    </Trans>
                                }
                                style={{
                                    opacity:
                                        isView ||
                                        !isCustomer ||
                                        checkboxInfo.value
                                            ? 0.4
                                            : 1,
                                }}
                            />
                        </Grid>

                        <Note>
                            <Trans i18nKey={createAccountFormOne.note}></Trans>
                        </Note>
                        <Grid item xs={12} md={12}>
                            <SignatureStyle>
                                <AttestInput
                                    xs={6}
                                    md={6}
                                    isView={isView}
                                    label=""
                                    name="buyerSign"
                                    register={register}
                                    id="buyerSign"
                                    disabled={!isCustomer}
                                    handleClick={(e, otp) => {
                                        setValues({
                                            buyerSignDate: dayjs(),
                                            signOtp: otp,
                                        });
                                    }}
                                    leadId={leadId}
                                    otpView={isCustomer && !isVerified}
                                    handleSubmitOtp={handleSubmitOtp}
                                    // generateSignatureOTP={generateSignatureOTP}
                                    otpVerified={isVerified}
                                    dateFieldName="buyerSignDate"
                                />
                                <NameDateStyle>
                                    <TextInput
                                        xs={5}
                                        md={5}
                                        value={`${
                                            register("firstName")?.value
                                        } ${register("lastName")?.value}`}
                                        isView={isView}
                                        id="buyerSignName"
                                        disabled={true}
                                    />
                                    <DateInput
                                        xs={3}
                                        md={3}
                                        label=""
                                        register={register}
                                        name={"buyerSignDate"}
                                        id="buyerSignDate"
                                        isView={isView}
                                        disabled={true}
                                    />
                                </NameDateStyle>
                            </SignatureStyle>
                        </Grid>
                        <FormControlLabel
                            key={"12"}
                            control={
                                <Checkbox
                                    checked={!isView ? check : true}
                                    name={"checkbox"}
                                    color={isView ? "default" : "primary"}
                                    onChange={handleCheckboxChange}
                                    data-testid="checkbox"
                                />
                            }
                            sx={{ color: "#000000" }}
                            label={
                                <span style={{ fontSize: "1.45rem" }}>
                                    {message("account.form.agree")}
                                </span>
                            }
                            style={{
                                opacity: isView ? 0.4 : 1,
                            }}
                        />

                        {!isView && (
                            <Grid item xs={12} md={12} mt={5}>
                                <NavButton
                                    name={message(
                                        createAccouuntButtons.previous
                                    )}
                                    variant="contained"
                                    color="success"
                                    onClick={handleBack}
                                    sx={{
                                        float: "left",
                                    }}
                                />
                                <NavButton
                                    name={message(createAccouuntButtons.next)}
                                    variant="contained"
                                    color="success"
                                    disabled={getDisableInfo()}
                                    onClick={handleNext}
                                    sx={{
                                        float: "right",
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </FormFrame>
            </FormContainer>
        </Main>
    );
};

export default CreateAccountStepTwo;
