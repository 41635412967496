import React from "react";
import { Typography, Divider, Box, Paper } from "../../sharedComponent/index";
import TabsComponent from "../../sharedComponent/dynamicTabs";
import { getStepper } from "./stepperConfig";
import useTrans from "../../../hooks/useTranslation";

const Summary = ({
    register,
    isCustomer,
    isManager,
    fieldValue,
    isValidApprover,
}) => {
    const [label, setLabel] = React.useState(0);
    const [message] = useTrans();

    const steps = getStepper(
        register("adjustedCreditLimit")?.value,
        isCustomer,
        isManager,
        isValidApprover
    );
    const Labels: any = steps.slice(0, steps.length - 1).map((item) => {
        return { name: message(item.label) };
    });

    const handleChange = (e, value) => {
        setLabel(value);
    };
    const CurrentComponent = steps[label].summary || steps[label].component;
    return (
        <>
            <Box component={Paper} sx={{ borderRadius: "1rem" }}>
                <Typography variant="poster2" sx={{ padding: "1rem 2rem" }}>
                    {message("pages.stepper.label.seven")}
                </Typography>
                <Divider />
                <TabsComponent
                    labels={Labels}
                    handleLabelChange={handleChange}
                    selectedLable={label}
                    tooltip={true}
                />
                <CurrentComponent
                    register={register}
                    isView={true}
                    currentIndex={-1}
                    isCustomer={isCustomer}
                    fieldValue={fieldValue}
                />
            </Box>
        </>
    );
};

export default Summary;
