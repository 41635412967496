import dayjs from "dayjs";

function capitalizeFirstLetter(str) {
    return str[0].toUpperCase() + str.slice(1);
}
export const getMessage = (condtion, message, defaultMessage) => {
    if (condtion) {
        return "";
    }
    return message ? message : defaultMessage;
};

export const required = (message) => {
    return (value, fieldName) => {
        if (value != null || value != undefined) {
            if (Array.isArray(value)) {
                return getMessage(
                    value.length,
                    message,
                    `${fieldName}  is required`
                );
            }
        }
        return getMessage(
            value,
            message,
            `${capitalizeFirstLetter(fieldName)} is required`
        );
    };
};

export const lengthValidate = (message) => {
    return (value) => {
        return (
            value &&
            getMessage(
                value?.length <= 100,
                message,
                "Not more than 100 chars allowed"
            )
        );
    };
};

export const rangeValidate = (message, maxValue) => {
    return (value) => {
        return (
            value &&
            getMessage(
                value < maxValue,
                message,
                "Value Not more than 100 allowed"
            )
        );
    };
};

export const openingHoursValidate = (message) => {
    return (value: any) => {
        const time = value ? value.split("||") : ["null", "null"];
        if (time[0] === "null" && time[1] === "null") {
            return "";
        }
        const startTime = dayjs(time[0]);
        const isValidStartTime = startTime.isValid();
        const endTime = dayjs(time[1]);
        const isValidEndTime = endTime.isValid();

        const check =
            (isValidStartTime && time[1] === "null") ||
            (time[0] === "null" && isValidEndTime);

        if (check) {
            return (
                value &&
                getMessage(
                    !check,
                    message,
                    `${
                        isValidStartTime ? "End Time" : "Start Time"
                    } is required`
                )
            );
        } else if (!isValidStartTime || !isValidEndTime) {
            return (
                value &&
                getMessage(
                    false,
                    message,
                    `${
                        !isValidEndTime ? "End Time" : "Start Time"
                    } must be valid`
                )
            );
        } else {
            const timeDiff = endTime.diff(startTime, "minute");

            return (
                value &&
                getMessage(
                    timeDiff > 0,
                    message,
                    `End Time must be greater than Start Time`
                )
            );
        }
    };
};

export const openingDaysValidate = (message) => {
    return (value: any, fieldName: string, fieldInfo: any) => {
        const time = fieldInfo["timing"]
            ? fieldInfo["timing"].split("||")
            : ["null", "null"];

        if (time[0] === "null" && time[1] === "null") {
            return "";
        }

        const isValidStartTime = dayjs(time[0], "HH:mm:ss").isValid();
        const isValidEndTime = dayjs(time[1], "HH:mm:ss").isValid();

        const check =
            isValidStartTime || isValidEndTime
                ? value?.indexOf("1") > -1
                : true;

        return (
            value &&
            getMessage(
                check,
                message,
                `${capitalizeFirstLetter(fieldName)} is required`
            )
        );
    };
};

export const phoneValidation = (message) => {
    return (value) => {
        //const re = /((\+66|0)(\d{1,2}-?\d{3}-?\d{3,4}))|((\+๖๖|๐)([๐-๙]{1,2}-?[๐-๙]{3}-?[๐-๙]{3,4}))/gm;
        return (
            value &&
            getMessage(
                value.length > 8 && value.length < 13,
                message,
                "Please provide a valid Numeric Contact Number with 12 digits"
            )
        );
    };
};

export const ratingValidation = (message, length) => {
    return (value) => {
        const values = value?.split(".") || "";
        if (length < values[0]?.toString()?.length)
            return (
                value &&
                getMessage(false, message, `Maximum ${length} digits allowed`)
            );
        else if (1 < values[1]?.toString()?.length && !values[2])
            return (
                value &&
                getMessage(false, "", `Maximum 1 digit allowed after decimal`)
            );
    };
};

export const max = (message, length) => {
    return (value) => {
        return (
            value &&
            getMessage(
                length >= value?.toString()?.length,
                message,
                `Maximum ${length} digits allowed`
            )
        );
    };
};
export const min = (message, length) => {
    return (value) => {
        return (
            value &&
            getMessage(
                length <= value?.toString()?.length,
                message,
                `Min ${length} digits require`
            )
        );
    };
};

export const exactMatch = (message, length) => {
    return (value) => {
        return (
            value &&
            getMessage(
                length == value?.toString()?.length,
                message,
                `${length} digits require`
            )
        );
    };
};

export const ValidateCreditLimit = (message, totalValue) => {
    return (value) => {
        totalValue = parseFloat(totalValue);
        return (
            value &&
            getMessage(
                totalValue + totalValue * 0.1 >= value,
                message,
                `Not more than 10% of Total credit limit`
            )
        );
    };
};

export const number = (message = "") => {
    return (value) => {
        return (
            value && getMessage(!isNaN(value), message, `Numeric value require`)
        );
    };
};

export const customPhoneValidator = (message) => {
    return (value, fieldName) => {
        if (!value) {
            value = "";
        }
        const res = value?.split(",")?.map((ite, idx) => {
            return phoneValidation(message)(ite);
        });
        return res?.every((item) => item === "") ? "" : res;
    };
};

export const validatePriceLevel = (message: string) => {
    return (value: any, fieldName: string, fieldInfo: any) => {
        const priceLevelMin = isNaN(fieldInfo["priceLevelMin"])
            ? 0
            : parseInt(fieldInfo["priceLevelMin"]);
        const priceLevelMax = isNaN(fieldInfo["priceLevelMax"])
            ? 0
            : parseInt(fieldInfo["priceLevelMax"]);

        return (
            value &&
            getMessage(
                priceLevelMin < priceLevelMax,
                message,
                "invalid price level"
            )
        );
    };
};

export const addressRequired = (message) => {
    return (value: any, fieldName: string, fieldInfo: any) => {
        return (
            value &&
            getMessage(
                value.every((address) =>
                    [
                        "houseNo",
                        "address",
                        "province",
                        "district",
                        "subdistrict",
                        "postalCode",
                    ].every((item) =>
                        address[item] === false ? "false" : address[item]
                    )
                ),
                message,
                `${capitalizeFirstLetter(fieldName)} is required`
            )
        );
    };
};

// export function ValidateEmail(message) {
//   return (value) => {
//     return getMessage(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value),
//       message,
//       "Please provide a valid Email"
//     );
//   };
// }
