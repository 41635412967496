import React from "react";
import dayjs from "dayjs";
import {
    FormControl,
    Paper,
    Typography,
    FormLabel,
    Grid,
    SelectInput,
    UploadInput,
} from "../../../sharedComponent";
import { ReactComponent as CheckIcon } from "../../../../assets/check-solid.svg";
import TextAreaInput from "../../../sharedComponent/InputField/TextAreaInput/TextAreaInput";
import ButtonComponent from "../../../sharedComponent/button/Button";
import DateTextInput from "../../../sharedComponent/InputField/DateInput";
import { ReactComponent as CloseIcon } from "../../../../assets/xmark-solid.svg";
import {
    BoxStyle,
    TypeWrapperStyle,
    HeadingStyle,
    ChipWrapperStyle,
} from "../segment/activityStyle";
import { ActivityMainActivity } from "../../../../utils/apiData";
import { prepareDropdownData } from "../segment/pocketSize";
import useForm from "../../../../hooks/useForm";
import useTrans from "../../../../hooks/useTranslation";
import { IActivity } from "../../../../interfaces/activity";
import { AlertContext } from "../../../../context/alterContext";
import { useParams } from "react-router";
import { useWaitCall } from "../../../../hooks/useCall";
import { uploadFiles, removeFile } from "../../../../services/LeadsInfoApi";

const activityValue = {
    type: [],
    mainActivityId: null,
    subActivityId: null,
    activityDate: dayjs().toISOString(),
    dueDate: null,
    nextDate: null,
    remark: "",
    notes: null,
    visit: "",
    call: "",
};

const AddActivity = ({ handleClose, submitData }: IActivity) => {
    const [message] = useTrans();
    const [visit, setVisit] = React.useState(true);
    const [call, setCall] = React.useState(false);
    const [files, setFiles] = React.useState([]);
    const [uploadedFiles, setUploadedFiles] = React.useState([]);
    const { register, getValue, handleSubmit, validate, setValues, isValid } =
        useForm({
            defaultValues: activityValue,
        });
    const { id } = useParams();
    const remarkInfo = register("remark", null);
    const selectedChannel = getValue("mainActivityId", "");
    const alert = React.useContext(AlertContext);
    const mainActivity = React.useMemo(() => {
        return ActivityMainActivity.map((val) => {
            return { ...val, name: message(val.name) };
        });
    }, [ActivityMainActivity]);
    const selectedSubActivity = React.useMemo(() => {
        return getSegementCollection(
            mainActivity,
            selectedChannel,
            "mainActivityId",
            "subActivity"
        )().map((val) => {
            return { ...val, name: message(val.name) };
        });
    }, [selectedChannel]);

    const handleRemarkChange = (e) => {
        remarkInfo.onChange({ target: { value: e.target.value } });
    };

    const handleType = (e) => {
        if (e.target.id === "visit" && !visit) {
            setVisit(true);
            setCall(false);
        } else if (e.target.id === "call" && !call) {
            setCall(true);
            setVisit(false);
        }
    };

    const handleFileChange = (files) => {
        if (files?.length) {
            setFiles(files);
            const data = new FormData();
            for (let i = 0; i < files.length; i++) {
                data.append("File", files[i]);
            }
            uploadFileData.callback(data);
        } else {
            setFiles([]);
        }
    };

    const uploadFileData = useWaitCall(
        (data) => uploadFiles(data),
        [],
        (result) => {
            setFiles([]);
            const newList = result?.data?.data?.data || [];
            const updatedList = [...uploadedFiles, ...newList];
            setUploadedFiles(updatedList);
            alert.setSuccess("Uploaded Successfully");
        },
        () => {
            alert.setError("something went wrong");
        }
    );

    const handleCloseFile = () => {
        setFiles([]);
    };

    const removeImage = useWaitCall(
        async (name) => {
            await removeFile({ name });
            return name;
        },
        [],
        (result) => {
            const updatedList = uploadedFiles.filter(
                (val) => !(val === result.data)
            );
            setUploadedFiles([...updatedList]);
            alert.setSuccess("File deleted Successfully");
        },
        () => {
            alert.setError("something went wrong");
        }
    );
    const handleRemoveImage = (name) => {
        removeImage.callback(name);
    };

    const onSubmit = (value) => {
        const apidata = {
            visit: visit,
            call: call,
            activityDate:
                value?.activityDate === null
                    ? dayjs().toISOString()
                    : value?.activityDate,
            mainActivityId:
                value?.mainActivityId === null
                    ? dayjs().toISOString()
                    : value?.mainActivityId,
            subActivityId:
                value?.subActivityId === null
                    ? dayjs().toISOString()
                    : value?.subActivityId,
            dueDate: value?.dueDate,
            nextDate: value?.nextDate,
            remark: value?.remark,
            leadId: id,
            attachment: uploadedFiles,
        };
        submitData(apidata);
    };

    const someDate = new Date();
    const date = someDate.setDate(someDate.getDate());
    return (
        <>
            <BoxStyle component={Paper} p={4}>
                <div>
                    <HeadingStyle>
                        <Typography variant="h4">
                            {message("pages.leadDetails.leadInfo.addActivity")}
                        </Typography>
                        <span
                            data-testid={"closeActivity"}
                            onClick={handleClose}
                        >
                            <CloseIcon
                                height={25}
                                width={25}
                                fill={"#47474A"}
                                cursor={"pointer"}
                            />
                        </span>
                    </HeadingStyle>
                    <Grid item container spacing={2}>
                        <Grid item lg={6}>
                            <FormControl>
                                <FormLabel>
                                    {message("pages.leadDetails.leadInfo.type")}
                                </FormLabel>
                                <TypeWrapperStyle>
                                    <ChipWrapperStyle
                                        type={visit && (visit as any)}
                                        id="visit"
                                        onClick={handleType}
                                    >
                                        {visit && visit && (
                                            <CheckIcon
                                                height={15}
                                                width={15}
                                                fill={"#84BD00"}
                                            />
                                        )}
                                        {message(
                                            "pages.leadDetails.leadInfo.visit"
                                        )}
                                    </ChipWrapperStyle>
                                    <ChipWrapperStyle
                                        type={call && (call as any)}
                                        id="call"
                                        onClick={handleType}
                                    >
                                        {call && call && (
                                            <CheckIcon
                                                height={15}
                                                width={15}
                                                fill={"#84BD00"}
                                            />
                                        )}
                                        {message(
                                            "pages.leadDetails.leadInfo.call"
                                        )}
                                    </ChipWrapperStyle>
                                </TypeWrapperStyle>
                            </FormControl>
                        </Grid>
                        <DateTextInput
                            lg={6}
                            md={6}
                            label={message(
                                "pages.leadDetails.leadInfo.activityDate"
                            )}
                            name="activityDate"
                            register={register}
                            defaultValue={dayjs()}
                        />
                        <SelectInput
                            lg={6}
                            md={6}
                            label={message(
                                "pages.leadDetails.leadInfo.mainActivity"
                            )}
                            {...register("mainActivityId", null)}
                            options={prepareDropdownData(
                                mainActivity,
                                "mainActivityId"
                            )}
                        />
                        <SelectInput
                            lg={6}
                            md={6}
                            label={message(
                                "pages.leadDetails.leadInfo.subActivity"
                            )}
                            {...register("subActivityId", null)}
                            disabled={!getValue("mainActivityId")}
                            options={prepareDropdownData(
                                selectedSubActivity,
                                "subActivityId"
                            )}
                        />
                        <DateTextInput
                            lg={6}
                            md={6}
                            label={`${message(
                                "pages.leadDetails.leadInfo.dueDateOptional"
                            )} ${message("sharedComponent.label.optional")}`}
                            name="dueDate"
                            register={register}
                            defaultValue={dayjs()}
                        />
                        <DateTextInput
                            lg={6}
                            md={6}
                            label={`${message(
                                "pages.leadDetails.leadInfo.nextMeetingDate"
                            )} ${message("sharedComponent.label.optional")}`}
                            name="nextDate"
                            register={register}
                            defaultValue={dayjs()}
                        />
                        <Grid item lg={12}>
                            <TextAreaInput
                                label={message(
                                    "pages.leadDetails.leadInfo.remark"
                                )}
                                minRows={4}
                                placeholder={message(
                                    "pages.leadDetails.leadInfo.enterAdditionalNote"
                                )}
                                multiline={true}
                                onChange={handleRemarkChange}
                                value={remarkInfo.value}
                            />
                        </Grid>
                        <Grid item lg={12}>
                            <UploadInput
                                placeholder={message(
                                    "pages.leadDetails.leadInfo.uploadPlaceholder"
                                )}
                                handleChange={handleFileChange}
                                handleClose={handleCloseFile}
                                removeUploadedFiles={handleRemoveImage}
                                files={files}
                                uploadedFiles={uploadedFiles}
                                accept="image/*"
                            />
                        </Grid>
                        <Grid item lg={12}>
                            <ButtonComponent
                                name={message(
                                    "pages.leadDetails.leadInfo.submit"
                                )}
                                variant="contained"
                                color="success"
                                onClick={handleSubmit(onSubmit)}
                                sx={{
                                    borderRadius: "16px",
                                    backgroundColor: "#84BD00",
                                    textTransform: "none",
                                    width: "100%",
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </BoxStyle>
        </>
    );
};
export default AddActivity;

function getSegementCollection(
    items: any,
    selectedItem: any,
    name: string,
    selectedChannelName: string
): () => any {
    return () => {
        const mychannels = items.find((item) => item[name] === selectedItem);
        if (mychannels) {
            return mychannels[selectedChannelName];
        }
        return items[0][selectedChannelName];
    };
}
