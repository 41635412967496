import React from "react";
import {
    Box,
    Card,
    Typography,
    ActionComponent,
    ExportExcel,
} from "../../sharedComponent";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import TableData from "../../sharedComponent/table/Table";
import useCall from "../../../hooks/useCall";
import { getArchivedLeads } from "../../../services/LeadsgetApi";
import useTrans from "../../../hooks/useTranslation";
import "./index.scss";
import { ReactComponent as EllipsisIcon } from "../../../assets/ellipsis-vertical.svg";
import { updateLeadsInfo } from "../../../services/LeadsInfoApi";
import { useWaitCall } from "../../../hooks/useCall";
import { AlertContext } from "../../../context/alterContext";
import { useCommonContext } from "@betagro/ui-common";
import { ROLES } from "../../../constants/role.status";

const ArchivedLeads = () => {
    const [message] = useTrans();
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [refresh, setRefresh] = React.useState(false);
    const { refreshCount, userContextInfo } = useCommonContext();
    const _exporter = React.createRef<ExcelExport>();
    const [exportList, setExportList] = React.useState([]);
    const [downloadList, setDownloadList] = React.useState([]);

    const handlePageChange = (pageVal, rowsPerPageVal) => {
        setPage(pageVal);
        setRowsPerPage(rowsPerPageVal);
    };
    const leadApi = useCall(
        () => getArchivedLeads(page, rowsPerPage),
        [page, rowsPerPage, refresh],
        []
    );

    const fetchExpoerData = useWaitCall(
        (count) => getArchivedLeads(1, count),
        {},
        async (response) => {
            setDownloadList(response?.data?.data?.data || []);
        },
        (error) => {
            alert.setError(
                error?.data?.errors?.join(",") || "Something went wrong"
            );
        }
    );

    React.useEffect(() => {
        if (downloadList?.length && _exporter.current) {
            _exporter.current.save(downloadList);
        }
    }, [downloadList]);

    const handleExport = async () => {
        if (exportList.length) {
            const list = leadApi?.data?.data?.data
                ? leadApi?.data?.data?.data.filter(
                      (val) => exportList.indexOf(val.id) >= 0
                  )
                : [];
            setDownloadList(list);
        } else if (leadApi.data?.data?.count) {
            fetchExpoerData.callback(leadApi.data?.data?.count);
        }
    };

    const alert = React.useContext(AlertContext);
    const updateApiData = useWaitCall(
        (data) => updateLeadsInfo(data.id, { leads: data.leads }),
        [],
        () => {
            alert.setSuccess("Save Successfully");
            refreshCount();
            setRefresh(!refresh);
        },
        () => {
            alert.setError("something went wrong");
        }
    );

    const handleUnarchive = (row, name, tabName, handleClose) => {
        if (name === "sharedComponent.listDivider.assignmentList.unarchive") {
            const apiData = {
                id: row?.id,
                leads: {
                    archive: false,
                },
            };
            updateApiData.callback(apiData);
            setRefresh(!refresh);
            handleClose();
        } else {
            return false;
        }
    };

    const actions = [
        {
            name: "sharedComponent.listDivider.assignmentList.unarchive",
            handleClick: handleUnarchive,
        },
    ];

    return (
        <>
            <Typography variant="poster1" sx={{ padding: "1rem 0rem" }}>
                {message("layout.sideNav.archivedLeads")}
            </Typography>
            <Box>
                <Card sx={{ padding: "15px" }}>
                    <TableData
                        setExportList={setExportList}
                        isLoading={leadApi.isLoading}
                        tabName={message("layout.sideNav.archivedLeads")}
                        handlePageChange={handlePageChange}
                        count={leadApi.data?.data?.count || 0}
                        data={leadApi.data?.data?.data}
                        handleExport={handleExport}
                        buttonLabel={"Unarchive"}
                        icon={false}
                        pageAction={(row) =>
                            userContextInfo?.role === ROLES.SALES ? (
                                <ActionComponent row={row} actions={actions}>
                                    <EllipsisIcon />
                                </ActionComponent>
                            ) : null
                        }
                    />
                    {
                        <ExportExcel
                            exporter={_exporter}
                            fileName={message("layout.sideNav.archivedLeads")}
                        />
                    }
                </Card>
            </Box>
        </>
    );
};

export default ArchivedLeads;
