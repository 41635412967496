import CustomerMap from "./customerMap";
import Memorandum from "./memorandum";
import ConsentLetter from "./consentLetter";
import CustomerSign from "./customerSign";
import CreateAccountStepAnalysis from "./stepAnalysis";
import Summary from "./summary";
import ProductPurchase from "./productPurchase";
import CreateAccountStepUpload from "./stepUpload";
import MemorandumSummary from "./memorandumSummary";

export const steps = [
    {
        label: "pages.stepper.label.five",
        component: CustomerMap,
        summary: null,
        type: 6,
        validationField: [
            "companyName",
            "contactNumber",
            "salesRepresntative",
            "salesRepContactNumber",
            "latitude",
            "longitude",
        ],
    },
    {
        label: "account.form.tanalysisaggrement.title",
        component: CreateAccountStepAnalysis,
        summary: null,
        type: 7,
        validationField: [
            "sellerSign",
            "firstName",
            "title",
            "lastName",
            "nationalId",
            "totalCreditLimit",
            "adjustedCreditLimit",
        ],
    },
    {
        label: "pages.stepper.label.three",
        component: ProductPurchase,
        summary: null,
        type: 4,
        validationField: [
            "title",
            "productPurchaseDate",
            "firstName",
            "lastName",
            "item",
            "priceWords",
            "workingDays",
            "workingDaysWords",
            "CreditTerm",
        ],
    },
    {
        label: "pages.stepper.label.one",
        component: ConsentLetter,
        summary: null,
        type: 2,
        validationField: ["firstName", "lastName", "nationalId", "title"],
    },
    {
        label: "pages.stepper.label.two",
        component: CustomerSign,
        summary: null,
        type: 7,
        validationField: [
            "firstName",
            "lastName",
            "companyName",
            "sellerSign",
            "sellerSignName",
            "memorandumAddress",
        ],
    },
    {
        label: "pages.stepper.label.four",
        component: Memorandum,
        summary: MemorandumSummary,
        type: 7,
        validationField: [
            "date",
            "description",
            "sellerSign",
            "sellerSignName",
            "memorandumAddress",
        ],
    },
    {
        label: "pages.stepper.label.six",
        component: CreateAccountStepUpload,
        summary: null,
        type: 7,
        validationField: null,
    },
    {
        label: "pages.stepper.label.seven",
        component: Summary,
        summary: null,
        type: 7,
        validationField: null,
    },
];

export const stepsManager = [
    {
        label: "pages.stepper.label.five",
        component: CustomerMap,
        summary: null,
        type: 6,
        validationField: null,
    },
    {
        label: "account.form.tanalysisaggrement.title",
        component: CreateAccountStepAnalysis,
        summary: null,
        type: 7,
        validationField: ["branchManager"],
    },
    {
        label: "pages.stepper.label.three",
        component: ProductPurchase,
        summary: null,
        type: 4,
        validationField: null,
    },
    {
        label: "pages.stepper.label.one",
        component: ConsentLetter,
        summary: null,
        type: 2,
        validationField: null,
    },
    {
        label: "pages.stepper.label.two",
        component: CustomerSign,
        summary: null,
        type: 3,
        validationField: null,
    },
    {
        label: "pages.stepper.label.four",
        component: Memorandum,
        summary: MemorandumSummary,
        type: 8,
        validationField: null,
    },
    {
        label: "pages.stepper.label.six",
        component: CreateAccountStepUpload,
        summary: null,
        type: 7,
        validationField: null,
    },
    {
        label: "pages.stepper.label.seven",
        component: Summary,
        summary: null,
        type: 7,
        validationField: null,
    },
];

export const stepsCustomer = [
    {
        label: "pages.stepper.label.one",
        component: ConsentLetter,
        summary: null,
        validationField: ["buyerSign"],
        type: 2,
    },
    {
        label: "pages.stepper.label.five",
        component: CustomerMap,
        summary: null,
        type: 6,
        validationField: null,
    },
    {
        label: "pages.stepper.label.three",
        component: ProductPurchase,
        summary: null,
        validationField: null,
        type: 4,
    },
    {
        label: "pages.stepper.label.two",
        component: CustomerSign,
        summary: null,
        validationField: null,
        type: 3,
    },
    {
        label: "pages.stepper.label.four",
        component: Memorandum,
        summary: MemorandumSummary,
        validationField: null,
        type: 8,
    },
    {
        label: "pages.stepper.label.six",
        component: CreateAccountStepUpload,
        summary: null,
        validationField: ["idCard", "customerImages"],
        type: 7,
    },
    {
        label: "pages.stepper.label.seven",
        component: Summary,
        summary: null,
        validationField: null,
        type: 7,
    },
];

export const stepsSecondManager = [
    {
        label: "pages.stepper.label.five",
        component: CustomerMap,
        summary: null,
        type: 6,
        validationField: null,
    },
    {
        label: "account.form.tanalysisaggrement.title",
        component: CreateAccountStepAnalysis,
        summary: null,
        type: 7,
        validationField: ["branchManager"],
    },
    {
        label: "pages.stepper.label.three",
        component: ProductPurchase,
        summary: null,
        type: 4,
        validationField: ["manangerApprovalSign", "manangerApprovalSignName"],
    },
    {
        label: "pages.stepper.label.one",
        component: ConsentLetter,
        summary: null,
        type: 2,
        validationField: null,
    },
    {
        label: "pages.stepper.label.two",
        component: CustomerSign,
        summary: null,
        type: 7,
        validationField: null,
    },
    {
        label: "pages.stepper.label.four",
        component: Memorandum,
        summary: MemorandumSummary,
        type: 7,
        validationField: null,
    },
    {
        label: "pages.stepper.label.six",
        component: CreateAccountStepUpload,
        summary: null,
        type: 7,
        validationField: null,
    },
    {
        label: "pages.stepper.label.seven",
        component: Summary,
        summary: null,
        type: 7,
        validationField: null,
    },
];

const getConfig = (isCustomer, isManager, isValidApprover) => {
    switch (true) {
        case isCustomer:
            return stepsCustomer;
        case isValidApprover:
            return stepsSecondManager;
        case isManager:
            return stepsManager;
        default:
            return steps;
    }
};

export const getStepper = (limit, isCustomer, isManager, isValidApprover) => {
    const stepperConfig = getConfig(isCustomer, isManager, isValidApprover);
    return parseInt(limit) > 20000
        ? stepperConfig
        : stepperConfig.filter(
              (item, index) => item.label !== "pages.stepper.label.three"
          );
};

export const getRole = (limit) => {
    return parseInt(limit) <= 40000
        ? 2
        : parseInt(limit) >= 40000 && parseInt(limit) <= 300000
          ? 3
          : parseInt(limit) >= 300000 && parseInt(limit) <= 1000000
            ? 4
            : parseInt(limit) >= 1000000 && parseInt(limit) <= 3000000
              ? 5
              : parseInt(limit) >= 3000000 && parseInt(limit) <= 20000000
                ? 6
                : 7;
};
