import React from "react";

import { Grid, FormControl } from "../../index";
import { ITextInput, ITextAreaInput } from "../../../../interface/InputField";
import { styled } from "@mui/material/styles";

const NFIS_PLACE_REMARK_LENGTH = 200;

const errorStyle = {
    border: "2px solid red",
};

const FormLabel = styled("label")(() => ({
    fontSize: "1.25rem",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    color: "#464B55",
}));
const InputError = styled("span")(() => ({
    color: "red",
}));

const StyledTextarea = styled("textarea")(() => ({
    fontFamily: "IBM Plex Sans, sans-serif",
    fontSize: "0.875rem",
    fontWeight: "400",
    lineHeight: "1.5",
    padding: " 12px",
    borderRadius: "12px 12px 12px 12px",
    color: "#6C6D71",
    background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #6C6D71",
    // borderRadius:'8px',
    border: "none",
    width: "95%",
    height: "3.5rem",
    // firefox
    "&:focusVisible": {
        outline: "0",
        border: "none",
    },
}));
const FormHelperText = styled("span")(() => ({
    display: "flex",
    justifyContent: "flex-end",
    color: "#919295",
    fontSize: "20px",
}));

const TextAreaInput = ({
    xs,
    md,
    value,
    label,
    name,
    id,
    hidden = false,
    disabled = false,
    error,
    placeholder,
    onChange,
    labelType = "column",
}: ITextInput & ITextAreaInput) => {
    const labelStyle = {
        color: "#919295",
        fontSize: "1.25rem",
        fontFamily: "DB Helvethaica X 65 Regular",
    };
    const gridLabelStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
    };

    return (
        <>
            {label && labelType == "row" && (
                <Grid item md={3} sx={gridLabelStyle}>
                    <FormLabel style={labelStyle}>{label}</FormLabel>
                </Grid>
            )}
            <Grid item xs={xs} md={md}>
                <FormControl fullWidth>
                    {labelType != "row" && <FormLabel>{label}</FormLabel>}
                    <StyledTextarea
                        value={value}
                        name={name}
                        data-textid={id}
                        hidden={hidden}
                        disabled={disabled}
                        onChange={onChange}
                        style={
                            error ? errorStyle : { margin: "6px 0px 6px 0px" }
                        }
                        maxLength={NFIS_PLACE_REMARK_LENGTH}
                        placeholder={placeholder}
                    />
                    <FormHelperText>{`${value?.length}/${NFIS_PLACE_REMARK_LENGTH}`}</FormHelperText>
                    {error && <InputError>{error}</InputError>}
                </FormControl>
            </Grid>
        </>
    );
};

export default TextAreaInput;
