import React from "react";

import { Grid, FormControl } from "../../../sharedComponent";
import { styled } from "@mui/material/styles";
import { Select, MenuItem } from "@mui/material";
import { ISelectInput } from "../../../../interface/InputField";

const errorStyle = {
    border: "2px solid red",
};

const FormLabel = styled("label")(() => ({
    fontSize: "1.25rem",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    color: "#464B55",
}));

const InputError = styled("span")(() => ({
    color: "red",
}));

const InputSelect = styled(Select)(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "3rem",
    background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #6C6D71",
    borderRadius: "0.5rem",
    borderWidth: "0px",
    borderStyle: "solid",
    marginTop: "0.375rem",
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
}));
const DisplayVal = styled("span")(() => ({
    fontSize: "1.25rem",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    color: "#464B55",
    margin: "0.5rem",
}));
const SelectInput = ({
    xs,
    md,
    lg,
    value,
    label,
    options = [],
    name,
    id,
    error,
    isView = undefined,
    onChange,
    disabled,
}: ISelectInput) => {
    const displayLabel =
        isView && options.find((item) => item.value === value)?.label;
    return (
        <>
            {isView && !(xs || md) && (
                <span>
                    <b>
                        {"\u00A0"}
                        {displayLabel}
                    </b>
                </span>
            )}

            {isView && (xs || md) && (
                <Grid item xs={xs} md={md}>
                    <DisplayVal>{displayLabel}</DisplayVal>
                </Grid>
            )}
            {!isView && (
                <Grid item xs={xs} md={md} lg={lg}>
                    <FormControl fullWidth>
                        {label && <FormLabel>{label}</FormLabel>}
                        <>
                            <InputSelect
                                placeholder="Select"
                                autoWidth={false}
                                name={name}
                                id={id}
                                value={value}
                                onChange={onChange}
                                data-textid={name}
                                style={error ? errorStyle : null}
                                disabled={disabled}
                            >
                                <MenuItem
                                    value={`Select`}
                                    data-textid={`${name}`}
                                    disabled={true}
                                >
                                    {`Select`}
                                </MenuItem>
                                {options.map((op, index) => {
                                    return (
                                        // eslint-disable-next-line react/jsx-key
                                        <MenuItem
                                            value={op.value}
                                            key={`${op.label}-${index}`}
                                            data-textid={`${name}${index}`}
                                            hidden={op.hidden}
                                            disabled={op.disabled}
                                        >
                                            {op.label}
                                        </MenuItem>
                                    );
                                })}
                            </InputSelect>
                            {error && <InputError>{error}</InputError>}
                        </>
                    </FormControl>
                </Grid>
            )}
        </>
    );
};

export default SelectInput;
