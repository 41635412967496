import React from "react";
import dayjs from "dayjs";
import { Typography, ImageList, ImageListItem } from "../../../sharedComponent";
import { ReactComponent as SuitCaseIcon } from "../../../../assets/suitcase.svg";
import { ActivityMainActivity } from "../../../../utils/apiData";
import {
    BoxHeadingStyle,
    ChipWrapperMargin,
    BoxChipWrapper,
} from "../segment/activityStyle";
import useTrans from "../../../../hooks/useTranslation";
import { dateFormat } from "../../../../utils/global";
import { useCommonContext } from "@betagro/ui-common";

const mainActivity = (value) => {
    const name = ActivityMainActivity.find((x) => x.mainActivityId === value);
    return name?.name;
};

const subMainActivity = (firstvalue, secondValue) => {
    const subActivityArray = ActivityMainActivity?.find(
        (x) => x.mainActivityId === firstvalue
    )?.subActivity;
    const name = subActivityArray
        ?.map((x) => x)
        .find((x) => x.subActivityId === secondValue)?.name;
    return name;
};

export const ActivityBox = ({ list }) => {
    const [message] = useTrans();
    const commonContext = useCommonContext();

    return (
        <>
            <BoxHeadingStyle>
                <SuitCaseIcon />
                <Typography>{`${
                    commonContext?.userContextInfo?.firstNameEn ||
                    commonContext?.userContextInfo?.firstNameTh
                } ${
                    commonContext?.userContextInfo?.lastNameEn ||
                    commonContext?.userContextInfo?.lastNameTh
                }`}</Typography>
            </BoxHeadingStyle>
            <BoxChipWrapper>
                <ChipWrapperMargin
                    label={`Main Activity: ${
                        list?.mainActivityId
                            ? message(mainActivity(list?.mainActivityId))
                            : "-"
                    }`}
                />
                <ChipWrapperMargin
                    label={`Sub activity: ${
                        list?.subActivityId
                            ? message(
                                  subMainActivity(
                                      list?.mainActivityId,
                                      list?.subActivityId
                                  )
                              )
                            : "-"
                    }`}
                />
                <ChipWrapperMargin
                    label={`Due date: ${
                        list?.dueDate ? dateFormat(list?.dueDate) : "-"
                    }`}
                />
                <ChipWrapperMargin
                    label={`Next meeting date: ${dateFormat(
                        list?.nextMeetingDate
                    )}`}
                />
                <Typography style={{ wordWrap: "break-word" }}>
                    {list?.remark ? list?.remark : "-"}
                </Typography>
                <ImageList cols={3} rowHeight={164}>
                    {list?.attachment?.map((item) => (
                        <ImageListItem key={item}>
                            <img
                                src={`/api/file/img/${item}?w=164&h=164&fit=crop&auto=format`}
                                srcSet={`/api/file/img/${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                alt={item}
                                loading="lazy"
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </BoxChipWrapper>
        </>
    );
};

export const ActivityBoxSmall = ({ notes }) => {
    const commonContext = useCommonContext();

    return (
        <>
            <BoxHeadingStyle>
                <SuitCaseIcon />
                <Typography>{`${
                    commonContext?.userContextInfo?.firstNameEn ||
                    commonContext?.userContextInfo?.firstNameTh
                } ${
                    commonContext?.userContextInfo?.lastNameEn ||
                    commonContext?.userContextInfo?.lastNameTh
                }`}</Typography>
            </BoxHeadingStyle>
            <BoxChipWrapper>
                {notes.map((val) => {
                    return (
                        <>
                            <Typography style={{ wordWrap: "break-word" }}>
                                {val?.remark ? val?.remark : "-"}
                            </Typography>
                            <ImageList cols={3} rowHeight={164}>
                                {val?.attachment?.map((item) => (
                                    <ImageListItem key={item}>
                                        <img
                                            src={`/api/file/img/${item}?w=164&h=164&fit=crop&auto=format`}
                                            srcSet={`/api/file/img/${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                            alt={item}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </>
                    );
                })}
            </BoxChipWrapper>
        </>
    );
};
