import * as React from "react";
import { FormControl, Grid, TextField } from "../../index";
import { styled } from "@mui/material/styles";

const FormControlWrapper = styled(FormControl)(() => ({
    "& .MuiFormControl-root": { width: "80%" },
}));

const TextFieldWrapper = styled(TextField)(() => ({
    "& .MuiInputBase-root .MuiInputBase-input": {
        background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #6C6D71",
        borderRadius: "12px",
        textAlign: "center",
        fontSize: "1.5rem",
        fontWeight: "600",
    },
}));

const OTPNumberInput = ({
    disabled,
    required,
    handleChange,
    name,
    error,
    inputRef,
}: any) => {
    return (
        <Grid item container md={2}>
            <FormControlWrapper>
                <TextFieldWrapper
                    inputRef={inputRef}
                    type="number"
                    error={error}
                    name={name}
                    onChange={handleChange}
                    disabled={disabled}
                    required={required}
                />
            </FormControlWrapper>
        </Grid>
    );
};

export default OTPNumberInput;
