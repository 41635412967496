import React from "react";
import TableData from "../../../sharedComponent/table/Table";
import useCall from "../../../../hooks/useCall";
import {
    getBranchLeads,
    getAssignedLeads,
    getSuccessfulLeads,
    getFailedLeads,
} from "../../../../services/LeadsgetApi";
import BasicModal from "../../../sharedComponent/modal";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import AssignModal from "../assignModal/AssignModal";
import { ActionComponent, ExportExcel } from "../../../sharedComponent";
import { ReactComponent as EllipsisIcon } from "../../../../assets/ellipsis-vertical.svg";
import { useWaitCall } from "../../../../hooks/useCall";
import { AlertContext } from "../../../../context/alterContext";
import { updateLeadsInfo } from "../../../../services/LeadsInfoApi";
import { useNavigate } from "react-router-dom";
import { useCommonContext } from "@betagro/ui-common";
import useTrans from "../../../../hooks/useTranslation";
import { ROLES } from "../../../../constants/role.status";

const LeadsTable = (props: any) => {
    const { value } = props;
    const [message] = useTrans();
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [refresh, setRefresh] = React.useState(false);
    const navigate = useNavigate();
    const [rowData, setRowData] = React.useState({});
    const commonContext = useCommonContext();
    const _exporter = React.createRef<ExcelExport>();
    const [exportList, setExportList] = React.useState([]);
    const [downloadList, setDownloadList] = React.useState([]);

    const handlePageChange = (pageVal, rowsPerPageVal) => {
        setPage(pageVal);
        setRowsPerPage(rowsPerPageVal);
    };
    const [editBtn, setEditBtn] = React.useState(false);
    React.useEffect(() => {
        const roleCheck = commonContext?.userContextInfo?.role === ROLES.SALES;
        setEditBtn(roleCheck);
    }, []);
    const apiCall = useCall(
        () =>
            value === 0
                ? getBranchLeads(page, rowsPerPage)
                : value === ROLES.SALES
                ? getAssignedLeads(page, rowsPerPage)
                : value === ROLES.MANAGER
                ? getSuccessfulLeads(page, rowsPerPage)
                : value === ROLES.GM
                ? getFailedLeads(page, rowsPerPage)
                : {},
        [page, rowsPerPage, value, refresh],
        []
    );

    const [open, setOpen] = React.useState(false);

    const openModal = () => {
        setOpen(true);
    };

    const handleModelClose = () => {
        setOpen(false);
    };

    const fetchExpoerData = useWaitCall(
        (count) =>
            value === 0
                ? getBranchLeads(1, count)
                : value === ROLES.SALES
                ? getAssignedLeads(1, count)
                : value === ROLES.MANAGER
                ? getSuccessfulLeads(1, count)
                : value === ROLES.GM
                ? getFailedLeads(1, count)
                : {},
        {},
        async (response) => {
            await setDownloadList(response?.data?.data?.data || []);
        },
        (error) => {
            alert.setError(
                error?.data?.errors?.join(",") || "Something went wrong"
            );
        }
    );

    React.useEffect(() => {
        if (downloadList?.length && _exporter.current) {
            _exporter.current.save(downloadList);
        }
    }, [downloadList]);

    const handleExport = async () => {
        if (exportList.length) {
            const list = apiCall.data?.data?.data
                ? apiCall.data?.data?.data.filter(
                      (val) => exportList.indexOf(val.id) >= 0
                  )
                : [];
            await setDownloadList(list);
        } else if (apiCall.data?.data?.count) {
            fetchExpoerData.callback(apiCall.data?.data?.count);
        }
    };
    const handleAssign = (row, name, tabName, handleClose) => {
        if (!row?.isLeadAssigned) {
            setRowData(row);
            setOpen(true);
            handleClose();
        }
    };
    const AssignModalData = () => {
        return (
            <BasicModal open={open} handleClose={handleModelClose}>
                <AssignModal
                    rowData={rowData}
                    refresh={() => setRefresh(!refresh)}
                    handleClose={handleModelClose}
                />
            </BasicModal>
        );
    };

    const alert = React.useContext(AlertContext);

    const updateApiData = useWaitCall(
        (data) => updateLeadsInfo(data.id, { leads: { ...data.leads } }),
        [],
        () => {
            alert.setSuccess("Save Successfully");
            // setEdit(false);
            setRefresh(!refresh);
            commonContext.refreshCount();
        },
        () => {
            alert.setError("something went wrong");
        }
    );
    const handleArchive = (row, name, tabName, handleClose) => {
        if (name === "sharedComponent.listDivider.assignmentList.archive") {
            const apiData = {
                id: row?.id,
                leads: {
                    archive: true,
                },
            };
            updateApiData.callback(apiData);
            setRefresh(!refresh);
            handleClose();
        } else {
            return false;
        }
    };

    const handleEdit = (row, name, tabName, handleClose) => {
        const tabNameConvert = message(tabName).replace(/\s+/g, "-");
        if (row?.id != null) {
            navigate(`/leadInfo/${row?.id}/${tabNameConvert}/edit`);
        }
        handleClose();
    };

    const getActions = (row) => {
        const role = commonContext?.userContextInfo?.role;

        // For roles above than sales manager
        if (role > ROLES.MANAGER) {
            return [];
        }

        const actions = [
            { name: "Edit", handleClick: handleEdit },
            {
                name: "sharedComponent.listDivider.assignmentList.archive",
                handleClick: handleArchive,
            },
        ];

        const assignAction = {
            name: "sharedComponent.listDivider.assignmentList.assignTo",
            handleClick: handleAssign,
        };

        // For salesperson, show options only when either lead is assigned to self or not assigned to anyone
        if (role === ROLES.SALES) {
            return !row?.leadSalesMappings?.empCode ||
                row?.leadSalesMappings?.empCode ===
                    commonContext?.userContextInfo?.employeeId
                ? [assignAction, ...actions]
                : [];
        }

        // For sales manager
        return row.isLeadAssigned ? [] : [assignAction];
    };

    return (
        <>
            <TableData
                setExportList={setExportList}
                isLoading={apiCall.isLoading}
                tabName={props.tabName}
                icon={true}
                count={
                    commonContext?.userContextInfo?.role === ROLES.SALES ||
                    commonContext?.userContextInfo?.role === ROLES.MANAGER
                        ? apiCall.data?.data?.count || 0
                        : 0
                }
                data={
                    commonContext?.userContextInfo?.role === ROLES.SALES ||
                    commonContext?.userContextInfo?.role === ROLES.MANAGER
                        ? apiCall.data?.data?.data
                        : []
                }
                handleExport={handleExport}
                buttonLabel={message(
                    "sharedComponent.table.header.button.assignto"
                )}
                handlePageChange={handlePageChange}
                modal={<AssignModalData />}
                openModal={openModal}
                handleModelClose={handleModelClose}
                pageAction={(row: any) => {
                    const actions = getActions(row);
                    if (actions.length === 0) {
                        return null;
                    }

                    return (
                        <ActionComponent
                            row={row}
                            tabName={props.tabName}
                            actions={getActions(row)}
                        >
                            <EllipsisIcon />
                        </ActionComponent>
                    );
                }}
            />
            {
                <ExportExcel
                    exporter={_exporter}
                    fileName={message(props.tabName)}
                />
            }
        </>
    );
};

export default LeadsTable;
